import { DelayTopFiveDelayCodesPieChartWidget, DelayAirportOperativeDelaysPieChartWidget, DelaySummaryWidget, } from "@features/OnTimePerformance/widgets";
import { DefaultPermissions } from "@widgets/registry";
export const delaysWidgets = [
    {
        name: DelayTopFiveDelayCodesPieChartWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 6, 4],
        component: DelayTopFiveDelayCodesPieChartWidget.displayName,
        componentProps: {
            minHeight: 400,
        },
        permissions: DelayTopFiveDelayCodesPieChartWidget.permissions,
    },
    {
        name: DelayAirportOperativeDelaysPieChartWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [5, 5, 5],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 6, 4],
        component: DelayAirportOperativeDelaysPieChartWidget.displayName,
        componentProps: {
            minHeight: 400,
        },
        permissions: DefaultPermissions,
    },
    {
        name: DelaySummaryWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [9, 9, 9],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: DelaySummaryWidget.displayName,
        componentProps: {
            title: "Totalt antall forsinkelser",
            minHeight: 400,
        },
        permissions: DefaultPermissions,
    },
];
