import { useState, useEffect } from "react";
const useIsTestEnvironment = () => {
    const [isTestEnvironment, setIsTestEnvironment] = useState(true);
    useEffect(() => {
        if (typeof window !== "undefined" && window.__CONFIG__ !== undefined) {
            const windowEnv = window.__CONFIG__.environment;
            setIsTestEnvironment(windowEnv === "dev" || windowEnv === "test");
        }
    }, []);
    return isTestEnvironment;
};
export default useIsTestEnvironment;
