import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Grid } from "@components/Layout";
import { ProgressCard } from "@components/ProgressCard/ProgressCard";
import Spinner from "@components/Spinner/Spinner";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import useFlightMovementsData from "../hooks/useFlightMovementsData";
export const FlightMovementsDomesticTotal = () => {
    const { picker: { from, to }, } = useDatePickerContext();
    const { domesticFlights, isLoading } = useFlightMovementsData({ date: { from, to } });
    if (isLoading)
        return _jsx(_Fragment, {});
    return _jsx(_Fragment, { children: domesticFlights });
};
export const FlightMovementsDomestic = () => {
    const { picker: { from, to }, } = useDatePickerContext();
    const { domesticArrival, domesticDeparture, isLoading } = useFlightMovementsData({ date: { from, to } });
    const { t } = useTranslation();
    if (isLoading)
        return _jsx(Spinner, {});
    return (_jsxs(Grid, { columns: 2, children: [_jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", title: t("common.departure"), value: domesticDeparture || "N/A" }), _jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", title: t("common.arrival"), value: domesticArrival || "N/A" })] }));
};
