var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./Search.scss";
import clsx from "clsx";
import { forwardRef, useCallback, useMemo, useRef, useState } from "react";
import { CloseOutlined, MagnifyingGlassOutlined } from "@avinor/react-icons";
import mergeRefs, { omit } from "@utils/Utils";
import Button from "../Buttons/Button/Button";
// eslint-disable-next-line react/display-name
const Search = forwardRef((props, ref) => {
    const { defaultValue, value, className, onChange, onClear, onEnter, placeholder = "Søk" } = props, rest = __rest(props, ["defaultValue", "value", "className", "onChange", "onClear", "onEnter", "placeholder"]);
    const searchRef = useRef(null);
    const mergedRef = useMemo(() => mergeRefs([searchRef, ref]), [ref]);
    const [internalValue, setInternalValue] = useState(defaultValue !== null && defaultValue !== void 0 ? defaultValue : "");
    const handleChange = useCallback((v) => {
        value === undefined && setInternalValue(v);
        onChange === null || onChange === void 0 ? void 0 : onChange(v);
    }, [onChange, value]);
    const handleOnEnter = useCallback(() => {
        var _a, _b, _c, _d;
        if (onChange)
            return;
        onEnter === null || onEnter === void 0 ? void 0 : onEnter((_b = (_a = searchRef.current) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : "");
        searchRef.current && ((_d = (_c = searchRef.current) === null || _c === void 0 ? void 0 : _c.focus) === null || _d === void 0 ? void 0 : _d.call(_c));
    }, [onEnter]);
    const handleClear = useCallback((event) => {
        var _a, _b;
        onClear === null || onClear === void 0 ? void 0 : onClear(event);
        handleChange("");
        searchRef.current && ((_b = (_a = searchRef.current) === null || _a === void 0 ? void 0 : _a.focus) === null || _b === void 0 ? void 0 : _b.call(_a));
    }, [handleChange, onClear]);
    return (_jsxs("div", { className: clsx("search", className), onKeyDown: (e) => {
            var _a, _b;
            if (e.key !== "Escape")
                return;
            ((_a = searchRef.current) === null || _a === void 0 ? void 0 : _a.value) && ((_b = searchRef.current) === null || _b === void 0 ? void 0 : _b.value) !== "" && e.preventDefault();
            handleClear({ trigger: "Escape", event: e });
        }, children: [_jsxs("div", { className: "search__wrapper", children: [_jsx("input", Object.assign({}, omit(rest, ["size", "readOnly"]), { className: "search__input", ref: mergedRef, type: "search", placeholder: placeholder, value: value !== null && value !== void 0 ? value : internalValue, onChange: (e) => handleChange(e.target.value) })), _jsxs("button", { className: "search__clear-btn", onClick: (e) => handleClear({ trigger: "Click", event: e }), children: [_jsx("span", { className: "search__clear-btn--sr-only", children: "T\u00F8m" }), _jsx(CloseOutlined, { "aria-hidden": true, width: "0.75em", height: "0.75em" })] })] }), _jsx(Button, { className: "search__search-btn", onClick: handleOnEnter, onKeyDown: (e) => {
                    if (e.key !== "Enter")
                        return;
                    handleOnEnter();
                }, tabIndex: onChange ? -1 : 0, "aria-label": "Search button", icon: _jsx(MagnifyingGlassOutlined, { color: "var(--avinor-white)" }) })] }));
});
export default Search;
