import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./ToggleUtcButton.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useUtcContext } from "@contexts/UtcContext";
const styleSheet = {
    utc: "utc",
    utcButton: "utc__button",
    utcButtonActive: "utc__button--active",
    utcSwitch: "utc__switch",
    utcSwitchActive: "utc__switch--active",
};
const ToggleUTCButton = () => {
    const { t } = useTranslation();
    const { utc, setUtc } = useUtcContext();
    const handleKeyDown = (event) => {
        if (event.key === "Enter" || event.key === " ") {
            setUtc((prevUtc) => !prevUtc);
            event.preventDefault();
        }
    };
    return (_jsxs("div", { className: styleSheet.utc, tabIndex: 0, onKeyDown: handleKeyDown, children: [_jsx("button", { className: classNames(styleSheet.utcButton, { [styleSheet.utcButtonActive]: utc }), "aria-pressed": utc, "aria-label": "Toggle UTC Time", tabIndex: -1, children: _jsx("div", { className: classNames(styleSheet.utcSwitch, { [styleSheet.utcSwitchActive]: utc }) }) }), _jsx("span", { children: t("showTimeInUtc") })] }));
};
export default ToggleUTCButton;
