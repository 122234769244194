export const findTopN = (arr, n) => {
    return arr
        .slice()
        .sort((a, b) => {
        return b.totalDelays - a.totalDelays;
    })
        .slice(0, n);
};
export const isUnknownDelayCode = (code) => {
    {
        return iataDelayCodes.every((item) => item.shortName !== code);
    }
};
export const iataDelayCodes = [
    {
        shortName: "00",
        fullName: "AIRLINE INTERNAL CODES.",
    },
    {
        shortName: "01",
        fullName: "AIRLINE INTERNAL CODES.",
    },
    {
        shortName: "02",
        fullName: "AIRLINE INTERNAL CODES.",
    },
    {
        shortName: "03",
        fullName: "AIRLINE INTERNAL CODES.",
    },
    {
        shortName: "04",
        fullName: "AIRLINE INTERNAL CODES.",
    },
    {
        shortName: "05",
        fullName: "AIRLINE INTERNAL CODES.",
    },
    {
        shortName: "OA",
        fullName: "NO GATE STAND AVAILABILITY DUE TO OWN AIRLINE ACTIVITY (Including Early Arrivals).",
    },
    {
        shortName: "SG",
        fullName: "SCHEDULED GROUND TIME LESS THAN DECLARED MINIMUM GROUND TIME.",
    },
    {
        shortName: "PD",
        fullName: "LATE CHECK-IN, acceptance after deadline.",
    },
    {
        shortName: "PL",
        fullName: "LATE CHECK-IN, congestions in check-in area.",
    },
    {
        shortName: "PE",
        fullName: "CHECK-IN ERROR, passenger and baggage.",
    },
    {
        shortName: "PO",
        fullName: "OVERSALES, booking errors.",
    },
    {
        shortName: "PH",
        fullName: " BOARDING, discrepancies and paging, missing checked-in passenger.",
    },
    {
        shortName: "PS",
        fullName: "COMMERCIAL PUBLICITY PASSENGER CONVENIENCE, VIP, press, ground meals and missing personal items.",
    },
    {
        shortName: "PC",
        fullName: "CATERING ORDER, late or incorrect order given to supplier.",
    },
    {
        shortName: "PB",
        fullName: "BAGGAGE PROCESSING, sorting etc.",
    },
    {
        shortName: "PW",
        fullName: "REDUCED MOBILITY, boarding deboarding of passengers with reduced mobility.",
    },
    {
        shortName: "CD",
        fullName: "DOCUMENTATION, errors etc.",
    },
    {
        shortName: "CP",
        fullName: "LATE POSITIONING.",
    },
    {
        shortName: "CC",
        fullName: "LATE ACCEPTANCE.",
    },
    {
        shortName: "CI",
        fullName: "INADEQUATE PACKING.",
    },
    {
        shortName: "CO",
        fullName: "OVERSALES, booking errors.",
    },
    {
        shortName: "CU",
        fullName: "LATE PREPARATION IN WAREHOUSE.",
    },
    {
        shortName: "CE",
        fullName: "DOCUMENTATION, PACKING etc. (Mail Only).",
    },
    {
        shortName: "CL",
        fullName: "LATE POSITIONING (Mail Only).",
    },
    {
        shortName: "CA",
        fullName: "LATE ACCEPTANCE (Mail Only).",
    },
    {
        shortName: "GD",
        fullName: "AIRCRAFT DOCUMENTATION LATE INACCURATE, weight and balance, general declaration, pax manifest, etc.",
    },
    {
        shortName: "GL",
        fullName: "LOADING UNLOADING, bulky, special load, cabin load, lack of loading staff.",
    },
    {
        shortName: "GE",
        fullName: "LOADING EQUIPMENT, lack of or breakdown, e.g. container pallet loader, lack of staff.",
    },
    {
        shortName: "GS",
        fullName: "SERVICING EQUIPMENT, lack of or breakdown, lack of staff, e.g. steps.",
    },
    {
        shortName: "GC",
        fullName: "AIRCRAFT CLEANING.",
    },
    {
        shortName: "GF",
        fullName: "FUELLING DEFUELLING, fuel supplier.",
    },
    {
        shortName: "GB",
        fullName: "CATERING, late delivery or loading.",
    },
    {
        shortName: "GU",
        fullName: "ULD, lack of or serviceability.",
    },
    {
        shortName: "GT",
        fullName: "TECHNICAL EQUIPMENT, lack of or breakdown, lack of staff, e.g. pushback.",
    },
    {
        shortName: "TD",
        fullName: "AIRCRAFT DEFECTS.",
    },
    {
        shortName: "TM",
        fullName: "SCHEDULED MAINTENANCE, late release.",
    },
    {
        shortName: "TN",
        fullName: "NON-SCHEDULED MAINTENANCE, special checks and or additional works beyond normal maintenance schedule.",
    },
    {
        shortName: "TS",
        fullName: "SPARES AND MAINTENANCE EQUIPMENT, lack of or breakdown.",
    },
    {
        shortName: "TA",
        fullName: "AOG SPARES, to be carried to another station.",
    },
    {
        shortName: "TC",
        fullName: "AIRCRAFT CHANGE, for technical reasons.",
    },
    {
        shortName: "TL",
        fullName: "STAND-BY AIRCRAFT, lack of planned stand-by aircraft for technical reasons.",
    },
    {
        shortName: "TV",
        fullName: "SCHEDULED CABIN CONFIGURATION VERSION ADJUSTMENTS.",
    },
    {
        shortName: "DF",
        fullName: "DAMAGE DURING FLIGHT OPERATIONS, bird or lightning strike, turbulence, heavy or overweight landing, collision during taxiing.",
    },
    {
        shortName: "DG",
        fullName: "DAMAGE DURING GROUND OPERATIONS, collisions (other than during taxiing), loading off-loading damage, contamination, towing, extreme weather conditions.",
    },
    {
        shortName: "ED",
        fullName: "DEPARTURE CONTROL.",
    },
    {
        shortName: "EC",
        fullName: "CARGO PREPARATION DOCUMENTATION.",
    },
    {
        shortName: "EF",
        fullName: "FLIGHT PLANS.",
    },
    {
        shortName: "EO",
        fullName: "OTHER AUTOMATED SYSTEM.",
    },
    {
        shortName: "FP",
        fullName: "FLIGHT PLAN, late completion or change of, flight documentation.",
    },
    {
        shortName: "FF",
        fullName: "OPERATIONAL REQUIREMENTS, fuel, load alteration.",
    },
    {
        shortName: "FT",
        fullName: "LATE CREW BOARDING OR DEPARTURE PROCEDURES, other than connection and standby (flight deck or entire crew).",
    },
    {
        shortName: "FS",
        fullName: "FLIGHT DECK CREW SHORTAGE, sickness, awaiting standby, flight time limitations, crew meals, valid visa, health documents, etc.",
    },
    {
        shortName: "FR",
        fullName: "FLIGHT DECK CREW SPECIAL REQUEST, not within operational requirements.",
    },
    {
        shortName: "FL",
        fullName: "LATE CABIN CREW BOARDING OR DEPARTURE PROCEDURES, other than connection and standby.",
    },
    {
        shortName: "FC",
        fullName: "CABIN CREW SHORTAGE, sickness, awaiting standby, flight time limitations, crew meals, valid visa, health documents, etc.",
    },
    {
        shortName: "FA",
        fullName: "CABIN CREW ERROR OR SPECIAL REQUEST, not within operational requirements.",
    },
    {
        shortName: "FB",
        fullName: "CAPTAIN REQUEST FOR SECURITY CHECK, extraordinary.",
    },
    {
        shortName: "WO",
        fullName: "DEPARTURE STATION.",
    },
    {
        shortName: "WT",
        fullName: "DESTINATION STATION.",
    },
    {
        shortName: "WR",
        fullName: "EN ROUTE OR ALTERNATE.",
    },
    {
        shortName: "WI",
        fullName: "DE-ICING OF AIRCRAFT, removal of ice and or snow, frost prevention excluding unserviceability of equipment.",
    },
    {
        shortName: "WS",
        fullName: "REMOVAL OF SNOW, ICE, WATER AND SAND FROM AIRPORT.",
    },
    {
        shortName: "WG",
        fullName: "GROUND HANDLING IMPAIRED BY ADVERSE WEATHER CONDITIONS.",
    },
    {
        shortName: "AT",
        fullName: "ATFM due to ATC EN-ROUTE DEMAND CAPACITY, standard demand capacity problems.",
    },
    {
        shortName: "AX",
        fullName: "ATFM due to ATC STAFF EQUIPMENT EN-ROUTE, reduced capacity caused by industrial action or staff shortage, equipment failure, military exercise or extraordinary demand due to capacity reduction in neighbouring area.",
    },
    {
        shortName: "AE",
        fullName: "ATFM due to RESTRICTION AT DESTINATION AIRPORT, airport and or runway closed due to obstruction, industrial action, staff shortage, political unrest, noise abatement, night curfew, special flights.",
    },
    {
        shortName: "AW",
        fullName: "ATFM due to WEATHER AT DESTINATION.",
    },
    {
        shortName: "AS",
        fullName: "MANDATORY SECURITY.",
    },
    {
        shortName: "AG",
        fullName: "IMMIGRATION, CUSTOMS, HEALTH.",
    },
    {
        shortName: "AF",
        fullName: "AIRPORT FACILITIES, parking stands, ramp congestion, lighting, buildings, gate limitations, etc.",
    },
    {
        shortName: "AD",
        fullName: "RESTRICTIONS AT AIRPORT OF DESTINATION, airport and or runway closed due to obstruction, industrial action, staff shortage, political unrest, noise abatement, night curfew, special flights.",
    },
    {
        shortName: "AM",
        fullName: "RESTRICTIONS AT AIRPORT OF DEPARTURE WITH OR WITHOUT ATFM RESTRICTIONS, including Air Traffic Services, start-up and pushback, airport and or runway closed due to obstruction or weather, industrial action, staff shortage, political unrest, noise abatement, night curfew, special flights.",
    },
    {
        shortName: "RL",
        fullName: "LOAD CONNECTION, awaiting load from another flight.",
    },
    {
        shortName: "RT",
        fullName: "THROUGH CHECK-IN ERROR, passenger and baggage.",
    },
    {
        shortName: "RA",
        fullName: "AIRCRAFT ROTATION, late arrival of aircraft from another flight or previous sector.",
    },
    {
        shortName: "RS",
        fullName: "CABIN CREW ROTATION, awaiting cabin crew from another flight.",
    },
    {
        shortName: "RC",
        fullName: "CREW ROTATION, awaiting crew from another flight (flight deck or entire crew).",
    },
    {
        shortName: "RO",
        fullName: "OPERATIONS CONTROL, re-routing, diversion, consolidation, aircraft change for reasons other than technical.",
    },
    {
        shortName: "MI",
        fullName: "INDUSTRIAL ACTION WITH OWN AIRLINE.",
    },
    {
        shortName: "MO",
        fullName: "INDUSTRIAL ACTION OUTSIDE OWN AIRLINE, excluding ATS.",
    },
    {
        shortName: "MX",
        fullName: "OTHER REASON, not matching any other codes.",
    },
];
