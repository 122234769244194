export var Delay;
(function (Delay) {
    Delay[Delay["TenSeconds"] = 10000] = "TenSeconds";
    Delay[Delay["FifteenSeconds"] = 15000] = "FifteenSeconds";
    Delay[Delay["ThirtySeconds"] = 30000] = "ThirtySeconds";
    Delay[Delay["OneMinute"] = 60000] = "OneMinute";
    Delay[Delay["TwoMinutes"] = 120000] = "TwoMinutes";
    Delay[Delay["FiveMinutes"] = 300000] = "FiveMinutes";
    Delay[Delay["FifteenMinutes"] = 900000] = "FifteenMinutes";
    Delay[Delay["ThirtyMinutes"] = 1800000] = "ThirtyMinutes";
    Delay[Delay["OneHour"] = 3600000] = "OneHour";
    Delay[Delay["TwentyFourHours"] = 86400000] = "TwentyFourHours";
    Delay[Delay["SaveSuccessDelay"] = 1000] = "SaveSuccessDelay";
})(Delay || (Delay = {}));
