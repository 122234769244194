import { jsx as _jsx } from "react/jsx-runtime";
import Spinner from "@components/Spinner/Spinner";
import { AvinorColor } from "@constants";
import { PieChart } from "@charts/apexcharts/PieChart";
import useDelayNumbersData from "../hooks/useDelayNumbersData";
export const DelayAirportOperativeDelaysPieChart = () => {
    const { data: delayNumbers, isLoading } = useDelayNumbersData();
    if (isLoading)
        return _jsx(Spinner, {});
    const colors = [
        AvinorColor.ChartMintGreen300,
        AvinorColor.ChartVioletDark600,
        AvinorColor.ChartVioletLight400,
        AvinorColor.ChartGrey200,
    ];
    const series = delayNumbers
        ? [delayNumbers.NoDelaysAF, delayNumbers.NoDelaysAS, delayNumbers.NoDelaysPW, delayNumbers.NoDelaysAG]
        : [];
    const labels = ["AF", "AS", "PW", "AG"];
    return (_jsx(PieChart, { colors: colors, series: series, labels: labels }));
};
