import { jsx as _jsx } from "react/jsx-runtime";
import { isToday } from "date-fns";
import { useTranslation } from "react-i18next";
import { getDateToReadableText } from "@utils/DateUtils";
import { FlightMovementsDirectionFilterProvider } from "@features/FlightMovements/contexts/FlightMovementsDirectionFilterContext";
import { todayWidgetFlightInformation, widgetFlightInformation } from "@widgets/registry";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useIataContext } from "@contexts/IataContext";
import PageLayout from "./PageLayout/PageLayout";
const FlightMovementsPage = () => {
    const { iata: airport } = useIataContext();
    const { t } = useTranslation();
    const { picker: { id, from }, } = useDatePickerContext();
    return (_jsx(FlightMovementsDirectionFilterProvider, { children: _jsx(PageLayout, { customDatePickerProps: {
                id,
            }, title: `${t("pages.flightMovements.title")} ${getDateToReadableText().toLowerCase()}`, description: t("pages.flightMovements.description", { iata: airport }), widgets: isToday(from) ? todayWidgetFlightInformation : widgetFlightInformation }) }));
};
export default FlightMovementsPage;
