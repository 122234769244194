export const transformTrainsDetails = (trainsStatuses) => {
    const excludedArrivalStatuses = ["ON_TIME", "ARRIVED"];
    const excludedDepartureStatuses = ["ON_TIME"];
    const arrival = trainsStatuses.flatMap((trainStatus) => {
        var _a;
        return (_a = trainStatus.trainDetails) === null || _a === void 0 ? void 0 : _a.map(({ aimedArrivalTime, expectedArrivalTime, arrivalDelayInMin, trainLine, destination, arrivalStatus, }) => ({
            operator: trainStatus.operator,
            arrivalTime: {
                aimedArrivalTime,
                expectedArrivalTime,
                arrivalStatus,
            },
            delayInMin: arrivalDelayInMin,
            route: {
                trainLine,
                destination,
            },
        })).filter(({ arrivalTime }) => arrivalTime.aimedArrivalTime &&
            arrivalTime.expectedArrivalTime &&
            !excludedArrivalStatuses.includes(arrivalTime.arrivalStatus));
    });
    const departure = trainsStatuses.flatMap((trainStatus) => {
        var _a;
        return (_a = trainStatus.trainDetails) === null || _a === void 0 ? void 0 : _a.map(({ aimedDepartureTime, expectedDepartureTime, departureDelayInMin, trainLine, destination, departureStatus, }) => ({
            operator: trainStatus.operator,
            departureTime: {
                aimedDepartureTime,
                expectedDepartureTime,
                departureStatus,
            },
            delayInMin: departureDelayInMin,
            route: {
                trainLine,
                destination,
            },
        })).filter(({ departureTime }) => departureTime.aimedDepartureTime &&
            departureTime.expectedDepartureTime &&
            !excludedDepartureStatuses.includes(departureTime.departureStatus));
    });
    return { arrival, departure };
};
