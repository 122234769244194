import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
import { EDeIceTimes, EWeatherCondition } from "../enums";
const DeIceWeatherContext = createContext(undefined);
const deIceTimeLookup = {
    [EWeatherCondition.SN]: EDeIceTimes.SN,
    [EWeatherCondition.PSN]: EDeIceTimes.PSN,
    [EWeatherCondition.FZFG]: EDeIceTimes.FZFG,
    [EWeatherCondition.FZDZ]: EDeIceTimes.FZDZ,
    [EWeatherCondition.FZRA]: EDeIceTimes.FZRA,
};
export const DeIceWeatherProvider = ({ children }) => {
    const [deIceWeather, setDeIceWeather] = useState("fog");
    const getWeatherTypeKey = () => {
        const entry = Object.entries(EWeatherCondition).find(([, value]) => value === deIceWeather);
        return entry ? entry[0] : undefined;
    };
    return (_jsx(DeIceWeatherContext.Provider, { value: { deIceWeather, setDeIceWeather, getWeatherTypeKey }, children: children }));
};
export const useDeIceWeatherContext = () => {
    const context = useContext(DeIceWeatherContext);
    if (!context) {
        throw new Error("useDeIceWeatherContext must be used within a DeIceWeatherProvider");
    }
    return Object.assign(Object.assign({}, context), { deIceTime: deIceTimeLookup[context.deIceWeather] });
};
