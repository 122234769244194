import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ProgressCard } from "@components/ProgressCard/ProgressCard";
import { useIataContext } from "@contexts/IataContext";
import { getEnroutePaxData } from "../services";
export const PaxEnroute = () => {
    const { iata } = useIataContext();
    const { data: enroutePax } = useQuery({
        queryFn: () => getEnroutePaxData({ iata, hoursTo: 1 }),
        queryKey: ["getEnroutePaxData", { iata }],
    });
    const { t } = useTranslation();
    return (_jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", value: (enroutePax === null || enroutePax === void 0 ? void 0 : enroutePax.enroutePax) || 0, description: t("pax.widgets.enroute.description") }));
};
PaxEnroute.displayName = "PaxEnroute";
