import { useQuery } from "@tanstack/react-query";
import { useIataContext } from "@contexts/IataContext";
import { getFacilityStatusOverviewData } from "../services";
const useFacilityStatusOverviewData = (active = false) => {
    const { iata } = useIataContext();
    const { data, error, isLoading } = useQuery({
        queryFn: () => getFacilityStatusOverviewData({
            iata,
            active,
        }),
        queryKey: ["getFacilityStatusOverviewData", { active, iata }],
    });
    return { data, error, iata, active, isLoading };
};
export default useFacilityStatusOverviewData;
