import { jsx as _jsx } from "react/jsx-runtime";
import { getDateToReadableText } from "@utils/DateUtils";
import { WidgetCard } from "@components/WidgetCard/WidgetCard";
import useDeIceData from "../hooks/useDeIceData";
export const DeIceAverageTimePerAircraft = ({ id }) => {
    const { data: deIceData } = useDeIceData();
    const deIceAverageTimePerAircraft = deIceData && deIceData.platforms[id].deIceAverageTimePerAircraft;
    const title = "De-ice per fly (gjennomsnitt)";
    return (_jsx(WidgetCard, { title: title, description: getDateToReadableText(), error: !deIceAverageTimePerAircraft, children: deIceAverageTimePerAircraft }));
};
DeIceAverageTimePerAircraft.displayName = "DeIceAverageTimePerAircraft";
