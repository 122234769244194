import { FacilityErrorCountWidget, FacilityOutOfOrderWidget } from "@features/Facility/widgets";
import { DefaultPermissions } from "@widgets/registry";
export const facilityWidgets = [
    {
        name: FacilityOutOfOrderWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [6, 6, 6],
        component: FacilityOutOfOrderWidget.displayName,
        componentProps: {
            minHeight: 206,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FacilityErrorCountWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [7, 7, 7],
        rowSpan: [1, 1, 1],
        columnSpan: [6, 6, 6],
        component: FacilityErrorCountWidget.displayName,
        componentProps: {
            minHeight: 206,
        },
        permissions: DefaultPermissions,
    },
];
