import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { PeopleGroup3Outlined } from "@avinor/react-icons";
import Widget from "@components/Widget/Widget";
import { PaxPredictionGraph } from "../components/PaxPredictionGraph";
export const PaxPredictionGraphWidget = ({ title, subTitle, footerText, }) => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t(title), subtitle: t(subTitle), icon: {
                    component: (_jsx(PeopleGroup3Outlined, { width: "1.5rem", height: "1.5rem" })),
                } }), _jsx(Widget.Body, { children: _jsx(PaxPredictionGraph, {}) }), _jsx(Widget.Footer, { children: footerText })] }));
};
PaxPredictionGraphWidget.displayName = "PaxPredictionGraphWidget";
