import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
const DeIceHandlerContext = createContext(undefined);
export const DeIceHandlerProvider = ({ children }) => {
    const [selectedDeIceHandlers, setSelectedDeIceHandlers] = useState([]);
    const [deIcePads, setDeIcePads] = useState([]);
    return (_jsx(DeIceHandlerContext.Provider, { value: {
            selectedDeIceHandlers,
            setSelectedDeIceHandlers,
            deIcePads,
            setDeIcePads,
        }, children: children }));
};
export const useDeIceHandlerContext = () => {
    const context = useContext(DeIceHandlerContext);
    if (!context) {
        throw new Error("useDeIceHandlerContext must be used within a DeIceHandlerProvider");
    }
    return context;
};
