import xmlParser from "fast-xml-parser";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getNamespace(baseObject, desiredKey) {
    for (const key in baseObject) {
        if (key.includes(desiredKey)) {
            return key;
        }
    }
    throw new Error(`Namespace not found for key: ${desiredKey}`);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchTrafficCameraUrls = async (datex, cameraIds) => {
    try {
        const parser = new xmlParser.XMLParser();
        const parsed = await parser.parse(datex);
        const messageContainerKey = getNamespace(parsed, "messageContainer");
        const payloadKey = getNamespace(parsed[messageContainerKey], "payload");
        const publicationKey = getNamespace(parsed[messageContainerKey][payloadKey], "genericPublicationExtension");
        const siteTablePublicationKey = getNamespace(parsed[messageContainerKey][payloadKey][publicationKey], "cctvSiteTablePublication");
        const cameraListKey = getNamespace(parsed[messageContainerKey][payloadKey][publicationKey][siteTablePublicationKey], "cctvCameraList");
        const metadataRecordKey = getNamespace(parsed[messageContainerKey][payloadKey][publicationKey][siteTablePublicationKey][cameraListKey], "cctvCameraMetadataRecord");
        const cameras = parsed[messageContainerKey][payloadKey][publicationKey][siteTablePublicationKey][cameraListKey][metadataRecordKey];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const camerasFiltered = cameras.filter((cam) => cameraIds.some((x) => x === cam[getNamespace(cam, "cctvCameraIdentification")]));
        if (camerasFiltered.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const cameraUrls = camerasFiltered.map((camera, index) => {
                const identificationKey = getNamespace(camera, "cctvCameraIdentification");
                const localDescriptionKey = getNamespace(camera, "cctvCameraSiteLocalDescription");
                const orientationDescriptionKey = getNamespace(camera, "cctvCameraOrientationDescription");
                const locationKey = getNamespace(camera, "cctvCameraLocation");
                const supplementaryPositionalDescriptionKey = getNamespace(camera[locationKey], "supplementaryPositionalDescription");
                const roadInformationKey = getNamespace(camera[locationKey][supplementaryPositionalDescriptionKey], "roadInformation");
                const videoServiceKey = getNamespace(camera, "cctvVideoService");
                const videoUrlKey = getNamespace(camera[videoServiceKey], "videoUrl");
                const stillImageServiceKey = getNamespace(camera, "cctvStillImageService");
                const stillImageUrlKey = getNamespace(camera[stillImageServiceKey], "stillImageUrl");
                const pointByCoordinatesKey = getNamespace(camera[locationKey], "pointByCoordinates");
                const pointCoordinatesKey = getNamespace(camera[locationKey][pointByCoordinatesKey], "pointCoordinates");
                const latitudeKey = getNamespace(camera[locationKey][pointByCoordinatesKey][pointCoordinatesKey], "latitude");
                const longitudeKey = getNamespace(camera[locationKey][pointByCoordinatesKey][pointCoordinatesKey], "longitude");
                return {
                    id: ++index,
                    title: camera[localDescriptionKey]["values"]["value"],
                    direction: camera[orientationDescriptionKey],
                    road: camera[locationKey][supplementaryPositionalDescriptionKey][roadInformationKey]["roadNumber"],
                    videoUrl: camera[videoServiceKey][videoUrlKey]["urlLinkAddress"],
                    stillImageUrl: camera[stillImageServiceKey][stillImageUrlKey]["urlLinkAddress"],
                    link: "https://kamera.atlas.vegvesen.no/api/images/" + camera[identificationKey],
                    coordinates: {
                        lat: parseFloat(camera[locationKey][pointByCoordinatesKey][pointCoordinatesKey][latitudeKey]),
                        lng: parseFloat(camera[locationKey][pointByCoordinatesKey][pointCoordinatesKey][longitudeKey]),
                    },
                };
            });
            return cameraUrls;
        }
        else {
            throw new Error("Camera not found");
        }
    }
    catch (error) {
        console.error("Error fetching traffic camera image:", error);
        throw error;
    }
};
