import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { roundIfNeeded } from "@utils/Utils";
import Progress from "@components/Progress/Progress";
import useRegularityAndPunctualityPeriodData from "@features/OnTimePerformance/hooks/useRegularityAndPunctualityPeriodData";
export const BaggageFullFlights = () => {
    /**TODO: Change the endpoint to the correct one */
    const { data: punctualityTotalData } = useRegularityAndPunctualityPeriodData({ paxOnly: false, direction: "A" });
    const { t } = useTranslation();
    const progress = punctualityTotalData === null || punctualityTotalData === void 0 ? void 0 : punctualityTotalData.totalPunctuality;
    const operatedFlights = punctualityTotalData === null || punctualityTotalData === void 0 ? void 0 : punctualityTotalData.totalFlightsOperated;
    const plannedFlights = punctualityTotalData === null || punctualityTotalData === void 0 ? void 0 : punctualityTotalData.totalFlights;
    return (_jsx(Progress, { completedPercentage: progress, text: t("common.progressStatusCompletedAndPlanned", {
            percent: roundIfNeeded(progress || 0),
            completed: operatedFlights,
            planned: plannedFlights,
        }), withPadding: true }));
};
BaggageFullFlights.displayName = "BaggageFullFlights";
