import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { areDatesTheSame } from "@utils/DateUtils";
import { ProgressCard } from "@components/ProgressCard/ProgressCard";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import { useFlowControl } from "@features/FlightMovements/hooks";
import { useUtcContext } from "@contexts/UtcContext";
export const OnTimePerformanceServiceQualityFlowRegulationWidget = () => {
    const { flowControl } = useFlowControl();
    const { flowControlStartTime, flowControlEndTime, sourceTimestamp } = flowControl !== null && flowControl !== void 0 ? flowControl : {};
    const { utc } = useUtcContext();
    const formattedStart = formatDateTime(flowControlStartTime !== null && flowControlStartTime !== void 0 ? flowControlStartTime : "", utc);
    const formattedEnd = formatDateTime(flowControlEndTime !== null && flowControlEndTime !== void 0 ? flowControlEndTime : "", utc);
    const formattedTimestamp = formatDateTime(sourceTimestamp !== null && sourceTimestamp !== void 0 ? sourceTimestamp : "", utc);
    const isNormalFlow = (flowControl === null || flowControl === void 0 ? void 0 : flowControl.flowControlReason) === "NOLIMIT";
    return (_jsx(_Fragment, { children: _jsxs(Widget.Container, { style: { justifyContent: "space-between" }, children: [_jsx(Widget.Header, { title: "Flow regulation (Arrival)", subtitle: flowControl ? (_jsxs(_Fragment, { children: [_jsx("span", { style: { color: "var(--avinor-grey-800)" }, children: flowControl.flowControlRegulationType }), flowControl.flowControlRemark && (_jsxs(_Fragment, { children: [" ", "- ", !isNormalFlow && "Due to ", " ", flowControl.flowControlRemark] }))] })) : (_jsx(_Fragment, {})) }), _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsxs(Widget.Body, { style: { paddingTop: "0" }, children: [_jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", title: "", value: (flowControl === null || flowControl === void 0 ? void 0 : flowControl.flowControlRate) !== undefined
                                    ? isNormalFlow
                                        ? "N/A"
                                        : flowControl === null || flowControl === void 0 ? void 0 : flowControl.flowControlRate
                                    : "-" }), flowControl &&
                                !isNormalFlow &&
                                (areDatesTheSame(flowControlStartTime, flowControlEndTime) ? (_jsxs(_Fragment, { children: [formattedStart.date, " from ", formattedStart.time, " til ", formattedEnd.time] })) : (_jsxs(_Fragment, { children: ["From ", formattedStart.date, " - ", formattedStart.time, " til ", formattedEnd.date, " -", " ", formattedEnd.time] }))), _jsx("p", { style: { fontSize: "0.875rem", color: "var(--avinor-grey-200)" }, children: !isNormalFlow ? (_jsxs(_Fragment, { children: ["Set ", formattedTimestamp.date, " - ", formattedTimestamp.time, " (NMOC)"] })) : (_jsxs(_Fragment, { children: ["Last updated: ", formattedTimestamp.date, " - ", formattedTimestamp.time] })) })] }) })] }) }));
};
OnTimePerformanceServiceQualityFlowRegulationWidget.displayName = "OnTimePerformanceServiceQualityFlowRegulationWidget";
const formatDateTime = (dateTime, showInUTC) => {
    if (!dateTime) {
        return { date: "", time: "" };
    }
    const options = {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false, // Ensure 24-hour format
    };
    const date = new Date(dateTime);
    if (showInUTC) {
        options.timeZone = "UTC"; // Set to UTC time zone
    }
    const formattedDateTime = date.toLocaleString("no-NO", options);
    const [formattedDate, formattedTime] = formattedDateTime.split(", ");
    return { date: formattedDate, time: formattedTime };
};
