import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Cell } from "@table-library/react-table-library/table";
import { format, isToday, isYesterday } from "date-fns";
import { t } from "i18next";
import { removeZuluSuffix } from "@utils/DateUtils";
import { VStack } from "@components/Layout";
import { AvinorColor } from "@constants";
import { FormatPattern } from "@enums";
/**
 * Renders a table cell with potential asterisk marking based on the bagfactorDefaulted property.
 *
 * If the row's bagfactorDefaulted property is true, an asterisk is appended to the cell value.
 *
 * @param row - The data row being processed.
 * @param cellIndex - The index of the cell being rendered.
 * @param cellValue - The value to be displayed in the cell.
 * @returns A JSX Cell component with the specified cellValue, and an asterisk if bagfactorDefaulted is true.
 */
export const renderCellWithBagFactorMarking = (row, cellIndex, cellValue) => {
    if (row.bagfactorDefaulted) {
        return _jsxs(Cell, { children: [cellValue, "*"] }, cellIndex);
    }
    return _jsx(Cell, { children: cellValue }, cellIndex);
};
/**
 * Formats the value for specified columns as a date-time string.
 *
 * This function checks if the provided key is part of the specified date columns. If it is, the value
 * is passed to a date-time formatting function. If not, the original value is returned unchanged.
 *
 * @param dateColumns - An optional array of column keys that should have their values formatted as date-time.
 * @param key - The key of the current column being processed.
 * @param value - The corresponding value of the current column.
 * @returns If the key matches one of the date columns, a formatted date-time string; otherwise, the original value.
 */
export const formatDateForSpecifiedColumns = (dateColumns, key, value) => {
    if (dateColumns && dateColumns.includes(key)) {
        return formatDateTime(value, false);
    }
    return value;
};
export const formatDateTimeForTable = (inputDate, isZulu) => {
    const date = new Date(inputDate);
    let stringDate = "";
    if (!isZulu) {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = String(date.getFullYear()).slice(2);
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        stringDate = `${day}.${month}.${year} kl. ${hours}:${minutes}`;
    }
    else {
        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const year = String(date.getUTCFullYear()).slice(2);
        const hours = String(date.getUTCHours()).padStart(2, "0");
        const minutes = String(date.getUTCMinutes()).padStart(2, "0");
        stringDate = `${day}.${month}.${year} kl. ${hours}:${minutes} Z`;
    }
    return stringDate;
};
export const getCasePriorityColor = (row) => {
    const priorityColors = {
        Kritisk: {
            borderColor: AvinorColor.RedAlarmPrimary,
            backgroundColor: AvinorColor.RedAlarmPrimaryLight,
            color: AvinorColor.RedAlarmPrimaryDark,
        },
        Høy: {
            borderColor: AvinorColor.YellowWarningPrimary,
            backgroundColor: AvinorColor.YellowWarningPrimaryLight,
            color: AvinorColor.YellowWarningPrimaryDark,
        },
        default: {
            borderColor: AvinorColor.Grey100,
            backgroundColor: AvinorColor.White,
        },
    };
    const getColor = (priority) => priorityColors[priority] || priorityColors.default;
    const { backgroundColor, borderColor, color = AvinorColor.Grey800 } = getColor(row.casePriority);
    return {
        backgroundColor,
        borderColor,
        color,
    };
};
/**
 * Handles the rendering of boolean values for specified columns.
 *
 * Given a list of columns that are meant to contain boolean values and a specific key-value pair,
 * this function checks if the key belongs to one of those columns. If it does, and the value is truthy,
 * it returns the localized string for "yes"; if the value is falsy, it returns the localized string for "no".
 * For all other cases (e.g., the key isn't in the list or the value isn't a boolean), the original value is returned.
 *
 * @param booleanColumns - An optional list of column keys that are meant to contain boolean values.
 * @param key - The key of the current column.
 * @param value - The value in the current column.
 * @returns The localized strings for "yes" or "no" if the conditions are met; otherwise, the original value.
 */
export const handleBooleanValues = (booleanColumns, key, value) => {
    if (booleanColumns === null || booleanColumns === void 0 ? void 0 : booleanColumns.includes(key)) {
        return value ? t("yes") : t("no");
    }
    return value;
};
/**
 * Handles missing or undefined values in table cells.
 *
 * If the provided value is null, undefined, or an empty string, the function returns a placeholder "-".
 * Special case: A value of 0 (zero) is considered valid and will not be replaced.
 *
 * @param value - The value to be checked and possibly replaced.
 * @returns The original value if it's defined and not an empty string (or if it's explicitly 0); otherwise, a placeholder "-".
 */
export const handleMissingDataInCell = (value) => (value || value === 0 ? value : "-");
export const addIdToObj = (arr) => {
    return {
        // Add a nodes property to the object to fit table component
        nodes: arr.map((item, index) => {
            // For each item in the array, add an id property
            return Object.assign({ id: index + 1 }, item);
        }),
    };
};
export const formatDateTime = (date, withPrefix = true) => {
    const getFormat = () => {
        return FormatPattern.HH_mm;
    };
    const getDate = () => {
        return new Date(removeZuluSuffix(date));
    };
    const getPrefix = () => {
        if (withPrefix) {
            return t("timePrefix");
        }
        return "";
    };
    return `${getPrefix()} ${format(getDate(), getFormat())}`;
};
/**
 * Format a date string based on how recent it is.
 *
 * @param index - The index that will be used as a key for the returned Cell component.
 * @param timestamp - The date string, expected to be in ISO format, e.g. "2023-09-11T15:45:00.000Z"
 * @returns A Cell JSX component with the formatted date.
 */
export const formatRecentDate = (timestamp, index, translation) => {
    const messageDate = new Date(timestamp);
    const translate = (key, options) => translation ? translation(key, options) : "";
    if (isToday(messageDate)) {
        return (_jsx(Cell, { children: translate("formatRecentDate.today", {
                time: format(messageDate, FormatPattern.HH_mm),
            }) }, index));
    }
    if (isYesterday(messageDate)) {
        return (_jsx(Cell, { children: translate("formatRecentDate.yesterday", {
                time: format(messageDate, FormatPattern.HH_mm),
            }) }, index));
    }
    return (_jsx(Cell, { children: _jsxs(VStack, { gap: 0.25, children: [_jsx("p", { children: format(messageDate, FormatPattern.dd_MM_yy) }), _jsxs("p", { children: [translate("formatRecentDate.at"), format(messageDate, FormatPattern.HH_mm)] })] }) }, index));
};
