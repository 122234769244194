import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState, useContext } from "react";
import { Granularity } from "@enums";
const GranularityContext = createContext(undefined);
const generateKey = (key) => `${key.from}-${key.currentPage}`;
export const GranularityProvider = ({ children }) => {
    const [granularityState, setGranularityState] = useState(new Map());
    return (_jsx(GranularityContext.Provider, { value: { granularityState, setGranularityState }, children: children }));
};
export const useGranularityContext = (granularityKey) => {
    const context = useContext(GranularityContext);
    if (!context) {
        throw new Error("useGranularityContext must be used within a GranularityProvider");
    }
    const { granularityState, setGranularityState } = context;
    const setGranularity = (granularity) => {
        setGranularityState((prev) => {
            const newState = new Map(prev);
            newState.set(generateKey(granularityKey), granularity);
            return newState;
        });
    };
    const granularity = granularityState.get(generateKey(granularityKey)) || { granularity: Granularity.Hour };
    return { granularity: granularity.granularity, setGranularity };
};
