import { jsx as _jsx } from "react/jsx-runtime";
import { Cell } from "@table-library/react-table-library";
import Table from "@components/Table/Table";
import { addIdToObj } from "@components/Table/utils";
import "./OnTimePerformanceServiceQualityTableStyles.scss";
import { useArrivalPunctuality } from "@features/OnTimePerformance/hooks/useRegularityAndPunctualityPeriodData";
import { useInboundTaxitime } from "@features/OnTimePerformance/hooks/useTaxiTime";
import { OnTimePerformanceTimeframeText, PerformanceMetricCell, evaluateAlertIndicatorLevel, evaluateAlertIndicatorLevelInboundTaxiTime, headerGroups, } from "./OnTimePerformanceServiceQualityTableUtils";
import OTPThresholdsTooltip, { ThresholdValues } from "./OnTimePerformanceServiceQualityThresholdTooltip";
const cellRenderers = {
    totalPunctuality: ({ cellIndex, value, gridColumnStart, gridColumnEnd }) => {
        return (_jsx(Cell, { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, className: "OTP-table-performance-cell", children: _jsx(OTPThresholdsTooltip, { value: Math.floor(value), valueSuffix: "%", thresholdValues: ThresholdValues.Percentage, id: "totalPunctuality", evaluatingFunction: evaluateAlertIndicatorLevel }) }, cellIndex));
    },
    taxiTime: ({ cellIndex, value, gridColumnStart, gridColumnEnd }) => {
        return (_jsx(Cell, { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, className: "OTP-table-performance-cell", children: _jsx(OTPThresholdsTooltip, { value: value, valueSuffix: "min", thresholdValues: ThresholdValues.InboundTaxiTime, id: "inboundtaxtime", evaluatingFunction: evaluateAlertIndicatorLevelInboundTaxiTime }) }, cellIndex));
    },
    performanceMetrics: PerformanceMetricCell,
};
export const OnTimePerformanceServiceQualityArrivalTable = () => {
    const { data: taxiTime } = useInboundTaxitime();
    const { data: punctualityLastHour } = useArrivalPunctuality();
    const nodes = [
        {
            performanceMetrics: {
                content: (_jsx(OnTimePerformanceTimeframeText, { text: "Inbound Taxi Time", timeFrame: "avg. last hour" })),
                tooltip: {
                    id: "inboundtaxtime",
                    title: "Inbound Taxi Time",
                    description: "The average time from landing (ALDT) to stand (AIBT) last hour.",
                },
            },
            taxiTime: (taxiTime === null || taxiTime === void 0 ? void 0 : taxiTime.taxiTimeAvg) || 0,
        },
        {
            performanceMetrics: {
                content: (_jsx(OnTimePerformanceTimeframeText, { text: "Arrival Punctuality OTP", timeFrame: "avg. last hour" })),
                tooltip: {
                    id: "arrivalpunctualityotp",
                    title: "Arrival Punctuality OTP",
                    description: "Schedule In-Block (SIBT) vs. Actual In-Block(AIBT) is more than 15:59 min. The KPI is punctual flights divided by the total number of flights of the last hour.",
                },
            },
            totalPunctuality: (punctualityLastHour === null || punctualityLastHour === void 0 ? void 0 : punctualityLastHour.totalPunctuality) || 0,
        },
    ];
    return (_jsx(Table, { data: addIdToObj(nodes), headerGroups: headerGroups(), headerVisibility: "hidden", cellRenderers: cellRenderers, customTheme: {
            Cell: `
                    text-align: start;

                    & > div {
                        margin-left: 0.5rem;
                    }
                `,
        } }));
};
