import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ProgressCard } from "@components/ProgressCard/ProgressCard";
import Spinner from "@components/Spinner/Spinner";
import { useIataContext } from "@contexts/IataContext";
import { getEnrouteFlightsData } from "../services";
export const FlightMovementsEnroute = () => {
    const { iata } = useIataContext();
    const { data: enrouteFlights, isLoading } = useQuery({
        queryFn: () => getEnrouteFlightsData({ iata, hoursTo: 1 }),
        queryKey: ["getEnrouteFlightsData", { iata }],
    });
    const { t } = useTranslation();
    if (isLoading)
        return _jsx(Spinner, {});
    return (_jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", value: (enrouteFlights === null || enrouteFlights === void 0 ? void 0 : enrouteFlights.enrouteFlights) || 0, description: t("flightMovements.widgets.enroute.description") }));
};
