import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Cell } from "@table-library/react-table-library";
import { useTranslation } from "react-i18next";
import Spinner from "@components/Spinner/Spinner";
import Table from "@components/Table/Table";
import { addIdToObj } from "@components/Table/utils";
const tableColumnHeaders = {
    total: "baggage.widgets.enrouteTable.total",
    domestic: "baggage.widgets.enrouteTable.domestic",
    schengen: "baggage.widgets.enrouteTable.schengen",
    nonSchengen: "baggage.widgets.enrouteTable.nonSchengen",
};
const headers = Object.keys(tableColumnHeaders);
export const PaxPredictionTotalsTable = ({ total, totalDomestic, totalNonSchengen, totalSchengen, isLoading, }) => {
    const { t } = useTranslation();
    const getTableColumnHeaders = () => {
        return [
            {
                headers: headers.map((header) => ({
                    key: header,
                    content: t(tableColumnHeaders[header]),
                })),
            },
        ];
    };
    const customCellRenderers = {
        total: ({ cellIndex, value }) => {
            return _jsx(Cell, { children: value }, cellIndex);
        },
        totalDomestic: ({ cellIndex, value }) => {
            return _jsx(Cell, { children: value }, cellIndex);
        },
        totalNonSchengen: ({ cellIndex, value }) => {
            return _jsx(Cell, { children: value }, cellIndex);
        },
        totalSchengen: ({ cellIndex, value }) => {
            return _jsx(Cell, { children: value }, cellIndex);
        },
    };
    if (isLoading)
        return _jsx(Spinner, {});
    if (!total)
        return _jsx(_Fragment, {});
    return (_jsx(Table, { data: addIdToObj([
            {
                total,
                totalDomestic,
                totalNonSchengen,
                totalSchengen,
            },
        ]), headerGroups: getTableColumnHeaders(), cellRenderers: customCellRenderers, useDesignsystemetTheme: false }));
};
