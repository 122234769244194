import { AirportTrainsStatusesArrivalTableWidget, AirportTrainsStatusesDepartureTableWidget, } from "@features/AirportTransportation/widgets";
import { DefaultPermissions } from "@widgets/registry";
export const airportTransportationWidgets = [
    {
        name: AirportTrainsStatusesArrivalTableWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 12],
        component: AirportTrainsStatusesArrivalTableWidget.displayName,
        componentProps: {
            Component: AirportTrainsStatusesArrivalTableWidget,
            minHeight: 170,
        },
        permissions: DefaultPermissions,
    },
    {
        name: AirportTrainsStatusesDepartureTableWidget.displayName,
        gridRow: [2, 2, 2],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 12],
        component: AirportTrainsStatusesDepartureTableWidget.displayName,
        componentProps: {
            Component: AirportTrainsStatusesDepartureTableWidget,
            minHeight: 170,
        },
        permissions: DefaultPermissions,
    },
];
