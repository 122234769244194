import { jsx as _jsx } from "react/jsx-runtime";
import { getDateToReadableText } from "@utils/DateUtils";
import { WidgetCard } from "@components/WidgetCard/WidgetCard";
import useDeIceData from "../hooks/useDeIceData";
export const DeIceMedianTimePerAircraft = ({ id }) => {
    const { data: deIceData } = useDeIceData();
    const deIceMedianTimePerAircraft = deIceData && deIceData.platforms[id].deIceMedianTimePerAircraft;
    const title = "ICW Median per fly (sek)";
    return (_jsx(WidgetCard, { title: title, description: getDateToReadableText(), error: !deIceMedianTimePerAircraft, children: deIceMedianTimePerAircraft }));
};
DeIceMedianTimePerAircraft.displayName = "DeIceMedianTimePerAircraft";
