import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
var FlightMovementsDirection;
(function (FlightMovementsDirection) {
    FlightMovementsDirection["All"] = "All";
    FlightMovementsDirection["Arrival"] = "Arrival";
    FlightMovementsDirection["Departure"] = "Departure";
})(FlightMovementsDirection || (FlightMovementsDirection = {}));
const defaultFilter = {
    direction: FlightMovementsDirection.All,
    directions: [FlightMovementsDirection.All, FlightMovementsDirection.Arrival, FlightMovementsDirection.Departure],
};
const FlightMovementsDirectionFilterContext = createContext(undefined);
export const FlightMovementsDirectionFilterProvider = ({ children }) => {
    const [filter, setFilter] = useState(defaultFilter);
    return (_jsx(FlightMovementsDirectionFilterContext.Provider, { value: { filter, setFilter }, children: children }));
};
export const useFlightMovementsDirectionFilter = () => {
    const context = useContext(FlightMovementsDirectionFilterContext);
    if (!context) {
        throw new Error("useFlightMovementsDirectionFilter must be used within a FlightMovementsDirectionFilterProvider");
    }
    return context;
};
