import { enUS, nb } from "date-fns/locale";
const locales = {
    nb: nb,
    en: enUS,
};
/**
 * Retrieves the corresponding `Locale` based on the provided locale string.
 *
 * @param {string} locale - The locale string, e.g., 'nb' or 'en'.
 * @returns {Locale} - The matching Locale object or a default (enUS) if no match is found.
 *
 * @example
 * getLocale('nb');  // Returns the Norwegian Locale object.
 * getLocale('fr');  // Returns the default (enUS) Locale object since 'fr' isn't in our lookup.
 */
export const getLocale = (locale) => {
    return locales[locale] || enUS;
};
