import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./Tooltip.scss";
import clsx from "clsx";
import { AvinorColor } from "@constants";
const styleSheet = {
    tooltip: clsx("tooltip"),
    tooltipContainer: (lineSeparator) => clsx("tooltip__container", {
        "tooltip__container--line-separator": lineSeparator,
    }),
    tooltipTitle: clsx("tooltip__title"),
    tooltipSubTitle: clsx("tooltip__sub-title"),
    tooltipList: (wide) => clsx("tooltip__list", {
        "tooltip__list--wide": wide,
    }),
    tooltipListItem: (wide, total) => clsx("tooltip__list-item", {
        "tooltip__list-item--wide": wide,
        "tooltip__list-item--total": total,
    }),
    tooltipListItemBody: clsx("tooltip__list-item__body"),
    tooltipListItemStyle: clsx("tooltip__list-item__style"),
    tooltipListItemContent: clsx("tooltip__list-item__content"),
    tooltipListItemStatus: clsx("tooltip__list-item__status"),
    tooltipFooter: clsx("tooltip__footer"),
};
const ToolTip = ({ children, style, }) => {
    return (_jsx("div", { className: styleSheet.tooltip, style: style, children: children }));
};
const Title = ({ children }) => _jsx("h3", { className: styleSheet.tooltipTitle, children: children });
const SubTitle = ({ children }) => (_jsx("p", { className: styleSheet.tooltipSubTitle, children: children }));
const List = ({ wide, children }) => (_jsx("ul", { className: styleSheet.tooltipList(wide), children: children }));
const Footer = ({ children }) => (_jsx("div", { className: styleSheet.tooltipFooter, children: children }));
const Container = ({ children, lineSeparator = true }) => (_jsx("div", { className: styleSheet.tooltipContainer(lineSeparator), children: children }));
const ListItem = ({ wide, total, style = { type: "none" }, content, status, horizontalLine: { top, bottom } = {}, }) => {
    const mergedContent = Object.assign({ color: AvinorColor.Grey200 }, content);
    const mergedStatus = Object.assign({ color: AvinorColor.White }, status);
    return (_jsxs(_Fragment, { children: [top && _jsx("hr", {}), _jsxs("li", { className: styleSheet.tooltipListItem(wide, total), children: [(style === null || style === void 0 ? void 0 : style.type) === "bullet" && (_jsx("span", { className: styleSheet.tooltipListItemStyle, style: {
                            backgroundColor: style.bgColor,
                        } })), _jsxs("span", { className: styleSheet.tooltipListItemBody, children: [mergedContent.value && (_jsx("span", { className: styleSheet.tooltipListItemContent, style: {
                                    color: mergedContent.color,
                                }, children: mergedContent.value })), mergedStatus.value && (_jsx("span", { className: styleSheet.tooltipListItemStatus, style: {
                                    color: mergedStatus.color,
                                }, children: mergedStatus.value }))] })] }), bottom && _jsx("hr", {})] }));
};
ToolTip.Title = Title;
ToolTip.SubTitle = SubTitle;
ToolTip.List = List;
ToolTip.ListItem = ListItem;
ToolTip.Footer = Footer;
ToolTip.Container = Container;
export default ToolTip;
