import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import SignInCard from "@auth/components/SignInCard/SignInCard";
const SignInPage = () => {
    const { inProgress } = useMsal();
    return _jsx(_Fragment, { children: inProgress == InteractionStatus.Logout ? _jsx(BlocksSpinner, {}) : _jsx(SignInCard, {}) });
};
const BlocksSpinner = () => {
    const spinnerStyles = {
        animation: "spinner_fUkk 2.4s linear infinite",
        fill: "#b5208f",
    };
    const containerStyles = {
        position: "absolute",
        inset: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    };
    const keyframesStyle = `
      @keyframes spinner_fUkk {
        8.33% { x: 54px; y: 4px; }
        25% { x: 54px; y: 4px; }
        33.3% { x: 54px; y: 54px; }
        50% { x: 54px; y: 54px; }
        58.33% { x: 4px; y: 54px; }
        75% { x: 4px; y: 54px; }
        83.33% { x: 4px; y: 4px; }
      }
    `;
    return (_jsxs("div", { style: containerStyles, children: [_jsx("style", { children: keyframesStyle }), _jsxs("svg", { width: "100", height: "100", viewBox: "0 0 100 100", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { style: Object.assign(Object.assign({}, spinnerStyles), { animationDelay: "-2.4s" }), x: "4", y: "4", rx: "4", width: "42", height: "42" }), _jsx("rect", { style: Object.assign(Object.assign({}, spinnerStyles), { animationDelay: "-1.6s" }), x: "4", y: "4", rx: "4", width: "42", height: "42" }), _jsx("rect", { style: Object.assign(Object.assign({}, spinnerStyles), { animationDelay: "-.8s" }), x: "4", y: "4", rx: "4", width: "42", height: "42" })] })] }));
};
export default SignInPage;
