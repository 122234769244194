import { BaggageEnrouteNonSchengenWidget, BaggageEnrouteSchengenWidget, BaggageOnBeltPerformanceAverageTableWidget, } from "@features/Baggage/widgets";
import { FlightMovementsEnrouteWidget } from "@features/FlightMovements/widgets";
import { PaxEnrouteWidget } from "@features/Pax/widgets/PaxEnrouteWidget";
import { DefaultPermissions } from "@widgets/registry";
export const baggageWidgets = [
    {
        name: PaxEnrouteWidget.displayName,
        gridRow: [2, 2, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 6, 6],
        component: PaxEnrouteWidget.displayName,
        componentProps: {
            Component: PaxEnrouteWidget,
            title: "pax.widgets.enroute.title",
            subTitle: "timePeriod.nextHour",
            minHeight: 240,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FlightMovementsEnrouteWidget.displayName,
        gridRow: [3, 2, 1],
        gridColumn: [1, 7, 7],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 6, 6],
        component: FlightMovementsEnrouteWidget.displayName,
        componentProps: {
            title: "Antall flighter på vei til OSL",
            subTitle: "timePeriod.nextHour",
            minHeight: 240,
        },
        permissions: DefaultPermissions,
    },
    {
        name: BaggageEnrouteNonSchengenWidget.displayName,
        gridRow: [4, 3, 2],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 6, 6],
        component: BaggageEnrouteNonSchengenWidget.displayName,
        componentProps: {
            title: "baggage.widgets.enrouteNonSchengen.title",
            subTitle: "timePeriod.nextHour",
            minHeight: 256,
        },
        permissions: DefaultPermissions,
    },
    {
        name: BaggageEnrouteSchengenWidget.displayName,
        gridRow: [5, 3, 2],
        gridColumn: [1, 7, 7],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 6, 6],
        component: BaggageEnrouteSchengenWidget.displayName,
        componentProps: {
            title: "baggage.widgets.enrouteSchengen.title",
            subTitle: "timePeriod.nextHour",
            footerText: "baggage.widgets.enrouteSchengen.description",
            minHeight: 256,
        },
        permissions: DefaultPermissions,
    },
    {
        name: BaggageOnBeltPerformanceAverageTableWidget.displayName,
        gridRow: [3, 3, 3],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 12],
        component: BaggageOnBeltPerformanceAverageTableWidget.displayName,
        componentProps: {
            title: "table.baggage.onBeltPerformance.title",
            subTitle: "table.baggage.onBeltPerformance.subTitle",
            minHeight: 294,
        },
        permissions: BaggageOnBeltPerformanceAverageTableWidget.permissions,
    },
];
