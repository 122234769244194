import { WideroeIcon, NorwegianIcon, NorseIcon, SasIcon, AirlineIcon } from "@avinor/react-icons";
export const AIRLINE_IATA_MAPPER = {
    DY: NorwegianIcon,
    N0: NorseIcon,
    SK: SasIcon,
    WF: WideroeIcon,
    XX: AirlineIcon,
};
export const mapAirlineIataToIcon = (iata) => {
    return AIRLINE_IATA_MAPPER[iata] || AIRLINE_IATA_MAPPER["XX"];
};
