import { jsx as _jsx } from "react/jsx-runtime";
import { getDateToReadableText } from "@utils/DateUtils";
import { WidgetCard } from "@components/WidgetCard/WidgetCard";
import useDeIceData from "../hooks/useDeIceData";
export const DeIceAverageQueTime = ({ id }) => {
    const { data: deIceData } = useDeIceData();
    const deIceAverageQueTime = deIceData && deIceData.platforms[id].deIceAverageQueueTime;
    const title = "Køtid plattform (gjennomsnitt)";
    return (_jsx(WidgetCard, { title: title, description: getDateToReadableText(), error: !deIceAverageQueTime, children: deIceAverageQueTime }));
};
DeIceAverageQueTime.displayName = "DeIceAverageQueTime";
