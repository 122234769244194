import { jsx as _jsx } from "react/jsx-runtime";
import { Cell } from "@table-library/react-table-library";
import { endOfDay, startOfDay, subHours } from "date-fns";
import { formatDate } from "@utils/DateUtils";
import Table from "@components/Table/Table";
import { addIdToObj } from "@components/Table/utils";
import { FormatPattern } from "@enums";
import { useStartupDelay } from "@features/AirsidePerformance/hooks/useStartupDelay";
import useRegularityAndPunctualityPeriodData, { useDeparturePunctuality, } from "@features/OnTimePerformance/hooks/useRegularityAndPunctualityPeriodData";
import { useOutboundTaxitime } from "@features/OnTimePerformance/hooks/useTaxiTime";
import "./OnTimePerformanceServiceQualityTableStyles.scss";
import { OnTimePerformanceTimeframeText, PerformanceMetricCell, evaluateAlertIndicatorLevel, evaluateAlertIndicatorLevelOutboundTaxiTime, evaluateAlertIndicatorLevelStartupDelay, headerGroups, } from "./OnTimePerformanceServiceQualityTableUtils";
import OTPThresholdsTooltip, { ThresholdValues } from "./OnTimePerformanceServiceQualityThresholdTooltip";
const cellRenderers = {
    totalPunctuality: ({ cellIndex, value, gridColumnStart, gridColumnEnd }) => {
        return (_jsx(Cell, { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, className: "OTP-table-performance-cell", children: _jsx(OTPThresholdsTooltip, { value: Math.floor(value), valueSuffix: "%", thresholdValues: ThresholdValues.Percentage, id: "departingPunctuality", evaluatingFunction: evaluateAlertIndicatorLevel }) }, cellIndex));
    },
    taxiTime: ({ cellIndex, value, gridColumnStart, gridColumnEnd }) => {
        return (_jsx(Cell, { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, className: "OTP-table-performance-cell", children: _jsx(OTPThresholdsTooltip, { value: value, valueSuffix: "min", thresholdValues: ThresholdValues.OutboundTaxiTime, id: "outboundtaxitime", evaluatingFunction: evaluateAlertIndicatorLevelOutboundTaxiTime }) }, cellIndex));
    },
    startupDelay: ({ cellIndex, value, gridColumnStart, gridColumnEnd }) => {
        return (_jsx(Cell, { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, className: "OTP-table-performance-cell", children: _jsx(OTPThresholdsTooltip, { value: Math.round(value), valueSuffix: "min", thresholdValues: ThresholdValues.StartupDelay, id: "startupdelay", evaluatingFunction: evaluateAlertIndicatorLevelStartupDelay }) }, cellIndex));
    },
    performanceMetrics: PerformanceMetricCell,
};
export const OnTimePerformanceServiceQualityDepartureTable = () => {
    const { data: taxiTime } = useOutboundTaxitime();
    const { data: punctualityLastHour } = useDeparturePunctuality();
    const { startupDelay } = useStartupDelay({
        from: formatDate(false, subHours(new Date(), 1), FormatPattern.yyyy_MM_dd_HH_mm),
        to: formatDate(false, new Date(), FormatPattern.yyyy_MM_dd_HH_mm),
    });
    const { data: punctualityAverage } = useRegularityAndPunctualityPeriodData({
        from: formatDate(false, startOfDay(new Date()), FormatPattern.yyyy_MM_dd_HH_mm),
        to: formatDate(false, endOfDay(new Date()), FormatPattern.yyyy_MM_dd_HH_mm),
        direction: "D",
    });
    const nodes = [
        {
            performanceMetrics: {
                content: (_jsx(OnTimePerformanceTimeframeText, { text: "Departure Punctuality OTP", timeFrame: "today" })),
                tooltip: {
                    id: "departurepunctualityotptoday",
                    title: "Departure Punctuality OTP",
                    description: "Schedule Off-Block (SOBT) vs. Actual Off-Block (AOBT) is more than 15:59 min. The KPI is punctual flights divided by the total number of flights today.",
                },
            },
            totalPunctuality: (punctualityAverage === null || punctualityAverage === void 0 ? void 0 : punctualityAverage.totalPunctuality) || 0,
        },
        {
            performanceMetrics: {
                content: (_jsx(OnTimePerformanceTimeframeText, { text: "Departure Punctuality OTP", timeFrame: "avg. last hour" })),
                tooltip: {
                    id: "departurepunctualityotplasthour",
                    title: "Departure Punctuality OTP",
                    description: "Schedule Off-Block (SOBT) vs. Actual Off-Block (AOBT) is more than 15:59 min. The KPI is punctual flights divided by the total number of flights last hour.",
                },
            },
            totalPunctuality: (punctualityLastHour === null || punctualityLastHour === void 0 ? void 0 : punctualityLastHour.totalPunctuality) || 0,
        },
        {
            performanceMetrics: {
                content: (_jsx(OnTimePerformanceTimeframeText, { text: "Pushback Startup Delay", timeFrame: "avg. last hour" })),
                tooltip: {
                    id: "pushbackstartupdelay",
                    title: "Pushback Startup Delay",
                    description: "Difference Target Startup Aproval (TSAT) and Actual of Block (AOBT) last hour average.",
                },
            },
            startupDelay: (startupDelay === null || startupDelay === void 0 ? void 0 : startupDelay.totalStartupDelay) || 0,
        },
        {
            performanceMetrics: {
                content: (_jsx(OnTimePerformanceTimeframeText, { text: "Outbound Taxi Time", timeFrame: "avg. last hour" })),
                tooltip: {
                    id: "outboundtaxitime",
                    title: "Outbound Taxi Time",
                    description: "The average from Stand (AOBT) til Departure (ATOT) last hour.",
                },
            },
            taxiTime: (taxiTime === null || taxiTime === void 0 ? void 0 : taxiTime.taxiTimeAvg) || 0,
        },
    ];
    return (_jsx(Table, { data: addIdToObj(nodes), headerGroups: headerGroups(), headerVisibility: "hidden", cellRenderers: cellRenderers, customTheme: {
            Cell: `
                    text-align: start;

                    & > div {
                        margin-left: 0.5rem;
                    }
                `,
        } }));
};
