import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
const IataContext = createContext(undefined);
export const IataProvider = ({ children }) => {
    const [iata, setIata] = useState("OSL");
    return _jsx(IataContext.Provider, { value: { iata, setIata }, children: children });
};
export const useIataContext = () => {
    const context = useContext(IataContext);
    if (!context) {
        throw new Error("useIataContext must be used within a IataProvider");
    }
    return context;
};
