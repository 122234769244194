import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./AirportStatusPage.scss";
import useCurrentDateTime from "@hooks/useCurrentDateTime";
import { t } from "i18next";
import { Grid, HStack, VStack } from "@components/Layout";
import { WidgetLayout } from "@components/WidgetLayout/WidgetLayout";
import { mapAirportIataToName } from "@mappings";
import { FlightMovementsDirectionFilterProvider } from "@features/FlightMovements/contexts/FlightMovementsDirectionFilterContext";
import { layout } from "@widgets/registry";
import { useIataContext } from "@contexts/IataContext";
import { airportTransportationWidgets, baggageWidgets, delaysWidgets, facilityWidgets, flightMovementsWidgets, operationalMessagesWidgets, paxWidgets, } from "./Widgets";
const COLUMNS = [
    [
        { title: t("pages.airportStatus.category.flightMovements"), widgets: flightMovementsWidgets },
        { title: t("pages.airportStatus.category.delays"), widgets: delaysWidgets },
    ],
    [
        { title: t("pages.airportStatus.category.baggage"), widgets: baggageWidgets },
        { title: t("pages.airportStatus.category.passengers"), widgets: paxWidgets },
        { title: t("pages.airportStatus.category.facility"), widgets: facilityWidgets },
    ],
    [
        { title: t("pages.airportStatus.category.operationalMessages"), widgets: operationalMessagesWidgets },
        { title: t("pages.airportStatus.category.airportTransportation"), widgets: airportTransportationWidgets },
    ],
];
const AvinorLogo = () => (_jsx("svg", { className: "airport-status__logo", xmlns: "http://www.w3.org/2000/svg", width: "119", height: "34", viewBox: "-0.281 2.74 119 34", "aria-label": "Avinor logo", children: _jsx("g", { children: _jsx("path", { fill: "var(--avinor-grey-900)", d: "M13.901 3.886a3.409 3.409 0 0 0-4.34 2.097 3.406 3.406 0 0 0 1.817 4.226l-6.85 19.077a3.407 3.407 0 0 0-2.013 6.508 3.409 3.409 0 0 0 2.53-6.321L11.89 10.39a3.408 3.408 0 0 0 2.011-6.505m11.124 20.065l1.346-4.008c.825-2.457 1.117-3.495 1.117-3.495h.117s.42 1.305 1.185 3.416l1.435 4.087h-5.2zm.876-12.285L18.724 31.66h3.721l1.601-4.745h7.213l1.699 4.745h3.78L29.1 11.666h-3.199m17.584 15.025h-.107s-.622-1.91-1.264-3.768l-3.965-11.257h-3.863l7.51 19.994h3.271l7.228-19.994h-3.866l-3.783 11.259c-.614 1.768-1.161 3.766-1.161 3.766m11.457 4.969h3.595V11.666h-3.595V31.66zm20.535-6.664l-.067.017-9.43-13.347h-2.658V31.66h3.502v-5.83c0-3.157-.127-7.499-.127-7.499l.077-.013 9.405 13.342h2.657V11.666h-3.501v6.378c-.001 2.394.142 6.952.142 6.952m38.487-6.986c0 1.781-1.179 2.934-3.005 2.934h-2.724v-5.868h2.724c1.826 0 3.005 1.153 3.005 2.934m.381 5.644c1.919-1.094 3.265-2.693 3.265-5.644 0-3.676-2.739-6.345-6.51-6.345h-6.461v19.993h3.596v-7.484h2.805l3.612 7.484h3.923l-4.23-8.004m-22.609-8.725c-3.781 0-6.497 2.857-6.497 6.734 0 3.928 2.775 6.732 6.5 6.732 3.805 0 6.495-2.886 6.495-6.732 0-3.972-2.823-6.734-6.498-6.734m10.226 6.735c0 5.649-4.58 10.229-10.23 10.229s-10.23-4.58-10.23-10.23 4.58-10.227 10.23-10.227 10.23 4.579 10.23 10.228" }) }) }));
const AirportStatusCategory = ({ title, widgets }) => {
    return (_jsxs("div", { className: "airport-status__category", children: [_jsx("h3", { className: "airport-status__category-title", children: title }), _jsx(WidgetLayout, { layout: Object.assign(Object.assign({}, layout), { margin: "0" }), widgets: widgets })] }));
};
const CurrentTime = () => {
    const { currentDate, currentTime } = useCurrentDateTime();
    return (_jsxs(VStack, { as: "section", className: "airport-status__time-section", children: [_jsx("time", { className: "airport-status__time", children: currentTime }), _jsxs("time", { className: "airport-status__date", children: [currentDate, " CET"] })] }));
};
const AirportStatusPage = () => {
    const { iata } = useIataContext();
    const airportName = mapAirportIataToName(iata);
    return (_jsxs("div", { className: "airport-status", children: [_jsxs(HStack, { as: "header", justify: "space-between", className: "airport-status__header", children: [_jsxs("section", { className: "airport-status__logo-section", children: [_jsxs(HStack, { align: "center", className: "airport-status__logo-wrapper", children: [_jsx(AvinorLogo, {}), _jsx("h1", { className: "airport-status__title", children: "Airport Operations Plan (AOP)" })] }), _jsx("p", { className: "airport-status__sub-title", children: airportName })] }), _jsx(CurrentTime, {})] }), _jsx(Grid, { as: "section", className: "airport-status__widgets", columns: "2fr 1fr 1fr", gap: 5, children: _jsx(FlightMovementsDirectionFilterProvider, { children: COLUMNS.map((column, index) => (_jsx("div", { className: "airport-status__column", children: column.map(({ title, widgets }) => (_jsx(AirportStatusCategory, { title: title, widgets: widgets }, title))) }, index))) }) })] }));
};
export default AirportStatusPage;
