import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ProgressCard } from "@components/ProgressCard/ProgressCard";
import { useIataContext } from "@contexts/IataContext";
import { getBaggageEnrouteStatistics } from "../services";
export const BaggageEnrouteNonSchengen = () => {
    const { iata } = useIataContext();
    const { data: bagsData } = useQuery({
        queryFn: () => getBaggageEnrouteStatistics({
            iata,
            hoursTo: 1,
        }),
        queryKey: ["getBaggageEnrouteStatistics", { iata }],
    });
    const { t } = useTranslation();
    return (_jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", value: (bagsData === null || bagsData === void 0 ? void 0 : bagsData.enrouteBagsNonSchengen) || 0, description: t("baggage.widgets.enrouteNonSchengen.description") }));
};
BaggageEnrouteNonSchengen.displayName = "BaggageEnrouteNonSchengen";
