export var ERoute;
(function (ERoute) {
    ERoute["Overview"] = "/";
    ERoute["Performance"] = "/performance";
    ERoute["Airside"] = "/airside";
    ERoute["Terminal"] = "/terminal";
    ERoute["DeIce"] = "/deice";
    ERoute["Plan"] = "/plan";
})(ERoute || (ERoute = {}));
export var ESubRoute;
(function (ESubRoute) {
    ESubRoute["Punctuality"] = "/punctuality";
    ESubRoute["Delays"] = "/delays";
    ESubRoute["AirportTransportation"] = "/airport-transportation";
    ESubRoute["OperationalMessages"] = "/operational-messages";
    ESubRoute["FlightMovements"] = "/flight-movements";
    ESubRoute["Passengers"] = "/passengers";
    ESubRoute["Baggage"] = "/baggage";
    ESubRoute["Facility"] = "/facility";
    ESubRoute["WinterOps"] = "/winter-ops";
    ESubRoute["Processes"] = "/processes";
    ESubRoute["PlanCreate"] = "/plan-create";
    ESubRoute["PlanOverview"] = "/plan-overview";
})(ESubRoute || (ESubRoute = {}));
