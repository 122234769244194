import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
const DelayCodeMetaDataContext = createContext(undefined);
const generateKey = (date) => date.toISOString();
export const DelayCodeMetaDataProvider = ({ children }) => {
    const [delayCodeMetaDataState, setDelayCodeMetaDataState] = useState(new Map());
    return (_jsx(DelayCodeMetaDataContext.Provider, { value: { delayCodeMetaDataState, setDelayCodeMetaDataState }, children: children }));
};
export const useDelayCodeMetaDataContext = (date) => {
    const context = useContext(DelayCodeMetaDataContext);
    if (!context) {
        throw new Error("useDelayCodeMetaDataContext must be used within a DelayCodeMetaDataProvider");
    }
    const { delayCodeMetaDataState, setDelayCodeMetaDataState } = context;
    const setDelayCodeMetaData = (data) => {
        setDelayCodeMetaDataState((prev) => {
            const newState = new Map(prev);
            newState.set(generateKey(date), data);
            return newState;
        });
    };
    const delayCodeMetaData = delayCodeMetaDataState.get(generateKey(date)) || null;
    return { delayCodeMetaData, setDelayCodeMetaData };
};
