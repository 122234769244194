import { useQuery } from "@tanstack/react-query";
import isFuture from "date-fns/isFuture";
import { formatDate } from "@utils/DateUtils";
import { Delay, FormatPattern } from "@enums";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useIataContext } from "@contexts/IataContext";
import { useUtcContext } from "@contexts/UtcContext";
import { getDelayNumbers } from "../services";
const useDelayNumbersData = () => {
    const { picker: { from }, } = useDatePickerContext();
    const { iata } = useIataContext();
    const { utc } = useUtcContext();
    // Prevent executing a request when navigating to forecast tab
    if (isFuture(from))
        return { data: null, error: null, from, iata, utc, isLoading: false };
    const { data, error, isLoading } = useQuery({
        queryFn: () => getDelayNumbers({
            iata,
            date: formatDate(utc, from, FormatPattern.yyyy_MM_dd),
        }),
        queryKey: ["getDelayNumbers", { utc, from, iata }],
        refetchInterval: Delay.OneMinute,
    });
    return { data, error, from, iata, utc, isLoading };
};
export default useDelayNumbersData;
