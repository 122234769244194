import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import ButtonGroup from "@components/Buttons/ButtonGroup/ButtonGroup";
export var PaxPredictionDirection;
(function (PaxPredictionDirection) {
    PaxPredictionDirection["Arrival"] = "ARRIVAL";
    PaxPredictionDirection["Departure"] = "DEPARTURE";
    PaxPredictionDirection["DepartureTransfer"] = "DEPARTURETRANSFER";
    PaxPredictionDirection["Security"] = "SECURITY";
})(PaxPredictionDirection || (PaxPredictionDirection = {}));
const directions = [
    PaxPredictionDirection.Arrival,
    PaxPredictionDirection.Departure,
    PaxPredictionDirection.DepartureTransfer,
];
const PaxPredictionDirectionFilter = ({ filter, setFilter }) => {
    var _a, _b;
    const { t } = useTranslation();
    const buttons = directions.map((direction, index) => ({
        id: index,
        text: t(`common.${direction.toLowerCase()}`),
        active: filter == direction,
        onClick: () => setFilter(direction),
    }));
    return (_jsx(ButtonGroup, { active: (_b = (_a = buttons.find((x) => x.active)) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0, buttons: buttons }));
};
export default PaxPredictionDirectionFilter;
