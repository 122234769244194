import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import { RegularityProgressBar } from "../components";
export const RegularityProgressBarWidget = () => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t("onTimePerformance.regularity.widgets.stats.title"), subtitleVisibility: "none" }), _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsx(Widget.Body, { children: _jsx(RegularityProgressBar, { direction: "D" }) }) })] }));
};
RegularityProgressBarWidget.displayName = "RegularityProgressBarWidget";
