import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import { PunctualityDayLineChart } from "@charts/apexcharts/OnTimePerformance";
export const PunctualityDayLineChartWidget = () => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t("onTimePerformance.punctuality.widgets.dayLineChart.title"), subtitle: t("onTimePerformance.punctuality.widgets.dayLineChart.subTitle") }), _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsx(Widget.Body, { children: _jsx(PunctualityDayLineChart, {}) }) })] }));
};
PunctualityDayLineChartWidget.displayName = "PunctualityDayLineChartWidget";
