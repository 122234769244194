import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./OperationalMessagesDisplay.scss";
import clsx from "clsx";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@components/Spinner/Spinner";
import OperationalMessagesTable from "../OperationalMessagesTable";
const styleSheet = {
    display: {
        root: clsx("operational-messages-display"),
        body: {
            root: clsx("operational-messages-display__body"),
            header: clsx("operational-messages-display__body-header"),
        },
        table: clsx("operational-messages-display__table"),
    },
};
const OperationalMessagesDisplay = () => {
    const { t } = useTranslation();
    return (_jsx("div", { className: styleSheet.display.root, children: _jsxs("div", { className: styleSheet.display.body.root, children: [_jsx("div", { className: styleSheet.display.body.header, children: _jsx("h3", { children: t("pages.operationalMessages.title") }) }), _jsx("div", { className: styleSheet.display.table, children: _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsx(OperationalMessagesTable, {}) }) })] }) }));
};
export default OperationalMessagesDisplay;
OperationalMessagesDisplay.displayName = "OperationalMessagesDisplay";
