export const sumAirlineData = (flights, isOtherAirlines) => {
    if (!flights)
        return {
            operatedFlights: 0,
            plannedFlights: 0,
            filteredFlights: [],
            punctualityFlights: 0,
            delayedFlights: 0,
        };
    const filteredFlights = flights.filter((flight) => isOtherAirlines ? flight.airlineIata === "rest" : flight.airlineIata !== "rest");
    const operatedFlights = filteredFlights.reduce((acc, flights) => acc + flights.operatedFlights, 0);
    const plannedFlights = filteredFlights.reduce((acc, flights) => acc + flights.flightsTimePeriod, 0);
    const punctualityFlights = filteredFlights.reduce((acc, flight) => acc + flight.airlinePunctuality, 0);
    const delayedFlights = filteredFlights.reduce((acc, flight) => acc + flight.delayedFlights, 0);
    return { operatedFlights, plannedFlights, filteredFlights, punctualityFlights, delayedFlights };
};
export const calculateAveragePunctuality = (flights, isOtherAirlines) => {
    const sum = sumAirlineData(flights, isOtherAirlines);
    if (!flights)
        return 0;
    const filteredFlights = sum.filteredFlights;
    const punctualityFlights = sum.punctualityFlights;
    const delayedFlights = sum.delayedFlights;
    const operatedFlights = sum.operatedFlights;
    if (punctualityFlights === 0 && delayedFlights === 0 && operatedFlights === 0)
        return 0;
    const avgPunctuality = isOtherAirlines
        ? punctualityFlights / filteredFlights.length
        : 100 - (delayedFlights / operatedFlights) * 100;
    return avgPunctuality;
};
export const punctualityTotalForAirlinesTitle = (isOtherAirlines) => {
    if (isOtherAirlines) {
        return "Øvrige flyselskaper";
    }
    else {
        return "DY, SK, N0 og WF";
    }
};
