import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Cell } from "@table-library/react-table-library";
import { SortToggleType, useSort } from "@table-library/react-table-library/sort";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ArrowDown, ArrowsUpDown, ArrowUp } from "@avinor/react-icons";
import ErrorMessageWidget from "@components/ErrorMessageWidget/ErrorMessageWidget";
import Table from "@components/Table/Table";
import { addIdToObj, renderCellWithBagFactorMarking } from "@components/Table/utils";
import "./BaggageDepartedTable.scss";
import { useFlightStageContext } from "@contexts/FlightStageContext";
import { useIataContext } from "@contexts/IataContext";
import { useUtcContext } from "@contexts/UtcContext";
const tableColumnHeaders = {
    flightID: "Flight",
    originIata: "Origin",
    etoa: "ETOA",
    stand: "Park",
    pax: "Pax",
    transferPax: "Transfer Pax",
    estimatedBagsTotal: "Total antall bag",
    estimatedBagsNonTransfer: "Total bag OSL",
    aircraftType: "Flytype",
    bagfactor: "Bagfaktor",
};
const headers = Object.keys(tableColumnHeaders);
const getTableColumnHeaders = () => {
    let formattedIndexStart = 0;
    return [
        {
            headers: headers.map((header) => {
                const spansTwoColumns = header === "estimatedBagsNonTransfer";
                formattedIndexStart += 1;
                const headerFormatted = {
                    key: header,
                    sortKey: header,
                    content: tableColumnHeaders[header],
                    cellProps: {
                        gridColumnStart: formattedIndexStart,
                        gridColumnEnd: formattedIndexStart + (spansTwoColumns ? 2 : 1),
                    },
                };
                // If the header spans two columns, we need to adjust the start index for the next header.
                if (spansTwoColumns) {
                    formattedIndexStart += 1;
                }
                return headerFormatted;
            }),
        },
    ];
};
const createSortFunctions = () => {
    const baseSortFunctions = {
        flightID: (array) => array.sort((a, b) => a.flightID.localeCompare(b.flightID)),
        originIata: (array) => array.sort((a, b) => a.originIata.localeCompare(b.originIata)),
        aircraftType: (array) => array.sort((a, b) => a.aircraftType.localeCompare(b.aircraftType)),
        etoa: (array) => array.sort((a, b) => a.etoa.localeCompare(b.etoa)),
        pax: (array) => array.sort((a, b) => a.pax - b.pax),
        transferPax: (array) => array.sort((a, b) => a.transferPax - b.transferPax),
        bagfactor: (array) => array.sort((a, b) => a.bagfactor - b.bagfactor),
        estimatedBagsTotal: (array) => array.sort((a, b) => a.estimatedBagsTotal - b.estimatedBagsTotal),
        estimatedBagsNonTransfer: (array) => array.sort((a, b) => a.estimatedBagsNonTransfer - b.estimatedBagsNonTransfer),
        stand: (array) => array.sort((a, b) => a.stand - b.stand),
    };
    return baseSortFunctions;
};
const filterColumnsByHeaders = (data) => {
    const preparedTableHeaders = data.map((item) => {
        const extractedProperties = {};
        headers.forEach((header) => {
            if (item[header] !== undefined) {
                extractedProperties[header] = item[header];
            }
        });
        return extractedProperties;
    });
    return preparedTableHeaders;
};
const evaluateAlertIndicatorLevel = (value) => {
    if (value !== 0) {
        if (value < 80)
            return "alarm";
        if (value <= 90)
            return "warning";
    }
    return "none";
};
export const BaggageDepartedTable = ({ displayHoursLimit }) => {
    /*TODO: This table should be updated with the correct departed data */
    const baggageEnroutePerFlightEstimate = [];
    const { stages: flightStage } = useFlightStageContext("baggageTable");
    const { iata } = useIataContext();
    const { utc } = useUtcContext();
    const { t } = useTranslation();
    const title = t("baggage.widgets.enroutePerFlightEstimateTable.title", { iata: iata });
    const subTitle = t("baggage.widgets.enroutePerFlightEstimateTable.description", { iata: iata });
    const filteredBaggageEnroutePerFlightEstimate = useMemo(() => {
        const selectedTravelTypeFilters = [
            ...(flightStage.includes("international") ? ["I"] : []),
            ...(flightStage.includes("domestic") ? ["D"] : []),
            ...(flightStage.includes("schengen") ? ["S"] : []),
        ];
        const baggageEnroutePerFlightEstimateFilteredByTravelType = (baggageEnroutePerFlightEstimate === null || baggageEnroutePerFlightEstimate === void 0 ? void 0 : baggageEnroutePerFlightEstimate.filter((flight) => selectedTravelTypeFilters.includes(flight.schIntDom))) ||
            [];
        const preparedTableColumns = filterColumnsByHeaders(baggageEnroutePerFlightEstimateFilteredByTravelType);
        return addIdToObj(preparedTableColumns);
    }, [flightStage, displayHoursLimit, utc, baggageEnroutePerFlightEstimate]);
    const sortOptions = useSort({ nodes: filteredBaggageEnroutePerFlightEstimate.nodes }, {}, {
        sortToggleType: SortToggleType.AlternateWithReset,
        sortIcon: {
            size: "24px",
            iconDefault: _jsx(ArrowsUpDown, {}),
            iconUp: _jsx(ArrowUp, {}),
            iconDown: _jsx(ArrowDown, {}),
        },
        sortFns: createSortFunctions(),
    });
    if (!baggageEnroutePerFlightEstimate || (baggageEnroutePerFlightEstimate === null || baggageEnroutePerFlightEstimate === void 0 ? void 0 : baggageEnroutePerFlightEstimate.length) === 0) {
        return (_jsx(ErrorMessageWidget, { title: title, description: subTitle, underline: "header" }));
    }
    const customCellRenderers = {
        bagFactor: ({ row, cellIndex, value }) => renderCellWithBagFactorMarking(row, cellIndex, value),
        estimatedBagsNonTransfer: ({ cellIndex, value, hide, row, gridColumnStart, gridColumnEnd }) => {
            return (_jsxs(Cell, { hide: hide, style: { display: "flex", alignItems: "center" }, className: "baggage-departed-loaded-bags-cell", gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, children: [_jsxs("div", { children: [value, "/", row.estimatedBagsTotal] }), _jsxs("div", { className: "baggage-loadedbags-percentage baggage-loadedbags-percentage-" +
                            evaluateAlertIndicatorLevel(row.estimatedBagsTotal ? Math.floor((value / row.estimatedBagsTotal) * 100) : 0), children: [row.estimatedBagsTotal ? Math.floor((value / row.estimatedBagsTotal) * 100) : "0", "%"] })] }, cellIndex));
        },
    };
    const keysToExclude = ["bagfactorDefaulted", "schIntDom", "gate"];
    const dateColumns = ["etoa"];
    return (_jsx(Table, { data: filteredBaggageEnroutePerFlightEstimate, sort: sortOptions, dateColumns: dateColumns, excludeKeys: keysToExclude, cellRenderers: customCellRenderers, headerGroups: getTableColumnHeaders(), enableHorizontalScroll: true }));
};
