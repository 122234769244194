import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import { BaggageEnrouteSchengen } from "../components";
export const BaggageEnrouteSchengenWidget = ({ title, subTitle, footerText, }) => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t(title), subtitle: t(subTitle) }), _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsx(Widget.Body, { children: _jsx(BaggageEnrouteSchengen, {}) }) }), _jsx(Widget.Footer, { children: t(footerText) })] }));
};
BaggageEnrouteSchengenWidget.displayName = "BaggageEnrouteSchengenWidget";
