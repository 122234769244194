import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "@digdir/designsystemet-react";
import { FloppydiskIcon } from "@navikt/aksel-icons";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Steps } from "rsuite";
import "./PlanCreateWidget.scss";
import { LeaveWithoutSavingModal } from "@components/Modals/LeaveWithoutSavingModal";
import { Delay, ERoute, ESubRoute } from "@enums";
import { OnSaveModalStateEnum, PlanOnSaveModal, } from "@features/Plan/components/PlanCreate/PlanOnSaveModal";
import { usePlanEditingContext } from "@features/Plan/contexts/PlanEditingContext";
import { usePlanProcessTypeContext } from "@features/Plan/contexts/PlanProcessTypeContext";
import { saveProcessStatuses } from "@features/Plan/services";
import { useIataContext } from "@contexts/IataContext";
import PlanCreateStep1Widget from "./PlanCreateStep1Widget";
import PlanCreateStep2Widget from "./PlanCreateStep2Widget";
export const PlanCreateWidget = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(0);
    const [showStep1ErrorMessage, setShowStep1ErrorMessage] = useState(false);
    const onHasChangesModalRef = useRef(null);
    const onSaveModalRef = useRef(null);
    const { planProcessType: selectedPlanProcessType } = usePlanProcessTypeContext();
    const [nextStep, setNextStep] = useState(null);
    const [onSaveModalState, setOnSaveModalState] = useState({ state: OnSaveModalStateEnum.SAVING });
    const { todayHasChanges, tomorrowHasChanges, modifiedStatusOfToday, modifiedStatusOfTomorrow, showTomorrow, resetStatus, areProcessStatusesValid, } = usePlanEditingContext();
    const [isSaving, setIsSaving] = useState(false);
    const { iata } = useIataContext();
    const handleConfirm = () => {
        if (nextStep !== null) {
            setCurrentStep(nextStep);
        }
        resetStatus();
    };
    const setStepper = (toStep) => {
        var _a;
        if (currentStep === 0 && toStep === 1 && !selectedPlanProcessType.id) {
            setShowStep1ErrorMessage(true);
            return;
        }
        if (currentStep === 1 && toStep === 0) {
            if (todayHasChanges || tomorrowHasChanges) {
                setNextStep(toStep);
                (_a = onHasChangesModalRef.current) === null || _a === void 0 ? void 0 : _a.showModal();
            }
            else {
                setCurrentStep(toStep);
                resetStatus();
            }
        }
        else {
            setCurrentStep(toStep);
        }
    };
    useEffect(() => {
        if (!selectedPlanProcessType.id) {
            setShowStep1ErrorMessage(false);
        }
    }, [selectedPlanProcessType]);
    const renderStepContent = () => {
        if (currentStep === 0) {
            return (_jsx(PlanCreateStep1Widget, { style: { marginBottom: "20vh" }, showError: showStep1ErrorMessage }));
        }
        return _jsx(PlanCreateStep2Widget, {});
    };
    const saveProcessStatusesMutation = useMutation({
        mutationFn: (processesToSave) => saveProcessStatuses({
            iata: iata,
            processesToSave: processesToSave.map((process) => ({
                id: process.id,
                disruptions: process.disruptions,
                processTypeId: process.processType.id,
                date: process.date,
                updatedAt: process.updatedAt,
                updatedBy: process.updatedBy,
            })),
        }),
        onSuccess: () => {
            setOnSaveModalState({ state: OnSaveModalStateEnum.SAVED });
            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                navigate(`${ERoute.Plan}${ESubRoute.PlanOverview}`);
                setIsSaving(false);
                resetStatus();
            }, Delay.SaveSuccessDelay);
        },
        onError: (error) => {
            var _a, _b, _c, _d;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const isUnauthorized = ((_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.request) === null || _b === void 0 ? void 0 : _b.status) == 401;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const isConflict = ((_d = (_c = error === null || error === void 0 ? void 0 : error.response) === null || _c === void 0 ? void 0 : _c.request) === null || _d === void 0 ? void 0 : _d.status) == 409;
            setOnSaveModalState({
                state: OnSaveModalStateEnum.ERROR,
                message: isUnauthorized
                    ? t("plan.create.widget.unauthorizedError")
                    : isConflict
                        ? t("plan.create.widget.conflictError")
                        : t("plan.create.widget.unexpectedError"),
            });
            console.error(error);
            setIsSaving(false);
        },
    });
    const onPlanSave = () => {
        setIsSaving(true);
        const plansToSave = [];
        if (modifiedStatusOfToday) {
            plansToSave.push(modifiedStatusOfToday);
        }
        if (showTomorrow && modifiedStatusOfTomorrow) {
            plansToSave.push(modifiedStatusOfTomorrow);
        }
        saveProcessStatusesMutation.mutate(plansToSave);
    };
    const showSaveModal = () => {
        var _a;
        let isValid = areProcessStatusesValid("today");
        if (tomorrowHasChanges && modifiedStatusOfTomorrow) {
            isValid = isValid && areProcessStatusesValid("tomorrow");
        }
        if (isValid) {
            setOnSaveModalState({
                state: OnSaveModalStateEnum.SAVING,
            });
        }
        else {
            setOnSaveModalState({
                state: OnSaveModalStateEnum.ERROR,
                message: t("plan.create.widget.validationError"),
            });
        }
        (_a = onSaveModalRef.current) === null || _a === void 0 ? void 0 : _a.showModal();
    };
    return (_jsxs("div", { style: { display: "flex", flexDirection: "column", gap: "40px" }, children: [_jsx("div", { style: { position: "relative", width: "100%" }, children: _jsx("div", { className: "plan-create-stepper", children: _jsxs(Steps, { current: currentStep, children: [_jsx(Steps.Item, { title: t("plan.create.widget.steps.background"), onClick: () => setStepper(0), style: { cursor: "pointer" } }), _jsx(Steps.Item, { title: t("plan.create.widget.steps.information") })] }) }) }), _jsx("div", { children: renderStepContent() }), _jsx(LeaveWithoutSavingModal, { onHasChangesModalRef: onHasChangesModalRef, onConfirm: handleConfirm }), _jsx(PlanOnSaveModal, { onSaveModalRef: onSaveModalRef, isSaving: isSaving, onSave: onPlanSave, modalState: onSaveModalState }), _jsx("div", { style: { display: "flex", justifyContent: "center", gap: "20px" }, children: currentStep === 0 ? (_jsx(Button, { color: "accent", size: "sm", variant: "primary", onClick: () => setStepper(1), children: t("plan.create.widget.nextButton") })) : (_jsxs(_Fragment, { children: [_jsx(Button, { color: "accent", size: "sm", variant: "secondary", onClick: () => setStepper(0), children: t("plan.create.widget.backButton") }), _jsxs(Button, { color: "accent", size: "sm", variant: "primary", onClick: () => showSaveModal(), children: [_jsx(FloppydiskIcon, { "aria-hidden": true, fontSize: "1.5rem" }), t("plan.create.widget.saveButton")] })] })) })] }));
};
PlanCreateWidget.displayName = "PlanCreateWidget";
