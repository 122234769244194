import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { TrainRightOutlined } from "@avinor/react-icons";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import { AirportTrainsStatusesTable } from "../components";
export const AirportTrainsStatusesTableWidget = ({ title, subTitle }) => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t(title), subtitle: t(subTitle), icon: {
                    component: (_jsx(TrainRightOutlined, { width: "1.5rem", height: "1.5rem" })),
                } }), _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsx(Widget.Body, { children: _jsx(AirportTrainsStatusesTable, {}) }) })] }));
};
AirportTrainsStatusesTableWidget.displayName = "AirportTrainsStatusesTableWidget";
