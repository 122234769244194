var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./Select.scss";
import clsx from "clsx";
import { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import { defaultStyles } from "./SelectDefaultStyles";
const Select = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { styles, label, fixedWidth, fullWidth, onChange, style, className, placeholder = t("common.selectPlaceholder") } = props, rest = __rest(props, ["styles", "label", "fixedWidth", "fullWidth", "onChange", "style", "className", "placeholder"]);
    const mergedStyles = useMemo(() => (Object.assign(Object.assign({}, defaultStyles), styles)), [styles]);
    const selectClasses = clsx("select", className, { "select--fixed-width": fixedWidth }, { "select--full-width": fullWidth });
    return (_jsxs("div", { className: selectClasses, style: style, children: [label && _jsx("label", { className: "select__label", children: label }), _jsx(ReactSelect, Object.assign({ ref: ref, styles: mergedStyles, theme: (theme) => (Object.assign(Object.assign({}, theme), { borderRadius: 0 })), onChange: onChange, placeholder: placeholder }, rest))] }));
});
Select.displayName = "Select";
export default Select;
