import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./FlightMovementsFilters.scss";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Button from "@components/Buttons/Button/Button";
import { Granularity } from "@enums";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useGranularityContext } from "@contexts/GranularityContext";
import FlightMovementsDirectionFilter from "../FlightMovementsDirectionFilter/FlightMovementsDirectionFilter";
import GranularityFilter from "../GranularityFilter/GranularityFilter";
const styleSheet = {
    root: "flight-movements-filters",
    container: "flight-movements-filters__container",
    backButton: (hidden) => clsx("flight-movements-filters__back-button", {
        "flight-movements-filters__back-button--hidden": hidden,
    }),
};
const updateGranularity = (prevState) => {
    const granularityLookup = {
        [Granularity.FiveMin]: Granularity.FifteenMin,
        [Granularity.FifteenMin]: Granularity.ThirtyMinutes,
        [Granularity.ThirtyMinutes]: Granularity.Hour,
        [Granularity.Hour]: Granularity.Day,
        [Granularity.Day]: Granularity.Day,
    };
    const nextGranularity = granularityLookup[prevState.granularity] || prevState.granularity;
    return Object.assign(Object.assign({}, prevState), { granularity: nextGranularity });
};
export const FlightMovementsFilters = () => {
    const { picker: { from, id }, } = useDatePickerContext();
    const { granularity, setGranularity } = useGranularityContext({ from, currentPage: id });
    const { t } = useTranslation();
    return (_jsxs("section", { className: styleSheet.root, children: [_jsxs("div", { className: styleSheet.container, children: [_jsx(GranularityFilter, {}), _jsx(FlightMovementsDirectionFilter, {})] }), _jsx(Button, { variant: "flat", className: styleSheet.backButton(granularity === Granularity.Day), "aria-hidden": granularity === Granularity.Day, onClick: () => {
                    setGranularity(updateGranularity({ granularity }));
                }, children: t("back") })] }));
};
