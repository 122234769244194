import { BrowserCacheLocation } from "@azure/msal-browser";
import config from "../../config";
export const msalConfig = {
    auth: {
        clientId: config.AZURE_AD_B2C.CLIENT_ID,
        authority: config.AZURE_AD_B2C.AUTHORITY,
        redirectUri: config.AZURE_AD_B2C.REDIRECT_URL,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
    },
};
