import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ProgressCard } from "@components/ProgressCard/ProgressCard";
import { getEnrouteFlightsData } from "@features/FlightMovements/services";
import { useIataContext } from "@contexts/IataContext";
export const BaggagePrintedTags = () => {
    /**TODO: Change the endpoint to the correct one */
    const { iata } = useIataContext();
    const { data: enrouteFlights } = useQuery({
        queryFn: () => getEnrouteFlightsData({ iata, hoursTo: 1 }),
        queryKey: ["getEnrouteFlightsData", { iata }],
    });
    const { t } = useTranslation();
    return (_jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", value: (enrouteFlights === null || enrouteFlights === void 0 ? void 0 : enrouteFlights.enrouteFlights) || 0, description: t("baggage.widgets.printedTags.description") }));
};
