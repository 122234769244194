import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import Widget from "@components/Widget/Widget";
import { DelaySummary } from "../components";
import { DelayTotalFlightDelays } from "../components/DelaySummary";
export const DelaySummaryWidget = () => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { style: { justifyContent: "space-between" }, children: [_jsx(Widget.Header, { title: t("onTimePerformance.delay.widgets.summary.title"), subtitle: t("onTimePerformance.delay.widgets.summary.subTitle") }), _jsx(Widget.NumericIndicator, { children: _jsx(DelayTotalFlightDelays, {}) }), _jsx(Widget.Body, { style: { height: "initial" }, children: _jsx(DelaySummary, {}) })] }));
};
DelaySummaryWidget.displayName = "DelaySummaryWidget";
