import { httpAirsidePerformanceService } from "@services/HttpService";
import { resolveUrl } from "@utils/UrlUtils";
import { getMSALToken } from "@auth/authUtils";
export async function getRunwayConfig({ iata, datetime }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("datetime", datetime);
    const token = await getMSALToken();
    const res = await httpAirsidePerformanceService.get(resolveUrl({
        url: `runway-config?${params}`,
        mockEndpoint: "/runway-config",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
}
export async function getStandOccupancy({ iata, datetime, }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("datetime", datetime);
    const token = await getMSALToken();
    const response = await httpAirsidePerformanceService.get(resolveUrl({
        url: `/stand-occupancy?${params}`,
        mockEndpoint: "/stand-occupancy",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function getStartupDelay({ iata, from, to, }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("from", from);
    params.set("to", to);
    const token = await getMSALToken();
    const response = await httpAirsidePerformanceService.get(resolveUrl({
        url: `/startup-delay?${params}`,
        mockEndpoint: "/startup-delay",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
