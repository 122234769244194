import { jsx as _jsx } from "react/jsx-runtime";
import { ProgressCard } from "@components/ProgressCard/ProgressCard";
import Spinner from "@components/Spinner/Spinner";
import useFacilityStatusOverviewData from "../hooks/useFacilityStatusOverviewData";
export const FacilityOutOfOrder = () => {
    const { data: facilityStatusOverview, isLoading } = useFacilityStatusOverviewData();
    const { OutOfOperation } = facilityStatusOverview || {
        OutOfOperation: "N/A",
    };
    if (isLoading)
        return _jsx(Spinner, {});
    return (_jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", value: OutOfOperation }));
};
