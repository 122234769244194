import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./PunctualityReport.scss";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { format, subDays } from "date-fns";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { formatDate, removeZuluSuffix } from "@utils/DateUtils";
import { StringUtils } from "@utils/StringUtils";
import { Grid, VStack } from "@components/Layout";
import Line from "@components/Layout/Line/Line";
import { ProgressCard } from "@components/ProgressCard/ProgressCard";
import { breakpoints } from "@constants";
import { FormatPattern } from "@enums";
import { mapAirlineIataToName } from "@mappings";
import { getHistoricSnapshotPunctualityData } from "@features/OnTimePerformance/services";
import { getLocale } from "@assets/i18n/utils";
import { useMediaQuery } from "@hooks";
import { useIataContext } from "@contexts/IataContext";
import { useUtcContext } from "@contexts/UtcContext";
const PunctualityReportColumn = ({ date, SK = undefined, DY = undefined, N0 = undefined, WF = undefined, total, remaining, }) => {
    const props = { total, SK, DY, N0, WF, remaining };
    const { t, i18n } = useTranslation();
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    return (_jsxs(VStack, { gap: isMobile ? 0.25 : 1, children: [_jsx("h3", { className: "punctuality-report__date", children: _jsx("span", { children: StringUtils.capitalizeFirstLetter(format(new Date(removeZuluSuffix(date)), FormatPattern.EEEE_DD_MM, {
                        locale: getLocale(i18n.language),
                    })) }) }), Object.entries(props).map(([key, punctualityValue]) => {
                return (_jsxs(Fragment, { children: [_jsx(ProgressCard, { variant: !punctualityValue ? "plain" : "percentage", progressVisibility: "hidden", title: mapAirlineIataToName(key, () => {
                                return t(key === "remaining" ? "others" : key);
                            }, {
                                targetAirline: "Norse Atlantic Airways",
                                modifyOutput: (name) => {
                                    return name.split(" ")[0];
                                },
                            }), value: !punctualityValue ? "-" : punctualityValue, valueSize: key === "total" ? "medium" : "small" }), key === "total" && _jsx(Line, {})] }, key));
            })] }));
};
const PunctualityReportLegend = () => {
    const percentage = { low: "< 80 %", medium: "80 - 90 %", high: "90 - 100 %" };
    return (_jsx("ul", { className: "punctuality-report__legend", children: Object.entries(percentage).map(([thresholdKey, thresholdValue]) => {
            return (_jsxs("li", { className: "punctuality-report__legend-item", children: [_jsx("span", { className: clsx("punctuality-report__legend-item-color", {
                            "punctuality-report__legend-item-color--low": thresholdKey === "low",
                            "punctuality-report__legend-item-color--medium": thresholdKey === "medium",
                            "punctuality-report__legend-item-color--high": thresholdKey === "high",
                        }) }), thresholdValue] }, thresholdKey));
        }) }));
};
export const PunctualityReport = () => {
    const { iata } = useIataContext();
    const { utc } = useUtcContext();
    const { data: weeklyOverviewPunctualityData } = useQuery({
        queryFn: () => getHistoricSnapshotPunctualityData({
            iata,
            fromDate: formatDate(utc, subDays(new Date(), 4), FormatPattern.yyyy_MM_dd),
            toDate: formatDate(utc, new Date(), FormatPattern.yyyy_MM_dd),
        }),
        queryKey: ["getHistoricSnapshotPunctualityData", { iata, utc }],
    });
    const dailyPunctualities = weeklyOverviewPunctualityData && weeklyOverviewPunctualityData.dailyPunctualities;
    if (!dailyPunctualities)
        return null;
    return (_jsxs("section", { children: [_jsx(Grid, { columns: `repeat(${dailyPunctualities.length}, minmax(7em, 1fr))`, className: "punctuality-report", gap: 1, children: dailyPunctualities.map((punctuality) => {
                    return (_jsx(PunctualityReportColumn, Object.assign({}, punctuality), punctuality.date));
                }) }), _jsx(PunctualityReportLegend, {})] }));
};
PunctualityReport.displayName = "PunctualityReport";
