import { AvinorColor } from "@constants";
// This is a temporary solution to make the client work with the inconsistent api.
// Ideally, the api should be consistent with the data it returns regarding keys like "total", "others" and so on.
// This is because the client is dependent on keys when mapping over colors, names and so on.
const SPECIAL_CASES_COLOR_MAPPER = {
    others: AvinorColor.Grey300,
    other: AvinorColor.Grey300,
    totalAverage: AvinorColor.Grey600,
};
const AIRLINE_COLOR_MAP = {
    DY: AvinorColor.ChartVioletLight400,
    N0: AvinorColor.Blue100,
    SK: AvinorColor.Blue500,
    WF: AvinorColor.ChartMintGreen300,
    DEFAULT: AvinorColor.Grey600,
};
const getColor = (airline) => {
    return AIRLINE_COLOR_MAP[airline];
};
const AIRLINE_COLOR_MAPPER = {
    DY: getColor("DY"),
    N0: getColor("N0"),
    SK: getColor("SK"),
    WF: getColor("WF"),
    DEFAULT: getColor("DEFAULT"),
};
const COMBINED_COLOR_MAPPER = Object.assign(Object.assign({}, AIRLINE_COLOR_MAPPER), SPECIAL_CASES_COLOR_MAPPER);
export const mapAirlineIataToColor = (key) => {
    return COMBINED_COLOR_MAPPER[key] || AIRLINE_COLOR_MAPPER.DEFAULT;
};
