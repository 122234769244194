import { mapAirlineIataToColor } from "@mappings/mapAirlineIataToColor";
import { mapAirlineIataToName } from "@mappings/mapAirlineIataToName";
import { t } from "i18next";
import { extractUniqueKeys } from "@utils/Utils";
const createChartLines = (baggageOnBeltPerformanceAverageData) => {
    const excludeKeys = new Set([
        "date",
        "totalFlights",
        "airlines",
    ]);
    const uniqueAirlineCodes = extractUniqueKeys(baggageOnBeltPerformanceAverageData, excludeKeys);
    return uniqueAirlineCodes.map((iata) => ({
        dataKey: iata,
        stroke: mapAirlineIataToColor(iata),
    }));
};
export const getLineAndScatterChartData = (baggageOnBeltPerformanceAverageData, transformedBaggageOnBeltPerformancePerFlight) => {
    var _a;
    const lineChartSeries = createChartLines(baggageOnBeltPerformanceAverageData).map(({ dataKey, stroke }) => ({
        name: `${mapAirlineIataToName(dataKey, () => t(dataKey))} (${t("common.average")})`,
        type: "line",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: baggageOnBeltPerformanceAverageData.map((baggage) => { var _a; return (_a = baggage[dataKey]) !== null && _a !== void 0 ? _a : 0; }),
        Iata: dataKey,
        stroke,
    }));
    const startDate = new Date((_a = baggageOnBeltPerformanceAverageData[0]) === null || _a === void 0 ? void 0 : _a.date).getTime();
    const oneHour = 3600 * 1000; // One hour in milliseconds
    const lineSeriesAdjusted = lineChartSeries.map((series) => (Object.assign(Object.assign({}, series), { data: series.data.map((value, index) => ({
            x: startDate + index * oneHour,
            y: value,
        })) })));
    const airlineNamesToShow = ["Widerøe", "SAS", "Norwegian"];
    const scatteredChartSeries = transformedBaggageOnBeltPerformancePerFlight.map(({ airlineName, flights, airlineIata }) => ({
        name: airlineName,
        type: "scatter",
        Iata: airlineIata,
        data: flights.map(({ aibt, bagPerformance, flightID }) => ({
            x: new Date(aibt).getTime(),
            y: bagPerformance,
            flightID: flightID,
        })),
        stroke: mapAirlineIataToColor(airlineIata),
    }));
    const scatteredChartSeriesGrouped = [];
    scatteredChartSeries.forEach((series) => {
        if (!airlineNamesToShow.some((x) => x.toLowerCase().trim() == series.name.toLowerCase().trim())) {
            const otherSeries = scatteredChartSeriesGrouped.find((x) => x.name == t("other"));
            if (otherSeries) {
                otherSeries.data = [...otherSeries.data, ...series.data];
            }
            else {
                scatteredChartSeriesGrouped.push(Object.assign(Object.assign({}, series), { name: t("other"), Iata: "others", stroke: mapAirlineIataToColor("others") }));
            }
        }
        else {
            const groupedSeries = scatteredChartSeriesGrouped.find((x) => x.name == series.name);
            if (!groupedSeries) {
                scatteredChartSeriesGrouped.push(series);
            }
            else {
                groupedSeries.data = [...groupedSeries.data, ...series.data];
            }
        }
    });
    return {
        lineSeries: lineSeriesAdjusted.sort((a, b) => a.name.localeCompare(b.name)),
        scatteredSeries: scatteredChartSeriesGrouped.sort((a, b) => a.name.localeCompare(b.name)),
    };
};
export const formatBaggageOnBeltAverageData = (baggageOnBeltPerformanceAverage) => {
    if (!baggageOnBeltPerformanceAverage || (baggageOnBeltPerformanceAverage === null || baggageOnBeltPerformanceAverage === void 0 ? void 0 : baggageOnBeltPerformanceAverage.timeInterval.length) === 0)
        return [];
    const formattedBaggageOnBeltPerformanceAverage = baggageOnBeltPerformanceAverage.timeInterval.map(({ airlineAverages, date, totalAverage, totalFlights }) => {
        const airlineIataData = airlineAverages === null || airlineAverages === void 0 ? void 0 : airlineAverages.reduce((acc, { airlineIata, airlineAverage }) => {
            acc[airlineIata] = airlineAverage;
            return acc;
        }, {});
        return Object.assign({ date,
            totalAverage,
            totalFlights, airlines: Object.assign({}, airlineIataData) }, airlineIataData);
    });
    return formattedBaggageOnBeltPerformanceAverage;
};
export const formatBaggageOnBeltPerFlightData = (data) => {
    const resultMap = {};
    for (const entry of data) {
        if (!resultMap[entry.airlineIata]) {
            resultMap[entry.airlineIata] = {
                flights: [],
                airlineName: entry.airlineName,
            };
        }
        resultMap[entry.airlineIata].flights.push(entry);
    }
    return Object.entries(resultMap).map(([airlineIata, { flights, airlineName }]) => ({
        airlineIata,
        airlineName: airlineName || "",
        flights,
    }));
};
