import { format } from "date-fns";
import { removeZuluSuffix } from "@utils/DateUtils";
import { FormatPattern, Granularity } from "@enums";
export const formatXAxisTick = (date, granularity, timePrefix, options = { withPrefix: true }) => {
    const getFormat = () => {
        if (granularity === Granularity.Day) {
            return FormatPattern.d_LLL;
        }
        return FormatPattern.HH_mm;
    };
    const getDate = () => {
        return new Date(removeZuluSuffix(date));
    };
    const getPrefix = () => {
        if (granularity !== Granularity.Day && options.withPrefix) {
            return timePrefix;
        }
        return "";
    };
    return `${getPrefix()} ${format(getDate(), getFormat())}`;
};
