import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch } from "@digdir/designsystemet-react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "@utils/DateUtils";
import { LeaveWithoutSavingModal } from "@components/Modals/LeaveWithoutSavingModal";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import "./PlanCreateStep2Widget.scss";
import { FormatPattern } from "@enums";
import { PlanCreateTable } from "@features/Plan/components/PlanCreate/PlanCreateTable";
import { usePlanEditingContext } from "@features/Plan/contexts/PlanEditingContext";
import { usePlanProcessTypeContext } from "@features/Plan/contexts/PlanProcessTypeContext";
import { useLeaveWithoutSavingPrompt } from "@features/Plan/hooks/useLeaveWithoutSavingPrompt";
import useProcessStatus from "@features/Plan/hooks/useProcessStatus";
import { useUtcContext } from "@contexts/UtcContext";
const PlanCreateStep2Widget = (props) => {
    const { t } = useTranslation();
    const { planProcessType: selectedPlanProcessType } = usePlanProcessTypeContext();
    const { setTodayHasChanges, setTomorrowHasChanges, tomorrowHasChanges, modifiedStatusOfToday, modifiedStatusOfTomorrow, showTomorrow, setModifiedStatusOfToday, setModifiedStatusOfTomorrow, setShowTomorrow, } = usePlanEditingContext();
    const { utc } = useUtcContext();
    const { modalRef, handleConfirm } = useLeaveWithoutSavingPrompt();
    const onTomorrowChangesModalRef = useRef(null);
    // Fetch processStatus for today
    const today = formatDate(utc, new Date(), FormatPattern.yyyy_MM_dd);
    const { processStatus: processStatusOfToday, isLoading: isLoadingToday, isFetching: isFetchingToday, } = useProcessStatus({
        date: today,
        processTypeId: selectedPlanProcessType.id,
    });
    // Fetch processStatus for tomorrow
    const tomorrowDate = new Date();
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    const tomorrow = formatDate(utc, tomorrowDate, FormatPattern.yyyy_MM_dd);
    const { processStatus: processStatusOfTomorrow, isLoading: isLoadingTomorrow, isFetching: isFetchingTomorrow, } = useProcessStatus({
        date: tomorrow,
        processTypeId: selectedPlanProcessType.id,
    });
    useEffect(() => {
        var _a, _b;
        // Set the processStatus of today to the modified processStatus if they are not already set
        if (processStatusOfToday && ((_a = processStatusOfToday.disruptions) === null || _a === void 0 ? void 0 : _a.length) > 0 && !modifiedStatusOfToday) {
            setModifiedStatusOfToday(processStatusOfToday);
        }
        // Set the processStatus of tomorrow to the modified processStatus if they are not already set
        if (processStatusOfTomorrow && ((_b = processStatusOfTomorrow.disruptions) === null || _b === void 0 ? void 0 : _b.length) > 0 && !modifiedStatusOfTomorrow) {
            setModifiedStatusOfTomorrow(processStatusOfTomorrow);
        }
    }, [processStatusOfToday, processStatusOfTomorrow]);
    const handleDisruptionsChangeToday = (disruptions) => {
        setModifiedStatusOfToday((prev) => (prev ? Object.assign(Object.assign({}, prev), { disruptions }) : null));
    };
    const handleDisruptionsChangeTomorrow = (disruptions) => {
        setModifiedStatusOfTomorrow((prev) => (prev ? Object.assign(Object.assign({}, prev), { disruptions }) : null));
    };
    const toggleShowTomorrow = () => {
        var _a;
        if (showTomorrow && tomorrowHasChanges) {
            (_a = onTomorrowChangesModalRef.current) === null || _a === void 0 ? void 0 : _a.showModal();
        }
        else {
            setShowTomorrow(!showTomorrow);
        }
    };
    const confirmDiscardChangesTomorrow = () => {
        var _a;
        if (processStatusOfTomorrow) {
            setModifiedStatusOfTomorrow(processStatusOfTomorrow);
            setShowTomorrow(false);
            (_a = onTomorrowChangesModalRef.current) === null || _a === void 0 ? void 0 : _a.close();
        }
    };
    return (_jsxs("div", { className: "plan-create-step2", style: props.style, children: [_jsxs("div", { style: { display: "flex", flexDirection: "column", gap: "15px" }, children: [_jsx(Widget, { children: _jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t("plan.create.fillInProcess.todayTitle", {
                                        company: selectedPlanProcessType.company,
                                        name: selectedPlanProcessType.name,
                                    }) }), _jsx(Widget.Body, { children: modifiedStatusOfToday ? (_jsx(PlanCreateTable, { currentPlan: modifiedStatusOfToday, handlePlanDisruptionsChange: handleDisruptionsChangeToday, isLoading: isLoadingToday || isFetchingToday, setPlanHasChanges: setTodayHasChanges })) : (_jsx(Spinner, {})) })] }) }), _jsx(Widget, { children: _jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t("plan.create.fillInProcess.tomorrowTitle", {
                                        company: selectedPlanProcessType.company,
                                        name: selectedPlanProcessType.name,
                                    }) }), _jsxs(Widget.Body, { children: [_jsx(Switch, { description: "", position: "left", size: "sm", checked: showTomorrow, onChange: () => toggleShowTomorrow(), children: t("plan.create.fillInProcess.switchLabel") }), showTomorrow && modifiedStatusOfTomorrow && (_jsx(PlanCreateTable, { currentPlan: modifiedStatusOfTomorrow, handlePlanDisruptionsChange: handleDisruptionsChangeTomorrow, isLoading: isLoadingTomorrow || isFetchingTomorrow, setPlanHasChanges: setTomorrowHasChanges }))] })] }) })] }), _jsx(LeaveWithoutSavingModal, { onHasChangesModalRef: modalRef, onConfirm: handleConfirm }), _jsx(LeaveWithoutSavingModal, { onHasChangesModalRef: onTomorrowChangesModalRef, onConfirm: confirmDiscardChangesTomorrow })] }));
};
export default PlanCreateStep2Widget;
