import { httpAirportTransportationService } from "@services/HttpService";
import { resolveUrl } from "@utils/UrlUtils";
import { getMSALToken } from "@auth/authUtils";
export async function getAirportTrainsStatuses({ iata, }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    const token = await getMSALToken();
    try {
        const response = await httpAirportTransportationService.get(resolveUrl({
            url: `/trains/statuses?${params}`,
            mockEndpoint: "/trainsStatuses",
        }), {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}
