import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Cell } from "@table-library/react-table-library";
import clsx from "clsx";
import { formatDate, roundDateToNearestMinute } from "@utils/DateUtils";
import { FormatPattern } from "@enums";
import { useUtcContext } from "@contexts/UtcContext";
import AlertIndicator from "../AlertIndicator/AlertIndicator";
const styleSheet = {
    airportTrainsDetailsTableTimeCell: (status) => clsx("trains-details__time-cell", {
        "trains-details__time-cell--delayed": status === "DELAYED",
        "trains-details__time-cell--cancelled": status === "CANCELLED",
    }),
};
const AirportTrainsDetailsTableTimeCell = ({ props: { cellIndex, gridColumnStart, gridColumnEnd, aimedTime, expectedTime, status }, }) => {
    const { utc } = useUtcContext();
    const isCancelled = status === "CANCELLED";
    return (_jsxs(Cell, { className: styleSheet.airportTrainsDetailsTableTimeCell(status), gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, children: [_jsx(AlertIndicator, { variant: status === "CANCELLED" ? "alarm" : "warning" }), _jsxs("span", { children: [aimedTime && _jsx("time", { children: formatDate(utc, roundDateToNearestMinute(aimedTime), FormatPattern.HH_mm) }), expectedTime && !isCancelled && (_jsx("time", { children: formatDate(utc, roundDateToNearestMinute(expectedTime), FormatPattern.HH_mm) }))] })] }, cellIndex));
};
export default AirportTrainsDetailsTableTimeCell;
