import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./DeIceTimeEstimator.scss";
import { useTranslation } from "react-i18next";
import { Snow, Lightsnow, Lightsleet, Fog, Heavysleet } from "@avinor/react-icons";
import { Grid, HStack, VStack } from "@components/Layout";
import { breakpoints } from "@constants";
import { useMediaQuery } from "@hooks";
import DeIceWeatherConditionFilter from "../DeIceWeatherConditionFilter";
const deiceTimeEstimators = [
    { code: "FZFG", description: "Freezing Fog", Icon: Fog, time: "05" },
    { code: "FZDZ", description: "Freezing Drizzle", Icon: Lightsleet, time: "10" },
    { code: "-SN", description: "Light Snow", Icon: Lightsnow, time: "10" },
    { code: "SN", description: "Snow", Icon: Snow, time: "15" },
    { code: "FZRA", description: "Freezing Rain", Icon: Heavysleet, time: "17" },
];
const deiceEstimatorHeaders = [
    { title: "deice.capacity.controlPanel.timeEstimator.weatherCode" },
    { title: "deice.capacity.controlPanel.timeEstimator.explanation" },
    { title: "deice.capacity.controlPanel.timeEstimator.edit" },
];
const iconSize = {
    width: 24,
    height: 24,
};
const DeIceTimeEstimate = ({ code, description, Icon, time }) => {
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    return (_jsxs(Grid, { columns: isMobile ? 2 : 3, children: [_jsxs(HStack, { gap: 0.5, style: { alignItems: "center" }, children: [_jsx("p", { className: "deice-time-estimator__code", children: code }), _jsx(Icon, Object.assign({}, iconSize))] }), !isMobile ? _jsx("div", { className: "deice-time-estimator__description", children: description }) : null, _jsxs("p", { className: "deice-time-estimator__time", children: [_jsxs("span", { className: "deice-time-estimator__time-value", children: [time, " "] }), _jsx("span", { className: "deice-time-estimator__time-unit", children: "min" })] })] }));
};
const DeIceTimeEstimator = () => {
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    const { t } = useTranslation();
    return (_jsxs(VStack, { className: "deice-time-estimator", gap: 1, children: [_jsx(DeIceWeatherConditionFilter, {}), _jsx(Grid, { columns: isMobile ? 2 : 3, children: deiceEstimatorHeaders.map(({ title }) => {
                    if (isMobile && title.includes("explanation"))
                        return;
                    return (_jsx("p", { className: "deice-time-estimator__title", children: t(title) }, title));
                }) }), deiceTimeEstimators.map((estimate) => (_jsx(DeIceTimeEstimate, Object.assign({}, estimate), estimate.code)))] }));
};
export default DeIceTimeEstimator;
