import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@digdir/designsystemet-react";
import { ArrowCirclepathIcon, PlusIcon } from "@navikt/aksel-icons";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PlaneUpRightOutlined } from "@avinor/react-icons";
import AuthorizedButton from "@components/Buttons/AuthorizedButton/AuthorizedButton";
import Widget from "@components/Widget/Widget";
import { ERoute, ESubRoute } from "@enums";
import { PlanOverviewAccordion } from "@features/Plan/components/PlanOverview/PlanOverviewAccordion";
import useRoles from "@auth/hooks/useRoles";
export const PlanOverviewProcessStatusesWidget = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPlanWriteRole } = useRoles();
    const queryClient = useQueryClient();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t("plan.create.statusWidget.title"), subtitle: t("plan.create.statusWidget.subtitle"), icon: {
                    component: (_jsx(PlaneUpRightOutlined, { width: "1.5rem", height: "1.5rem" })),
                } }), _jsx(Widget.Body, { children: _jsxs("div", { children: [_jsxs("div", { style: { display: "flex", justifyContent: "space-between" }, children: [_jsxs(AuthorizedButton, { hasRole: hasPlanWriteRole, variant: "primary", size: "sm", color: "accent", style: { marginBottom: "10px" }, onClick: () => {
                                        window.scrollTo({ top: 0, behavior: "smooth" });
                                        navigate(`${ERoute.Plan}${ESubRoute.PlanCreate}`);
                                    }, children: [_jsx(PlusIcon, { "aria-hidden": true, fontSize: "1.5rem" }), t("plan.create.statusWidget.addStatusButton")] }), _jsxs(Button, { variant: "tertiary", color: "accent", size: "sm", onClick: () => queryClient.invalidateQueries({ queryKey: ["getPlanOverview"] }), children: [_jsx(ArrowCirclepathIcon, { title: "a11y-title", fontSize: "1rem" }), t("plan.create.statusWidget.refreshButton")] })] }), _jsx(PlanOverviewAccordion, {})] }) })] }));
};
PlanOverviewProcessStatusesWidget.displayName = "PlanOverviewProcessStatusesWidget";
