import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import { FlightMovementsToday } from "../components";
import { FlightMovementsTotal } from "../components/FlightMovementsToday";
export const FlightMovementsTodayWidget = () => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { style: { justifyContent: "space-between" }, children: [_jsx(Widget.Header, { title: t("flightMovements.widgets.today.title"), subtitle: t("flightMovements.widgets.today.subTitle") }), _jsxs(Suspense, { fallback: _jsx(Spinner, {}), children: [_jsx(Widget.NumericIndicator, { children: _jsx(FlightMovementsTotal, {}) }), _jsx(Widget.Body, { children: _jsx(FlightMovementsToday, {}) })] })] }));
};
FlightMovementsTodayWidget.displayName = "FlightMovementsTodayWidget";
