import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
const UtcContext = createContext(undefined);
export const UtcProvider = ({ children }) => {
    const [utc, setUtc] = useState(false);
    return _jsx(UtcContext.Provider, { value: { utc, setUtc }, children: children });
};
export const useUtcContext = () => {
    const context = useContext(UtcContext);
    if (!context) {
        throw new Error("useUtcContext must be used within a UTCProvider");
    }
    return context;
};
