var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./Button.scss";
import cl from "clsx";
import { forwardRef, useMemo, useRef, useState } from "react";
import mergeRefs, { omit } from "@utils/Utils";
import Spinner from "@components/Spinner/Spinner";
import { useClientLayoutEffect } from "@hooks";
/**
 * A button component
 * @see [📝 Documentation](<apply-link>)
 * @see 🏷️ {@link ButtonProps}
 * @see [🤖 OverridableComponent](<apply-link>) support
 * @example
 * ```jsx
 * <Button>Klikk meg</Button>
 * ```
 */
// eslint-disable-next-line react/display-name
export const Button = forwardRef((_a, ref) => {
    var { as: Component = "button", variant = "primary", className, children, size = "medium", loading = false, disabled, style, icon, iconPosition = "left" } = _a, rest = __rest(_a, ["as", "variant", "className", "children", "size", "loading", "disabled", "style", "icon", "iconPosition"]);
    const buttonRef = useRef(null);
    const [widthOverride, setWidthOverride] = useState();
    const mergedRef = useMemo(() => mergeRefs([buttonRef, ref]), [ref]);
    useClientLayoutEffect(() => {
        if (loading) {
            const requestID = window.requestAnimationFrame(() => {
                var _a, _b;
                setWidthOverride((_b = (_a = buttonRef === null || buttonRef === void 0 ? void 0 : buttonRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) === null || _b === void 0 ? void 0 : _b.width);
            });
            return () => {
                setWidthOverride(undefined);
                cancelAnimationFrame(requestID);
            };
        }
    }, [loading, children]);
    const filterProps = (disabled !== null && disabled !== void 0 ? disabled : widthOverride) ? omit(rest, ["href"]) : rest;
    return (_jsx(Component, Object.assign({}, (Component !== "button" ? { role: "button" } : {}), filterProps, { ref: mergedRef, onKeyUp: (e) => {
            var _a;
            (_a = filterProps.onKeyUp) === null || _a === void 0 ? void 0 : _a.call(filterProps, e);
            if (e.key === " " && !disabled && !widthOverride && !e.isDefaultPrevented()) {
                e.currentTarget.click();
            }
        }, className: cl(className, "avinor-button", `avinor-button--${variant}`, `avinor-button--${size}`, {
            "avinor-button--loading": widthOverride,
            "avinor-button--icon-only": !!icon && !children,
            "avinor-button--disabled": disabled !== null && disabled !== void 0 ? disabled : widthOverride,
        }), style: Object.assign(Object.assign({}, style), { width: widthOverride }), disabled: (disabled !== null && disabled !== void 0 ? disabled : widthOverride) ? true : undefined, children: widthOverride ? (_jsx(Spinner, { type: "threeDots", size: 24, color: variant === "primary" ? "var(--avinor-white)" : "var(--avinor-violet-light-400)" })) : (_jsxs(_Fragment, { children: [icon && iconPosition === "left" && _jsx("span", { className: "avinor-button__icon", children: icon }), children && _jsx("span", { children: children }), icon && iconPosition === "right" && _jsx("span", { className: "avinor-button__icon", children: icon })] })) })));
});
export default Button;
