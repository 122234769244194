import { jsx as _jsx } from "react/jsx-runtime";
import Spinner from "@components/Spinner/Spinner";
import { AvinorColor } from "@constants";
import { PieChart } from "@charts/apexcharts/PieChart";
import useDelayCodesData from "../hooks/useDelayCodesData";
import { findTopN } from "../utils";
export const DelayTopFiveDelayCodesPieChart = () => {
    const { data, isLoading } = useDelayCodesData();
    const topFiveDelayCodes = findTopN((data === null || data === void 0 ? void 0 : data.delayCodes) || [], 5);
    const delayNumbersData = topFiveDelayCodes.map((delayCode, index) => ({
        name: delayCode.delaycode,
        value: delayCode.totalDelays,
        fill: [
            AvinorColor.ChartMintGreen300,
            AvinorColor.ChartVioletDark600,
            AvinorColor.ChartVioletLight400,
            AvinorColor.ChartGrey200,
            AvinorColor.ChartTangerine200,
        ][index],
    }));
    const colors = delayNumbersData.map((delayNumber) => delayNumber.fill);
    const series = delayNumbersData.map((delayNumber) => delayNumber.value);
    const labels = delayNumbersData.map((delayNumber) => delayNumber.name);
    if (isLoading)
        return _jsx(Spinner, {});
    return (_jsx(PieChart, { colors: colors, series: series, labels: labels }));
};
