import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { SuitcaseClaimOutlined } from "@avinor/react-icons";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import { BaggageOnBeltPerformanceAverageTable } from "@features/Baggage/components";
import { UserDomains } from "@auth/hooks/useEmailDomain";
export const BaggageOnBeltPerformanceAverageTableWidget = ({ title, subTitle, }) => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t(title), subtitle: t(subTitle), icon: {
                    component: (_jsx(SuitcaseClaimOutlined, { width: "2rem", height: "2rem" })),
                } }), _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsx(Widget.Body, { children: _jsx(BaggageOnBeltPerformanceAverageTable, {}) }) })] }));
};
BaggageOnBeltPerformanceAverageTableWidget.displayName = "BaggageOnBeltPerformanceAverageTableWidget";
BaggageOnBeltPerformanceAverageTableWidget.permissions = { requiredDomain: UserDomains.Avinor };
