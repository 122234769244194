import { httpOnTimePerformanceService } from "@services/HttpService";
import { resolveUrl } from "@utils/UrlUtils";
import { getMSALToken } from "@auth/authUtils";
export async function getPunctualityDay({ iata, date }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("date", date);
    const token = await getMSALToken();
    const response = await httpOnTimePerformanceService.get(resolveUrl({
        url: `/punctuality-day?${params}`,
        mockEndpoint: "/punctualityDay",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function getRegularityAndPunctualityPeriod({ iata, from, to, paxOnly = true, cargoOnly = false, direction, }) {
    const params = new URLSearchParams("");
    params.set("from", from.toString());
    params.set("to", to.toString());
    params.set("iata", iata);
    params.set("paxOnly", paxOnly.toString());
    params.set("cargoOnly", cargoOnly.toString());
    const token = await getMSALToken();
    const res = await httpOnTimePerformanceService.get(resolveUrl({
        url: `regularity-and-punctuality-period/${direction}?${params}`,
        mockEndpoint: "/regularity-and-punctuality-period",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
}
export async function getHistoricSnapshotPunctualityData({ iata, fromDate, toDate, }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("fromDate", fromDate);
    params.set("toDate", toDate);
    const token = await getMSALToken();
    const res = await httpOnTimePerformanceService.get(resolveUrl({
        url: `historic-snapshot-punctuality?${params}`,
        mockEndpoint: "/historicSnapshot",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
}
export async function getDelayNumbers({ iata, date }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("date", date);
    const token = await getMSALToken();
    const response = await httpOnTimePerformanceService.get(resolveUrl({
        url: `/delay-numbers?${params}`,
        mockEndpoint: "/delay-numbers",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function getDelayCodes({ iata, date }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("date", date);
    const token = await getMSALToken();
    const response = await httpOnTimePerformanceService.get(resolveUrl({
        url: `/delaycodes?${params}`,
        mockEndpoint: "/delaycodes",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function getStandDelays({ iata, date }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("date", date);
    const token = await getMSALToken();
    const response = await httpOnTimePerformanceService.get(resolveUrl({
        url: `/stand-delays?${params}`,
        mockEndpoint: "/stand-delays",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function getTaxiTime({ iata, from, to, direction }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("from", from);
    params.set("to", to);
    const token = await getMSALToken();
    const response = await httpOnTimePerformanceService.get(resolveUrl({
        url: `/taxi-time/${direction}?${params}`,
        mockEndpoint: "/taxi-time",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function getRecoverDelay({ iata, from, to, }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("from", from);
    params.set("to", to);
    const token = await getMSALToken();
    const response = await httpOnTimePerformanceService.get(resolveUrl({
        url: `/recover-delay?${params}`,
        mockEndpoint: "/recover-delay",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
