import { jsx as _jsx } from "react/jsx-runtime";
import "./ErrorMessageWidget.scss";
import { t } from "i18next";
import { WidgetCard } from "../WidgetCard/WidgetCard";
const ErrorMessageWidget = ({ title, description, message = t("noDataAvailable"), icon, linkText, link, underline, footerText, size, }) => {
    let component;
    const data = (_jsx("div", { className: "error-message-widget", children: _jsx("span", { className: "error-message-widget__text", children: message }) }));
    switch (size) {
        case "s":
            component = (_jsx(WidgetCard, { title: title, description: description, icon: icon, children: data }));
            break;
        case "l":
            component = (_jsx(WidgetCard, { title: title, description: description, underline: underline, link: link, linkText: linkText, footerText: footerText, children: data }));
            break;
        default:
            component = (_jsx(WidgetCard, { title: title, description: description, underline: underline, link: link, linkText: linkText, footerText: footerText, children: data }));
            break;
    }
    return component;
};
export default ErrorMessageWidget;
