import clsx from "clsx";
export const typoClassNames = (props) => {
    return clsx({
        "avinor-typo--spacing": props.spacing,
        "avinor-typo--truncate": props.truncate,
        "avinor-typo--semibold": props.weight === "semibold",
        [`avinor-typo--align-${props.align}`]: props.align,
        [`avinor-typo--color-${props.textColor}`]: props.textColor,
        "avinor-typo--visually-hidden": props.visuallyHidden,
        "avinor-typo--uppercase": props.uppercase,
    });
};
