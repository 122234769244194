var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Tooltip } from "@digdir/designsystemet-react";
import Spinner from "@components/Spinner/Spinner";
const AuthorizedButton = (_a) => {
    var { hasRole, children, isLoading, variant } = _a, buttonProps = __rest(_a, ["hasRole", "children", "isLoading", "variant"]);
    if (isLoading) {
        return (_jsx(Button, Object.assign({ variant: variant }, buttonProps, { disabled: true, children: _jsx(Spinner, { type: "threeDots", size: 24, color: variant === "primary" ? "var(--avinor-white)" : "var(--avinor-violet-light-400)" }) })));
    }
    return hasRole ? (_jsx(Button, Object.assign({ variant: variant }, buttonProps, { children: children }))) : (_jsx(Tooltip, { content: "Du er ikke autorisert", placement: "top", children: _jsx(Button, Object.assign({ variant: variant }, buttonProps, { disabled: true, children: _jsx(_Fragment, { children: children }) })) }));
};
export default AuthorizedButton;
