var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { HeaderCellSort } from "@table-library/react-table-library/sort";
import { Body, Cell, Header, HeaderCell, HeaderRow, Row, Table as ReactTable, } from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";
import { useTranslation } from "react-i18next";
import { useUtcContext } from "@contexts/UtcContext";
import Pagination from "./Pagination/Pagination";
import { defaultTheme, designsystemetTheme } from "./themes";
import { handleBooleanValues, handleMissingDataInCell, formatDateForSpecifiedColumns } from "./utils";
const defaultCellRenderer = (props) => {
    const { value, key, hide, row, cellIndex, inputColumns, booleanColumns, dateColumns, gridColumnStart, gridColumnEnd, className, handleUpdate, } = props;
    let processedValue = value;
    processedValue = handleMissingDataInCell(processedValue);
    processedValue = handleBooleanValues(booleanColumns, key, processedValue);
    processedValue = formatDateForSpecifiedColumns(dateColumns, key, processedValue);
    if (inputColumns === null || inputColumns === void 0 ? void 0 : inputColumns.includes(key)) {
        const keyIndex = inputColumns.indexOf(key);
        return (_jsx(Cell, { hide: hide, gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, className: className, children: _jsx("input", { type: "text", style: {
                    width: "100%",
                    border: "none",
                    fontSize: "1rem",
                    padding: 0,
                    margin: 0,
                }, value: `${processedValue === value ? value : processedValue}`, onChange: (event) => handleUpdate === null || handleUpdate === void 0 ? void 0 : handleUpdate(event.target.value, row.id, inputColumns[keyIndex]) }) }, `${row.id}-${cellIndex}`));
    }
    return (_jsx(Cell, { hide: hide, gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, className: className, children: `${processedValue === value ? value : processedValue}` }, `${row.id}-${cellIndex}`));
};
const mergeThemes = (defaultTheme, customTheme) => {
    const mergedTheme = Object.assign({}, defaultTheme);
    if (customTheme) {
        const allKeys = new Set([...Object.keys(defaultTheme), ...Object.keys(customTheme)]);
        allKeys.forEach((key) => {
            const themeKey = key;
            mergedTheme[themeKey] = customTheme[themeKey]
                ? defaultTheme[themeKey]
                    ? `${defaultTheme[themeKey]} ${customTheme[themeKey]}`
                    : customTheme[themeKey]
                : defaultTheme[themeKey];
        });
    }
    return mergedTheme;
};
const getHeaderGroupsWithWidths = (headerGroups) => {
    const headerGroupsWithWidths = [];
    headerGroups.forEach((group) => {
        // Calculate the total weight of all headers in the group
        const totalWeight = group.headers.reduce((total, header) => {
            var _a;
            return Number(total) + (Number((_a = header.cellProps) === null || _a === void 0 ? void 0 : _a.weight) || 1); // Use weight if available, otherwise default to 1
        }, 0);
        const headersWithWidth = group.headers.map((header) => {
            var _a;
            // Calculate the percentage width based on the header's weight
            const weight = ((_a = header.cellProps) === null || _a === void 0 ? void 0 : _a.weight) || 1;
            const width = `${Math.round((100 / totalWeight) * weight * 100) / 100}%`;
            return Object.assign(Object.assign({}, header), { cellProps: Object.assign(Object.assign({}, header.cellProps), { width }) });
        });
        headerGroupsWithWidths.push({ headers: headersWithWidth });
    });
    return headerGroupsWithWidths;
};
const Table = (_a) => {
    var { data, sort, headerGroups, headerVisibility = "visible", hiddenColumns = [], customTheme, footerText, pagination, enableHorizontalScroll, useDesignsystemetTheme, maxHeight, style } = _a, commonProps = __rest(_a, ["data", "sort", "headerGroups", "headerVisibility", "hiddenColumns", "customTheme", "footerText", "pagination", "enableHorizontalScroll", "useDesignsystemetTheme", "maxHeight", "style"]);
    const { utc } = useUtcContext();
    const { t } = useTranslation();
    const theme = useTheme(mergeThemes(useDesignsystemetTheme ? designsystemetTheme : defaultTheme, customTheme));
    const allHeaders = headerGroups.flatMap((group) => group.headers);
    const headerGroupsWithWidths = useDesignsystemetTheme ? getHeaderGroupsWithWidths(headerGroups) : headerGroups;
    const renderCell = (props) => {
        const { key, excludeKeys = [], cellRenderers = {} } = props;
        if (["id", ...excludeKeys].includes(key))
            return null;
        return cellRenderers[key] ? cellRenderers[key](props) : defaultCellRenderer(props);
    };
    const shouldHideColumn = (key) => hiddenColumns.includes(key);
    const getHeaderGroupByKey = (key) => allHeaders.find((header) => header.key === key);
    return (_jsxs(_Fragment, { children: [_jsx("div", { style: { overflowX: "auto", maxHeight: maxHeight }, children: _jsx(ReactTable, { data: data, sort: sort, theme: theme, pagination: pagination, style: style, layout: enableHorizontalScroll ? { custom: true, horizontalScroll: true } : {}, children: (tableList) => (_jsxs(_Fragment, { children: [_jsx(Header, { style: { display: headerVisibility === "visible" ? "contents" : "none" }, children: headerGroupsWithWidths.map(({ headers }, groupIndex) => (_jsx(HeaderRow, { children: headers.map((header) => {
                                        var _a;
                                        if (header.sortKey) {
                                            const className = ((_a = header.cellProps) === null || _a === void 0 ? void 0 : _a.className)
                                                ? `${header.cellProps.className} header-sortable`
                                                : "header-sortable";
                                            if (header.cellProps)
                                                header.cellProps.className = className;
                                            return (_jsx(HeaderCellSort, Object.assign({ sortKey: header.sortKey, hide: shouldHideColumn(header.key) }, header.cellProps, { children: header.content }), header.key));
                                        }
                                        return (_jsx(HeaderCell, Object.assign({ hide: shouldHideColumn(header.key) }, header.cellProps, { children: header.content }), header.key));
                                    }) }, `${headers[groupIndex].key}-${groupIndex}`))) }), _jsx(Body, { children: tableList.map((row) => (_jsx(Row, { item: row, children: Object.entries(row).map(([key, value], i) => {
                                        var _a, _b, _c;
                                        const headerGroup = getHeaderGroupByKey(key);
                                        const cellProps = Object.assign({ row,
                                            key,
                                            value, cellIndex: i, utc, hide: shouldHideColumn(key), translation: t, gridColumnStart: (_a = headerGroup === null || headerGroup === void 0 ? void 0 : headerGroup.cellProps) === null || _a === void 0 ? void 0 : _a.gridColumnStart, gridColumnEnd: (_b = headerGroup === null || headerGroup === void 0 ? void 0 : headerGroup.cellProps) === null || _b === void 0 ? void 0 : _b.gridColumnEnd, className: (_c = headerGroup === null || headerGroup === void 0 ? void 0 : headerGroup.cellProps) === null || _c === void 0 ? void 0 : _c.className }, commonProps);
                                        return renderCell(cellProps);
                                    }) }, row.id))) })] })) }) }), footerText && (_jsx("p", { style: {
                    width: "max-content",
                    fontSize: "14px",
                    fontWeight: "400",
                    padding: "15px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                }, children: footerText })), _jsx(Pagination, { pagination: pagination, data: data, style: { width: style === null || style === void 0 ? void 0 : style.width } })] }));
};
export default Table;
