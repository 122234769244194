import { useQuery } from "@tanstack/react-query";
import { useIataContext } from "@contexts/IataContext";
import { getFacilityStatusListData } from "../services";
const useFacilityStatusListData = () => {
    const { iata } = useIataContext();
    const { data, error, isLoading } = useQuery({
        queryFn: () => getFacilityStatusListData({
            iata,
        }),
        queryKey: ["getFacilityStatusListData", { iata }],
    });
    return { data, error, iata, isLoading };
};
export default useFacilityStatusListData;
