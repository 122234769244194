import { AvinorColor } from "@constants";
const planeBodySizeColorMap = {
    A: AvinorColor.MintGreen200,
    B: AvinorColor.MintGreen200,
    C: AvinorColor.White,
    D: AvinorColor.VioletPrimaryLight,
    E: AvinorColor.VioletPrimaryLight,
    F: AvinorColor.VioletPrimaryLight,
};
export const mapPlaneBodySizeToColor = (planeBodySize) => {
    return planeBodySizeColorMap[planeBodySize] || AvinorColor.Grey200;
};
