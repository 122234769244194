import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./CheckboxGroup.scss";
import { useState } from "react";
import { CheckboxGroupContext } from "./CheckboxGroupContext";
const CheckboxGroup = ({ children, legend }) => {
    const [checkedItems, setCheckedItems] = useState({});
    const toggleItem = (id) => {
        setCheckedItems((prev) => (Object.assign(Object.assign({}, prev), { [id]: !prev[id] })));
    };
    return (_jsx(CheckboxGroupContext.Provider, { value: { checkedItems, toggleItem }, children: _jsxs("fieldset", { className: "checkbox-group-container", children: [legend && _jsx("legend", { children: legend }), children] }) }));
};
export default CheckboxGroup;
