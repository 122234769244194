import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isPast, isToday } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getDateToReadableText } from "@utils/DateUtils";
//import ButtonGroup from "@components/Buttons/ButtonGroup/ButtonGroup";
import { WidgetLayout } from "@components/WidgetLayout/WidgetLayout";
import { Page } from "@enums";
import { BaggageMode } from "@features/Baggage/models";
import { historicalBaggageWidgets, layout, todayBaggageArrivalWidgets, todayBaggageDepartureWidgets, } from "@widgets/registry";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { FlightStagesProvider } from "@contexts/FlightStageContext";
import { useIataContext } from "@contexts/IataContext";
import PageLayout from "./PageLayout/PageLayout";
import { getDatePickerSelectOptionsForPage, getWidgetsByTimeScope } from "./utils";
const BaggagePage = () => {
    const { iata: airport } = useIataContext();
    const { t } = useTranslation();
    const { picker: { from, id }, } = useDatePickerContext();
    const [currentMode, setCurrentMode] = useState(BaggageMode.Arrival);
    const buttons = [
        {
            id: BaggageMode.Arrival,
            text: "Ankomst",
            onClick: () => {
                setCurrentMode(BaggageMode.Arrival);
            },
        },
        {
            id: BaggageMode.Departure,
            text: "Avgang",
            onClick: () => {
                setCurrentMode(BaggageMode.Departure);
            },
        },
    ];
    const pageStyling = isToday(from) ? Object.assign(Object.assign({}, layout), { margin: "0 0 2rem 0" }) : layout;
    const widgets = currentMode == BaggageMode.Arrival
        ? getWidgetsByTimeScope({
            today: todayBaggageArrivalWidgets,
            historical: historicalBaggageWidgets,
        }, from)
        : getWidgetsByTimeScope({
            today: todayBaggageDepartureWidgets,
            historical: historicalBaggageWidgets,
        }, from);
    return (_jsxs(PageLayout, { customDatePickerProps: {
            id,
            selectProps: {
                options: getDatePickerSelectOptionsForPage(Page.Baggage),
            },
            datePickerProps: {
                shouldDisableDate: (date) => {
                    return !isPast(date) && !isToday(date);
                },
            },
        }, title: `${t("pages.baggage.title")} ${getDateToReadableText().toLowerCase()}`, description: t("pages.baggage.description", { iata: airport }), children: [isToday(from) && (_jsx("div", { style: { width: "100%", display: "flex", justifyContent: "center", margin: "1rem 0" } })), _jsx(FlightStagesProvider, { children: _jsx(WidgetLayout, { layout: pageStyling, widgets: widgets }) })] }));
};
export default BaggagePage;
