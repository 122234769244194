import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./AirportTrainsDetailsTable.scss";
import { Delay } from "@enums/delay";
import { Cell } from "@table-library/react-table-library";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { mapTrainCompanyName, minutesToHoursAndMinutes } from "@utils/Utils";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import Table from "@components/Table/Table";
import { addIdToObj } from "@components/Table/utils";
import TrainOperatorIcon from "@components/TrainOperatorIcon/TrainOperatorIcon";
import { getAirportTrainsStatuses } from "@features/AirportTransportation/services";
import { useIataContext } from "@contexts/IataContext";
import AirportTrainsDetailsTableTimeCell from "./AirportTrainsDetailsTableTimeCell";
import { transformTrainsDetails } from "./AirportTrainsDetailsTableUtils";
const styleSheet = {
    trainsDetails: "trains-details",
    container: "trains-details__container",
    heading: "trains-details__heading",
    subHeading: "trains-details__sub-heading",
    figure: "trains-details__figure",
    operatorCell: "trains-details__operator-cell",
    delayCell: "trains-details__delay-cell",
    trainLine: "trains-details__train-line",
    routeCell: "trains-details__route-cell",
};
const customCellRenderers = (t) => {
    return {
        operator: ({ cellIndex, value, gridColumnStart, gridColumnEnd }) => {
            return (_jsxs(Cell, { className: styleSheet.operatorCell, gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, children: [_jsx(TrainOperatorIcon, { operator: value }), _jsx("p", { children: mapTrainCompanyName(value) })] }, cellIndex));
        },
        arrivalTime: ({ cellIndex, gridColumnStart, gridColumnEnd, value: { aimedArrivalTime, expectedArrivalTime, arrivalStatus }, }) => {
            return (_jsx(AirportTrainsDetailsTableTimeCell, { props: {
                    cellIndex,
                    gridColumnStart,
                    gridColumnEnd,
                    aimedTime: aimedArrivalTime,
                    expectedTime: expectedArrivalTime,
                    status: arrivalStatus,
                } }));
        },
        departureTime: ({ cellIndex, gridColumnStart, gridColumnEnd, value: { aimedDepartureTime, expectedDepartureTime, departureStatus }, }) => {
            return (_jsx(AirportTrainsDetailsTableTimeCell, { props: {
                    cellIndex,
                    gridColumnStart,
                    gridColumnEnd,
                    aimedTime: aimedDepartureTime,
                    expectedTime: expectedDepartureTime,
                    status: departureStatus,
                } }));
        },
        delayInMin: ({ cellIndex, gridColumnStart, gridColumnEnd, value }) => {
            const delayInMinClasses = clsx(styleSheet.delayCell, {
                [`${styleSheet.delayCell}--no-delay`]: value <= 0,
            });
            return (_jsx(Cell, { className: delayInMinClasses, gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, children: value > 0 ? _jsx("span", { children: minutesToHoursAndMinutes(value, t) }) : "-" }, cellIndex));
        },
        route: ({ cellIndex, gridColumnStart, gridColumnEnd, value: { trainLine, destination } }) => {
            return (_jsx(Cell, { className: styleSheet.routeCell, gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, children: _jsxs("p", { children: [_jsx("span", { className: styleSheet.trainLine, children: trainLine }), _jsx("span", { children: destination })] }) }, cellIndex));
        },
    };
};
const tableColumnHeaders = {
    operator: "operator",
    time: "time",
    delay: "delay",
    route: "route",
};
const headers = Object.keys(tableColumnHeaders);
const getTableColumnHeaders = (t) => {
    return [
        {
            headers: [
                {
                    content: t(tableColumnHeaders.operator),
                    key: headers[0],
                    cellProps: {
                        gridColumnStart: 1,
                        gridColumnEnd: 2,
                    },
                },
                {
                    content: t(tableColumnHeaders.time),
                    key: headers[1],
                    cellProps: {
                        gridColumnStart: 2,
                        gridColumnEnd: 3,
                    },
                },
                {
                    content: t(tableColumnHeaders.delay),
                    key: headers[2],
                    cellProps: {
                        gridColumnStart: 3,
                        gridColumnEnd: 4,
                    },
                },
                {
                    content: t(tableColumnHeaders.route),
                    key: headers[3],
                    cellProps: {
                        gridColumnStart: 4,
                        gridColumnEnd: 7,
                    },
                },
            ],
        },
    ];
};
const TrainDetailsTable = ({ title, subTitle, nodes }) => {
    const { t } = useTranslation();
    return (_jsxs("section", { className: styleSheet.container, children: [_jsx("h3", { className: styleSheet.heading, children: title }), subTitle && _jsx("p", { className: styleSheet.subHeading, children: subTitle }), _jsx("figure", { className: styleSheet.figure, children: _jsx(Table, { data: { nodes }, headerGroups: getTableColumnHeaders(t), cellRenderers: customCellRenderers(t) }) })] }));
};
export const AirportTrainsDetailsArrivalTable = () => {
    const { iata } = useIataContext();
    const { data: airportTrainsStatusesData } = useQuery({
        queryFn: () => getAirportTrainsStatuses({
            iata: iata,
        }),
        queryKey: ["getAirportTrainsStatuses", { iata }],
        refetchInterval: Delay.TwoMinutes,
    });
    const { t } = useTranslation();
    if (!airportTrainsStatusesData)
        return _jsx(NoDataAvailable, {});
    const trainsDetails = transformTrainsDetails(airportTrainsStatusesData);
    const { nodes: arrivalNodes } = addIdToObj(trainsDetails.arrival);
    return (_jsx(TrainDetailsTable, { title: t("common.arrivals"), subTitle: `${t("common.to")} ${t(`airportIata.${iata.toLowerCase()}`)}`, nodes: arrivalNodes }));
};
export const AirportTrainsDetailsDepartureTable = () => {
    const { iata } = useIataContext();
    const { data: airportTrainsStatusesData } = useQuery({
        queryFn: () => getAirportTrainsStatuses({
            iata: iata,
        }),
        queryKey: ["getAirportTrainsStatuses", { iata }],
        refetchInterval: Delay.TwoMinutes,
    });
    const { t } = useTranslation();
    if (!airportTrainsStatusesData)
        return _jsx(NoDataAvailable, {});
    const trainsDetails = transformTrainsDetails(airportTrainsStatusesData);
    const { nodes: departureNodes } = addIdToObj(trainsDetails.departure);
    return (_jsx(TrainDetailsTable, { title: t("common.departures"), subTitle: `${t("common.to")} ${t(`airportIata.${iata.toLowerCase()}`)}`, nodes: departureNodes }));
};
const AirportTrainsDetailsTable = () => {
    return (_jsxs("section", { className: styleSheet.trainsDetails, children: [_jsx(AirportTrainsDetailsArrivalTable, {}), _jsx(AirportTrainsDetailsDepartureTable, {})] }));
};
AirportTrainsDetailsTable.displayName = "AirportTrainsDetailsTable";
export default AirportTrainsDetailsTable;
