export const getFlightsBy = (property, flightMovements) => {
    var _a;
    if (property === "internationalTotal") {
        const international = (flightMovements === null || flightMovements === void 0 ? void 0 : flightMovements.flightCounts.reduce((acc, flight) => acc + (flight.internationalTotal || 0), 0)) || 0;
        const schengen = (flightMovements === null || flightMovements === void 0 ? void 0 : flightMovements.flightCounts.reduce((acc, flight) => acc + (flight.schengenTotal || 0), 0)) || 0;
        return international + schengen;
    }
    else if (property === "internationalArrival") {
        const international = (flightMovements === null || flightMovements === void 0 ? void 0 : flightMovements.flightCounts.reduce((acc, flight) => acc + (flight.internationalArrival || 0), 0)) || 0;
        const schengen = (flightMovements === null || flightMovements === void 0 ? void 0 : flightMovements.flightCounts.reduce((acc, flight) => acc + (flight.schengenArrival || 0), 0)) || 0;
        return international + schengen;
    }
    else if (property === "internationalDeparture") {
        const international = (flightMovements === null || flightMovements === void 0 ? void 0 : flightMovements.flightCounts.reduce((acc, flight) => acc + (flight.internationalDeparture || 0), 0)) || 0;
        const schengen = (flightMovements === null || flightMovements === void 0 ? void 0 : flightMovements.flightCounts.reduce((acc, flight) => acc + (flight.schengenDeparture || 0), 0)) || 0;
        return international + schengen;
    }
    return (((_a = flightMovements === null || flightMovements === void 0 ? void 0 : flightMovements.flightCounts) === null || _a === void 0 ? void 0 : _a.reduce((previousValue, currentFlight) => previousValue + (currentFlight[property] || 0), 0)) || 0);
};
