export var EDeIceTimes;
(function (EDeIceTimes) {
    EDeIceTimes[EDeIceTimes["SN"] = 10] = "SN";
    EDeIceTimes[EDeIceTimes["PSN"] = 15] = "PSN";
    EDeIceTimes[EDeIceTimes["FZFG"] = 5] = "FZFG";
    EDeIceTimes[EDeIceTimes["FZDZ"] = 10] = "FZDZ";
    EDeIceTimes[EDeIceTimes["FZRA"] = 17] = "FZRA";
})(EDeIceTimes || (EDeIceTimes = {}));
export var EWeatherCondition;
(function (EWeatherCondition) {
    EWeatherCondition["SN"] = "snow";
    EWeatherCondition["PSN"] = "heavySnow";
    EWeatherCondition["FZFG"] = "fog";
    EWeatherCondition["FZDZ"] = "lightSleet";
    EWeatherCondition["FZRA"] = "heavySleet";
})(EWeatherCondition || (EWeatherCondition = {}));
export var EDeIceHandler;
(function (EDeIceHandler) {
    EDeIceHandler["SGH"] = "SGH";
    EDeIceHandler["WGH"] = "WGH";
    EDeIceHandler["MENZIES"] = "Menzies";
    EDeIceHandler["MENZIES_SGH"] = "Menzies_SGH";
    EDeIceHandler["OTHER"] = "OTHER";
    EDeIceHandler["ALL"] = "ALL";
})(EDeIceHandler || (EDeIceHandler = {}));
export var EDeIcePad;
(function (EDeIcePad) {
    EDeIcePad["AN"] = "AN";
    EDeIcePad["AS"] = "AS";
})(EDeIcePad || (EDeIcePad = {}));
