import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Checkbox, CheckboxGroup } from "@components/Checkbox";
import { useFlightStageContext } from "@contexts/FlightStageContext";
const options = (t) => [
    { label: t("charts.flightMovements.bar.international"), value: "international" },
    { label: t("charts.flightMovements.bar.domestic"), value: "domestic" },
    { label: t("charts.flightMovements.bar.schengen"), value: "schengen" },
];
const FlightStageFilter = ({ flightStageKey }) => {
    const { stages: flightStages, setStages: setFlightStages } = useFlightStageContext(flightStageKey);
    const { t } = useTranslation();
    const handleCheckChange = (id, checked) => {
        const stages = new Set(flightStages);
        if (checked) {
            stages.add(id);
        }
        else {
            stages.delete(id);
        }
        setFlightStages(Array.from(stages));
    };
    return (_jsx(CheckboxGroup, { children: options(t).map(({ label, value }) => (_jsx(Checkbox, { id: value, label: label, value: value, variant: "large", onCheckChange: handleCheckChange, checked: flightStages.includes(value) }, value))) }));
};
export default FlightStageFilter;
