import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isToday } from "date-fns";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import BaggageOnBeltPerformanceAverageLineChart from "@charts/apexcharts/Baggage/OnBeltPerformance/Average/LineChart";
import { useDatePickerContext } from "@contexts/DatePickerContext";
export const BaggageOnBeltPerformanceAverageLineChartWidget = ({ title, subTitle, }) => {
    const { t } = useTranslation();
    const { picker: { from }, } = useDatePickerContext();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t(title, {
                    content: t(`charts.baggage.line.onBeltPerformance.${isToday(from) ? "soFarToday" : "averageTimePerAirline"}`),
                }), subtitle: t(subTitle) }), _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsx(Widget.Body, { children: _jsx(BaggageOnBeltPerformanceAverageLineChart, {}) }) })] }));
};
BaggageOnBeltPerformanceAverageLineChartWidget.displayName = "BaggageOnBeltPerformanceAverageLineChartWidget";
