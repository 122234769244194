import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import ButtonGroup from "@components/Buttons/ButtonGroup/ButtonGroup";
import { Granularity } from "@enums";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useGranularityContext } from "@contexts/GranularityContext";
const GranularityFilter = () => {
    var _a;
    const { picker: { from, id }, } = useDatePickerContext();
    const { granularity, setGranularity } = useGranularityContext({ from, currentPage: id });
    const { t } = useTranslation();
    const granularityLookup = {
        [Granularity.Day]: 1,
        [Granularity.Hour]: 2,
        [Granularity.ThirtyMinutes]: 3,
        [Granularity.FifteenMin]: 4,
        [Granularity.FiveMin]: 5,
    };
    const buttons = [
        {
            id: 1,
            text: t("filter.granularity.day"),
            onClick: () => setGranularity({ granularity: Granularity.Day }),
        },
        {
            id: 2,
            text: t("filter.granularity.hour"),
            onClick: () => setGranularity({ granularity: Granularity.Hour }),
        },
        {
            id: 3,
            text: t("filter.granularity.thirtyMin"),
            onClick: () => setGranularity({ granularity: Granularity.ThirtyMinutes }),
        },
        {
            id: 4,
            text: t("filter.granularity.fifteenMin"),
            onClick: () => setGranularity({ granularity: Granularity.FifteenMin }),
        },
        {
            id: 5,
            text: t("filter.granularity.fiveMin"),
            onClick: () => setGranularity({ granularity: Granularity.FiveMin }),
        },
    ];
    const active = (_a = buttons.find(({ id }) => id === granularityLookup[granularity])) === null || _a === void 0 ? void 0 : _a.id;
    return (_jsx(ButtonGroup, { active: active, buttons: buttons }));
};
export default GranularityFilter;
