import { isUnknownDelayCode } from "@features/OnTimePerformance/utils";
export const handleFilteringOnSelectedDelayCodes = (delayCodes, delayCodesOptionSelected) => {
    return delayCodesOptionSelected.flatMap((option) => { var _a; return filterDelayCodesByOption((_a = delayCodes.delayCodes) !== null && _a !== void 0 ? _a : [], option); });
};
const filterDelayCodesByOption = (delayCodes, option) => {
    switch (option.value) {
        case "others":
            return filterByOthers(delayCodes);
        case "damageFailure":
            return filterByDamageFailure(delayCodes);
        case "restrictions":
            return filterByRestrictions(delayCodes);
        case "authorities":
            return filterByAuthorities(delayCodes);
        default:
            return filterByPrefix(delayCodes, option.value);
    }
};
const filterByOthers = (delayCodes) => {
    return delayCodes.filter((code) => code.delaycode.startsWith("0") ||
        ["OA", "SG"].includes(code.delaycode) ||
        isUnknownDelayCode(code.delaycode));
};
const filterByDamageFailure = (delayCodes) => {
    return delayCodes.filter((code) => code.delaycode.startsWith("D") || code.delaycode.startsWith("E"));
};
const filterByRestrictions = (delayCodes) => {
    return delayCodes.filter((code) => ["AT", "AX", "AE", "AW"].includes(code.delaycode));
};
const filterByAuthorities = (delayCodes) => {
    return delayCodes.filter((code) => ["AS", "AG", "AF", "AD", "AM"].includes(code.delaycode));
};
const filterByPrefix = (delayCodes, prefix) => {
    return delayCodes.filter((code) => code.delaycode.startsWith(prefix) && !isUnknownDelayCode(code.delaycode));
};
export const handleFilteringOnSelectedAirlineCodes = (delayCodes, airlinesOptionSelected) => {
    var _a;
    const initialData = (_a = delayCodes === null || delayCodes === void 0 ? void 0 : delayCodes.delayCodes) !== null && _a !== void 0 ? _a : [];
    return initialData.reduce((filteredData, delaycode) => {
        const airlineBreakdowns = getFilteredAirlineBreakdowns(delaycode.airlineBreakdown, airlinesOptionSelected);
        if (airlineBreakdowns.length > 0) {
            filteredData.push(Object.assign(Object.assign({}, delaycode), { airlineBreakdown: airlineBreakdowns }));
        }
        return filteredData;
    }, []);
};
const getFilteredAirlineBreakdowns = (airlineBreakdown, filters) => {
    return filters.flatMap((filter) => airlineBreakdown.filter((airline) => airline.airlineName === filter.label));
};
export const handleFilteringOnSelectedDelayCodesAndAirlineCodes = (delayCodes, delayCodesOptionsSelected, airlinesOptionsSelected) => {
    const filteredByDelayCodes = handleFilteringOnSelectedDelayCodes(delayCodes, delayCodesOptionsSelected);
    return filteredByDelayCodes
        .map((delayCode) => (Object.assign(Object.assign({}, delayCode), { airlineBreakdown: getFilteredAirlineBreakdowns(delayCode.airlineBreakdown, airlinesOptionsSelected) })))
        .filter((delayCode) => delayCode.airlineBreakdown.length > 0);
};
