import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
const MenuContext = createContext(undefined);
export const MenuProvider = ({ children }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    return _jsx(MenuContext.Provider, { value: { menuOpen, setMenuOpen }, children: children });
};
export const useMenuContext = () => {
    const context = useContext(MenuContext);
    if (!context) {
        throw new Error("useMenuContext must be used within a UTCProvider");
    }
    return context;
};
