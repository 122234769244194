import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./PlanOverviewDisruptions.scss";
import { PlanOverviewDisruptionTooltip } from "./PlanOverviewDisruptionTooltip";
const generateRandomId = (length = 16) => {
    return Math.random().toString(36).substr(2, length);
};
export const PlanOverviewDisruptionsLabels = () => {
    const disruptions = Array.from({ length: 24 }, (_, index) => index);
    return (_jsx("div", { className: "plan-overview-timeline-container", children: disruptions.map((_, index) => (_jsxs("div", { className: "plan-overview-timeline-segment-text", style: index == 5 || index == 11 || index == 17 ? { marginRight: "15px" } : {}, children: [index === 0 ? _jsx("p", { children: "00:00" }) : "", index === 6 ? _jsx("p", { children: "06:00" }) : "", index === 12 ? _jsx("p", { children: "12:00" }) : "", index === 18 ? _jsx("p", { children: "18:00" }) : "", index === 23 ? _jsx("p", { style: { right: 0, left: "auto" }, children: "23:59" }) : ""] }, index))) }));
};
export const PlanOverviewDisruptions = ({ disruptions, inTableLayout, hideTimeLabels = false, }) => {
    return (_jsxs("div", { style: {
            display: "flex",
            flexDirection: "column",
            width: inTableLayout ? "100%" : "calc(75% + 8px)",
            marginRight: inTableLayout ? "0" : "8px",
            marginLeft: 0,
        }, children: [!hideTimeLabels && _jsx(PlanOverviewDisruptionsLabels, {}), _jsx("div", { className: "plan-overview-timeline-container", children: disruptions.map((disruption, index) => {
                    const segmentClass = `plan-overview-timeline-segment ${disruption.someDisturbances ? "plan-overview-timeline-segment-warning" : ""} ${disruption.majorDisturbances ? "plan-overview-timeline-segment-error" : ""}`;
                    const id = generateRandomId();
                    const showTooltip = disruption.someDisturbances || disruption.majorDisturbances;
                    const uniqueTooltipId = `plan-overview-tooltip-${id}`;
                    return (_jsx("div", { className: "plan-overview-timeline-segment-wrapper", style: index == 5 || index == 11 || index == 17 ? { marginRight: "15px" } : {}, children: showTooltip ? (_jsxs(_Fragment, { children: [_jsx("div", { className: segmentClass, "data-tooltip-id": "plan-overview-tooltip-" + uniqueTooltipId, "data-tooltip-place": "top", "data-tooltip-position-strategy": "fixed" }), _jsx(PlanOverviewDisruptionTooltip, { id: "plan-overview-tooltip-" + uniqueTooltipId, disruption: disruption })] })) : (_jsx("div", { className: segmentClass })) }, index + "-" + id));
                }) })] }));
};
