import { format, formatISO, getDay, isToday, isTomorrow, isYesterday, parseISO, roundToNearestMinutes, secondsToMinutes, } from "date-fns";
import { nb, enGB } from "date-fns/locale";
import { t } from "i18next";
import { useDatePickerContext } from "@contexts/DatePickerContext";
export const formatDate = (utc, date, formatPattern) => {
    return utc ? `${format(new Date(date), formatPattern)}Z` : format(new Date(date), formatPattern);
};
export const removeZuluSuffix = (date) => {
    return date.replace("Z", "");
};
//Prints out the date in a pretty format, as in "I dag kl. 12:00", "I går kl. 12:00" or "25-06-2024 kl. 12:00"
export const prettyPrintDate = (dateString, i18Language) => {
    try {
        const date = parseISO(dateString);
        const locale = i18Language == "en" ? enGB : nb;
        if (isToday(date)) {
            return `${t("todayAt")} ${format(date, "HH:mm", { locale: locale })}`;
        }
        else if (isYesterday(date)) {
            return `${t("yesterdayAt")} ${format(date, "HH:mm", { locale: locale })}`;
        }
        else {
            if (i18Language == "en") {
                return format(date, "dd-MM-yyyy HH:mm", { locale: locale });
            }
            else {
                return format(date, "dd-MM-yyyy 'kl.' HH:mm", { locale: locale });
            }
        }
    }
    catch (error) {
        console.error("Error parsing date", error);
        return "";
    }
};
// Primarily to handle errors when resetting the datepicker in firefox
export const dateIsValid = (date) => {
    return !Number.isNaN(new Date(date).getTime());
};
export const isSingleDay = (from, to) => {
    // Returns true if dates are same day
    return getDay(new Date(from)) === getDay(new Date(to));
};
export const handleDateDiff = (from, to) => {
    const toTime = new Date(to).getTime();
    const fromTime = new Date(from).getTime();
    const timeDiff = toTime - fromTime;
    const dayDiff = Math.round(timeDiff / (1000 * 3600 * 24));
    // returns number of days between two dates
    return dayDiff;
};
/**
 * Round the date's seconds to the nearest minute.
 * Return the rounded time as an ISO formatted string.
 *
 * @param dateString - The ISO date string
 * @return - The ISO formatted string with seconds rounded to the nearest minute
 */
export const roundDateToNearestMinute = (dateString) => {
    const date = parseISO(dateString);
    const roundedDate = roundToNearestMinutes(date, { nearestTo: 1 });
    return formatISO(roundedDate, { representation: "complete" });
};
/**
 * Returns alias names for the datepicker's from and to dates.
 */
export const getDateToReadableText = () => {
    const { picker: { from, to }, } = useDatePickerContext();
    const formattedFrom = format(from, "dd/MM", { locale: nb });
    const formattedTo = format(to, "dd/MM", { locale: nb });
    const isSingleDay = formattedFrom === formattedTo;
    if (isToday(from) && isToday(to))
        return t("today");
    if (isYesterday(from) && isYesterday(to))
        return t("yesterday");
    if (isTomorrow(from) && isTomorrow(to))
        return t("tomorrow");
    if (isSingleDay)
        return formattedFrom;
    return `${formattedFrom} - ${formattedTo}`;
};
export const secondsToMinutesRounded = (seconds) => {
    return secondsToMinutes(seconds + 59);
};
export const areDatesTheSame = (date1, date2) => {
    if (!date1 || !date2)
        return false;
    const endTime = new Date(date1);
    const startTime = new Date(date2);
    return endTime.toDateString() === startTime.toDateString();
};
