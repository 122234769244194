import { httpFlightMovementsService } from "@services/HttpService";
import { resolveUrl } from "@utils/UrlUtils";
import { getMSALToken } from "@auth/authUtils";
export async function getFlightMovements({ params: { iata, from, to, granularity, international, domestic, schengen, dataSource }, }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("from", from);
    params.set("to", to);
    params.set("interval", granularity);
    params.set("international", international.toString());
    params.set("domestic", domestic.toString());
    params.set("schengen", schengen.toString());
    const token = await getMSALToken();
    const response = await httpFlightMovementsService.get(resolveUrl({
        url: `/${dataSource}-counts?${params}`,
        mockEndpoint: "/flightCounts",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function getEnrouteFlightsData({ iata, hoursTo = 4, }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("hoursTo", hoursTo.toString());
    const token = await getMSALToken();
    const response = await httpFlightMovementsService.get(resolveUrl({
        url: `/enroute-flights?${params}`,
        mockEndpoint: "/enroute-flights",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function getFlowControlData() {
    const token = await getMSALToken();
    const response = await httpFlightMovementsService.get(resolveUrl({
        url: "/flow",
        mockEndpoint: "/flow",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
