import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
const HoursToFilterButtons = ({ setHoursTo }) => {
    const [active, setActive] = useState(2);
    const { t } = useTranslation();
    const buttons = [
        {
            id: 4,
            text: t("timePeriod.last6Hours"),
            onClick: () => {
                setActive(4);
                setHoursTo(-6);
            },
        },
        {
            id: 0,
            text: t("timePeriod.nextHour"),
            onClick: () => {
                setActive(0);
                setHoursTo(1);
            },
        },
        {
            id: 1,
            text: t("timePeriod.next2Hours"),
            onClick: () => {
                setActive(1);
                setHoursTo(2);
            },
        },
        {
            id: 2,
            text: t("timePeriod.next4Hours"),
            onClick: () => {
                setActive(2);
                setHoursTo(4);
            },
        },
    ];
    return (_jsx(ButtonGroup, { active: active, buttons: buttons }));
};
export default HoursToFilterButtons;
