import { httpBaggageService } from "@services/HttpService";
import { resolveUrl } from "@utils/UrlUtils";
import { Granularity } from "@enums";
import { getMSALToken } from "@auth/authUtils";
export async function getBaggageEnrouteStatistics({ iata, hoursTo = 4, }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("hoursTo", hoursTo.toString());
    const token = await getMSALToken();
    const response = await httpBaggageService.get(resolveUrl({
        url: `/enroute-bags?${params}`,
        mockEndpoint: "/enroute-bags",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function getBaggageEnroutePerFlightEstimate({ iata, hoursTo = 4, returnZulu = true, }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("hoursTo", hoursTo.toString());
    params.set("returnZulu", returnZulu.toString());
    const token = await getMSALToken();
    const response = await httpBaggageService.get(resolveUrl({
        url: `/enroute-bags-list?${params}`,
        mockEndpoint: "/enroute-bags-list",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function getBaggageEnrouteGrouped({ iata, hoursTo = 4, returnZulu = true, }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    params.set("hoursTo", hoursTo.toString());
    params.set("returnZulu", returnZulu.toString());
    const token = await getMSALToken();
    const response = await httpBaggageService.get(resolveUrl({
        url: `/enroute-bags-list-grouped?${params}`,
        mockEndpoint: "/enroute-bags-list-grouped",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function getBaggageAndPaxEnroute({ iata, }) {
    const params = new URLSearchParams("");
    params.set("iata", iata);
    const token = await getMSALToken();
    const response = await httpBaggageService.get(resolveUrl({
        url: `/enroute-bags-and-pax-sum?${params}`,
        mockEndpoint: "/enroute-bags-and-pax-sum",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function getBaggageOnBeltPerformanceAverage({ params: { iata, from, to, interval = Granularity.Day }, }) {
    const params = new URLSearchParams("");
    const token = await getMSALToken();
    params.set("iata", iata);
    params.set("from", from.toString());
    params.set("to", to.toString());
    params.set("interval", interval);
    const response = await httpBaggageService.get(resolveUrl({
        url: `/bag-on-belt-performance?${params}`,
        mockEndpoint: "/bagOnBeltPerformance",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
export async function getBaggageOnBeltPerformancePerFlight({ params: { iata, from, to }, }) {
    const params = new URLSearchParams("");
    const token = await getMSALToken();
    params.set("iata", iata);
    params.set("from", from.toString());
    params.set("to", to.toString());
    const response = await httpBaggageService.get(resolveUrl({
        url: `/bag-on-belt-performance-per-flight?${params}`,
        mockEndpoint: "/bagOnBeltPerformancePerFlight",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
}
