import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./DeIceCapacityBarChartWidget.scss";
import { useTranslation } from "react-i18next";
import Widget from "@components/Widget/Widget";
import { DeIceCapacityBarChart } from "@charts/apexcharts/DeIce";
import { DeIceCapacityControlPanel } from "../components";
import { DeIceHandlerFilter } from "../components/DeIceCapacityControlPanel/components";
export const DeIceCapacityBarChartWidget = ({ title, subTitle }) => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t(title), subtitle: t(subTitle) }), _jsxs(Widget.Body, { children: [_jsxs("div", { className: "deice-capacity-barchart-widget-body", children: [_jsx(DeIceHandlerFilter, {}), _jsx(DeIceCapacityControlPanel, {})] }), _jsx(DeIceCapacityBarChart, {})] })] }));
};
DeIceCapacityBarChartWidget.displayName = "DeIceCapacityBarChartWidget";
