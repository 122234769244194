export const filterABPlaneBodySizesWithoutRollover = (flight) => {
    return (flight.planeBodySize === "A" || flight.planeBodySize === "B") && !flight.rollover;
};
export const filterCPlaneBodySize = (flight) => {
    return flight.planeBodySize === "C";
};
export const filterDEFPlaneBodySizesWithWidebodyWithoutRollover = (flight) => {
    return ((flight.planeBodySize === "D" || flight.planeBodySize === "E" || flight.planeBodySize === "F") &&
        flight.widebody &&
        !flight.rollover);
};
export const filterDEFPlaneBodySizesWithWidebodyWithRollover = (flight) => {
    return ((flight.planeBodySize === "D" || flight.planeBodySize === "E" || flight.planeBodySize === "F") &&
        flight.widebody &&
        flight.rollover);
};
export const filterUnknownPlaneBodySize = (flight) => {
    return !flight.planeBodySize;
};
export const groupPlaneBodySize = (segments) => {
    return {
        abPlaneBodySizes: segments.map((segment) => segment.flights.filter(filterABPlaneBodySizesWithoutRollover).length),
        cPlaneBodySizes: segments.map((segment) => segment.flights.filter(filterCPlaneBodySize).length),
        dfPlaneBodySizes: segments.map((segment) => segment.flights.filter(filterDEFPlaneBodySizesWithWidebodyWithoutRollover).length),
        extraTime: segments.map((segment) => segment.flights.filter(filterDEFPlaneBodySizesWithWidebodyWithRollover).length),
        planeBodySizeUnknown: segments.map((segment) => segment.flights.filter(filterUnknownPlaneBodySize).length),
    };
};
export const getHandlerFlightCapacities = (segments, capacity) => {
    const handlerFlightCapacities = [
        ...segments
            .filter((segment) => new Date(segment.from).getHours() < 14)
            .map(() => capacity.morningFlightCapacity || null),
        ...segments
            .filter((segment) => new Date(segment.from).getHours() >= 14)
            .map(() => capacity.afternoonFlightCapacity || null),
    ];
    return handlerFlightCapacities;
};
