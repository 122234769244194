import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoICircleOutlined } from "@avinor/react-icons";
import HoursToFilterButtons from "@components/Buttons/HoursToFilterButtons/HoursToFilterButtons";
import { VStack, Wrapper } from "@components/Layout";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import BaggageEnrouteGroupedBarChart from "@charts/apexcharts/Baggage/Enroute/Grouped/BarChart";
import { UserDomains } from "@auth/hooks/useEmailDomain";
import { useIataContext } from "@contexts/IataContext";
export const BaggageEnroutePerFlightEstimateBarChartWidget = () => {
    const { iata } = useIataContext();
    const { t } = useTranslation();
    const [hoursTo, setHoursTo] = useState(4);
    const title = t("baggage.widgets.enroutePerFlightEstimateBarChart.title", { iata: iata });
    const description = t("baggage.widgets.enroutePerFlightEstimateBarChart.description", { iata: iata });
    return (_jsxs(Widget.Container, { children: [_jsxs(VStack, { children: [_jsx(Widget.Header, { title: title, subtitle: description, icon: {
                            component: (_jsx(InfoICircleOutlined, { width: "1rem", height: "1rem" })),
                            tooltip: {
                                html: `<h3>${title}</h3><p>${description}</p>`,
                            },
                        } }), _jsx(Wrapper, { children: _jsx(HoursToFilterButtons, { setHoursTo: setHoursTo }) })] }), _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsx(Widget.Body, { children: _jsx(BaggageEnrouteGroupedBarChart, { hoursTo: hoursTo }) }) })] }));
};
BaggageEnroutePerFlightEstimateBarChartWidget.displayName = "BaggageEnroutePerFlightEstimateBarChartWidget";
BaggageEnroutePerFlightEstimateBarChartWidget.permissions = { requiredDomain: UserDomains.Avinor };
