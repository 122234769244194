import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./DeIceVehiclesCalculationFormula.scss";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { HStack, VStack } from "@components/Layout";
const TextFaded = ({ text }) => (_jsx("span", { className: "deice-vehicles-calculation-formula__text-faded", children: text }));
const FormulaPart = ({ mainKey }) => {
    const { t } = useTranslation();
    return (_jsx("span", { className: `deice-vehicles-calculation-formula__${mainKey}`, children: t(`deice.capacity.controlPanel.formula.${mainKey}.text`) }));
};
const DeIceVehiclesCalculationFormula = () => {
    const { t } = useTranslation();
    const formulaParts = ["aircraft", "time", "vehicles"];
    return (_jsxs(HStack, { className: "deice-vehicles-calculation-formula", justify: "center", align: "center", children: [_jsxs(VStack, { gap: 0.5, children: [_jsx("div", { className: "deice-vehicles-calculation-formula__numerator", children: formulaParts.map((part, index) => (_jsxs(Fragment, { children: [index > 0 && _jsx("span", { className: "deice-vehicles-calculation-formula__multiplier", children: "\u00D7" }), _jsx(FormulaPart, { mainKey: part })] }, part))) }), _jsx(FormulaPart, { mainKey: "denominator" })] }), _jsxs(HStack, { className: "deice-vehicles-calculation-formula__quantity", align: "center", children: [_jsx(TextFaded, { text: "\u2248" }), _jsx("span", { className: "deice-vehicles-calculation-formula__result", children: "XX" }), _jsx(TextFaded, { text: t("deice.capacity.controlPanel.formula.result.textFaded") })] })] }));
};
export default DeIceVehiclesCalculationFormula;
