export const MapsStyling = [
    {
        featureType: "landscape",
        elementType: "all",
        stylers: [
            {
                color: "#f2f2f2",
            },
        ],
    },
    {
        featureType: "landscape",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#ffffff",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "all",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "all",
        stylers: [
            {
                saturation: -100,
            },
            {
                lightness: 45,
            },
        ],
    },
    {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#eeeeee",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#7b7b7b",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: "#ffffff",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "all",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
            {
                visibility: "on",
            },
            {
                color: "#ff9900",
            },
            {
                weight: "1.00",
            },
        ],
    },
    {
        featureType: "road.highway.controlled_access",
        elementType: "geometry.fill",
        stylers: [
            {
                visibility: "on",
            },
            {
                color: "#ff0000",
            },
            {
                weight: "3.00",
            },
        ],
    },
    {
        featureType: "transit",
        elementType: "all",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "transit.station.airport",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#0e00ff",
            },
            {
                visibility: "on",
            },
            {
                weight: "9.51",
            },
        ],
    },
    {
        featureType: "transit.station.airport",
        elementType: "geometry.stroke",
        stylers: [
            {
                visibility: "on",
            },
            {
                color: "#ff0000",
            },
        ],
    },
    {
        featureType: "transit.station.rail",
        elementType: "geometry.fill",
        stylers: [
            {
                visibility: "on",
            },
            {
                color: "#00ff54",
            },
            {
                weight: "8.43",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "all",
        stylers: [
            {
                color: "#46bcec",
            },
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#c8d7d4",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#070707",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: "#ffffff",
            },
        ],
    },
];
