import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { calculatePercentage, formatNumber } from "@utils/Utils";
import { Grid, VStack } from "@components/Layout";
import Progress from "@components/Progress/Progress";
import { ProgressCard } from "@components/ProgressCard/ProgressCard";
import Spinner from "@components/Spinner/Spinner";
import { breakpoints } from "@constants";
import { Granularity } from "@enums";
import { useMediaQuery } from "@hooks";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useIataContext } from "@contexts/IataContext";
import { getPaxPredictionCounts, getPaxSecurityCounts } from "../services";
const sumPassedPax = (actualPaxCounts) => {
    return actualPaxCounts.reduce((acc, { totalPassengers }) => acc + totalPassengers, 0);
};
const sumPlannedPax = (plannedPassengerCount) => {
    return plannedPassengerCount.reduce((acc, { count }) => acc + count, 0);
};
export const PaxThroughSecurity = () => {
    const { picker: { from, to }, } = useDatePickerContext();
    const { iata } = useIataContext();
    const { t } = useTranslation();
    const { data: actualPassengers, isLoading: isLoadingActual } = useQuery({
        queryFn: () => getPaxSecurityCounts({
            iata: iata,
            from,
            to,
            granularity: Granularity.Day,
        }),
        queryKey: ["getPaxSecurityCounts", { from, to, iata }],
    });
    const { data: plannedPassengers, isLoading: isLoadingPlanned } = useQuery({
        queryFn: () => getPaxPredictionCounts({
            iata: iata,
            from,
            to,
            granularity: Granularity.Day,
            area: "Security",
        }),
        queryKey: ["getPaxPredictionCounts", { from, to, iata }],
    });
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    const passedPax = sumPassedPax((actualPassengers === null || actualPassengers === void 0 ? void 0 : actualPassengers.passengerCounts) || []);
    const plannedPax = sumPlannedPax((plannedPassengers === null || plannedPassengers === void 0 ? void 0 : plannedPassengers.counts) || []);
    const progress = calculatePercentage(passedPax, plannedPax);
    if (isLoadingActual || isLoadingPlanned)
        return _jsx(Spinner, {});
    return (_jsxs(VStack, { gap: 0.5, children: [_jsx(Progress, { completedPercentage: progress > 100 ? 100 : progress, withPadding: true, fixedBackground: true }), _jsxs(Grid, { columns: 2, gap: isMobile ? 0.5 : 1, children: [_jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", title: t("pax.widgets.throughSecurity.paxPassed"), value: formatNumber(passedPax) }), _jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", title: t("pax.widgets.throughSecurity.paxForecasted"), value: formatNumber(plannedPax) })] })] }));
};
