import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./PlatformSpecificDeIceData.scss";
import { useState } from "react";
import ButtonGroup from "@components/Buttons/ButtonGroup/ButtonGroup";
import ErrorMessageWidget from "@components/ErrorMessageWidget/ErrorMessageWidget";
import Line from "@components/Layout/Line/Line";
import Widget from "@components/Widget/Widget";
import useDeIceData from "../hooks/useDeIceData";
import { DeIceAverageQueTime } from "./DeIceAverageQueTime";
import { DeIceAverageTimePerAircraft } from "./DeIceAverageTimePerAircraft";
import { DeIceMedianTimePerAircraft } from "./DeIceMedianTimePerAircraft";
import { DeIcePlatformMedianQueTime } from "./DeIcePlatformMedianQueTime";
import { findMostActivePlatform } from "./utils";
export const PlatformSpecificDeIceData = () => {
    const { data } = useDeIceData();
    const platformArr = data && data.platforms;
    const platformWithMostData = platformArr ? findMostActivePlatform(platformArr) : null;
    const [platformName, setPlatformName] = useState("Bravo Nord");
    const [platformIndex, setPlatformIndex] = useState(platformWithMostData !== null ? platformWithMostData : 2);
    if (!platformArr || platformArr.length < 1) {
        return _jsx(ErrorMessageWidget, { title: "De-ice plattform data" });
    }
    const deIceCompletedLast30 = platformArr[platformIndex].deIceCompleteLast30;
    const deIceCompletedLast60 = platformArr[platformIndex].deIceCompleteLast60;
    const deIceCompletedLast120 = platformArr[platformIndex].deIceCompleteLast120;
    const deIceExpectedNext30 = platformArr[platformIndex].deIceExpectedNext30;
    const deIceExpectedNext60 = platformArr[platformIndex].deIceExpectedNext60;
    const deIceExpectedNext120 = platformArr[platformIndex].deIceExpectedNext120;
    const buttons = [
        {
            id: 0,
            text: "Alfa Nord",
            onClick: () => {
                setPlatformIndex(0);
                setPlatformName("Alfa Nord");
            },
        },
        {
            id: 1,
            text: "Alfa Syd",
            onClick: () => {
                setPlatformIndex(1);
                setPlatformName("Alfa Syd");
            },
        },
        {
            id: 2,
            text: "Bravo Nord",
            onClick: () => {
                setPlatformIndex(2);
                setPlatformName("Bravo Nord");
            },
        },
    ];
    const Table = ({ text }) => {
        return (_jsx("div", { className: "platform-specific-deice-data__footer__wrapper__table", children: text.map((item, i) => (_jsx("span", { className: "platform-specific-deice-data__footer__wrapper__table__header-text", children: item }, "de-ice-platform-table" + i))) }));
    };
    return (_jsx(Widget.Container, { children: _jsx(Widget.Body, { children: _jsxs("div", { className: "platform-specific-deice-data", children: [_jsxs("div", { className: "platform-specific-deice-data__header", children: [_jsx(ButtonGroup, { active: platformIndex, buttons: buttons }), _jsx("h3", { className: "platform-specific-deice-data__header__title", children: platformName })] }), _jsx("div", { className: "platform-specific-deice-data__body", children: _jsxs("div", { className: "platform-specific-deice-data__body__wrapper", children: [_jsxs("div", { className: "platform-specific-deice-data__body__wrapper__row", children: [_jsx(DeIceAverageTimePerAircraft, { id: platformIndex }), _jsx(DeIceMedianTimePerAircraft, { id: platformIndex })] }), _jsx(Line, {}), _jsxs("div", { className: "platform-specific-deice-data__body__wrapper__row", children: [_jsx(DeIceAverageQueTime, { id: platformIndex }), _jsx(DeIcePlatformMedianQueTime, { id: platformIndex })] })] }) }), _jsx("div", { className: "platform-specific-deice-data__footer", children: _jsxs("div", { className: "platform-specific-deice-data__footer__wrapper", children: [_jsxs("div", { children: [_jsx(Table, { text: ["Siste 30", "Siste 60", "Siste 120"] }), _jsx(Line, {}), _jsx(Table, { text: [deIceCompletedLast30, deIceCompletedLast60, deIceCompletedLast120] })] }), _jsxs("div", { children: [_jsx(Table, { text: ["Neste 30", "Neste 60", "Neste 120"] }), _jsx(Line, {}), _jsx(Table, { text: [deIceExpectedNext30, deIceExpectedNext60, deIceExpectedNext120] })] })] }) })] }) }) }));
};
PlatformSpecificDeIceData.displayName = "PlatformSpecificDeIceData";
