import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { roundIfNeeded } from "@utils/Utils";
import Progress from "@components/Progress/Progress";
import Spinner from "@components/Spinner/Spinner";
import useRegularityAndPunctualityPeriodData from "../hooks/useRegularityAndPunctualityPeriodData";
export const RegularityProgressBar = ({ direction }) => {
    const { data, isLoading } = useRegularityAndPunctualityPeriodData({ paxOnly: false, direction });
    const { t } = useTranslation();
    const totalRegularity = data === null || data === void 0 ? void 0 : data.totalRegularity;
    const totalFlightsOperated = data === null || data === void 0 ? void 0 : data.totalFlightsOperated;
    const totalPlannedFlights = data === null || data === void 0 ? void 0 : data.totalFlights;
    if (isLoading)
        return _jsx(Spinner, {});
    return (_jsx(Progress, { completedPercentage: totalRegularity, text: t("common.progressStatusCompletedAndPlanned", {
            percent: roundIfNeeded(totalRegularity || 0),
            completed: totalFlightsOperated,
            planned: totalPlannedFlights,
        }), withPadding: true }));
};
RegularityProgressBar.displayName = "RegularityProgressBar";
