import { useQuery } from "@tanstack/react-query";
import { endOfDay } from "date-fns";
import startOfDay from "date-fns/startOfDay";
import { formatDate } from "@utils/DateUtils";
import { FormatPattern, Granularity } from "@enums";
import { useIataContext } from "@contexts/IataContext";
import { useUtcContext } from "@contexts/UtcContext";
import { useFlightMovementDataSource } from "../contexts/FlightMovementsDataSourceContext";
import { getFlightMovements } from "../services";
import { getFlightsBy } from "../utils";
const useFlightMovementsData = ({ date }) => {
    const { utc } = useUtcContext();
    const { dataSource } = useFlightMovementDataSource(date.from);
    const { iata } = useIataContext();
    const from = date.from;
    const to = date.to;
    const { data, error, isLoading } = useQuery({
        queryFn: () => getFlightMovements({
            params: {
                from: formatDate(utc, startOfDay(new Date(from)), FormatPattern.yyyy_MM_dd_HH_mm),
                to: formatDate(utc, endOfDay(new Date(to)), FormatPattern.yyyy_MM_dd_HH_mm),
                international: true,
                domestic: true,
                schengen: true,
                granularity: Granularity.Day,
                dataSource: dataSource,
                iata,
            },
        }),
        queryKey: ["getFlightMovements", { utc, iata, from, to, dataSource }],
    });
    if (data) {
        data.flightCounts = data.flightCounts.map((flightCount) => {
            return Object.assign({}, flightCount);
        });
    }
    const flightsData = {
        totalFlights: getFlightsBy("totalFlights", data !== null && data !== void 0 ? data : null),
        departureFlights: getFlightsBy("departure", data !== null && data !== void 0 ? data : null),
        arrivalFlights: getFlightsBy("arrival", data !== null && data !== void 0 ? data : null),
        domesticFlights: getFlightsBy("domesticTotal", data !== null && data !== void 0 ? data : null),
        internationalFlights: getFlightsBy("internationalTotal", data !== null && data !== void 0 ? data : null),
        domesticArrival: getFlightsBy("domesticArrival", data !== null && data !== void 0 ? data : null),
        domesticDeparture: getFlightsBy("domesticDeparture", data !== null && data !== void 0 ? data : null),
        internationalArrival: getFlightsBy("internationalArrival", data !== null && data !== void 0 ? data : null),
        internationalDeparture: getFlightsBy("internationalDeparture", data !== null && data !== void 0 ? data : null),
    };
    return Object.assign(Object.assign({}, flightsData), { data, error, date, iata, utc, isLoading });
};
export default useFlightMovementsData;
