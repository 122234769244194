import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import { BaggageEnrouteNonSchengen } from "../components";
export const BaggageEnrouteNonSchengenWidget = ({ title, subTitle }) => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t(title), subtitle: t(subTitle) }), _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsx(Widget.Body, { children: _jsx(BaggageEnrouteNonSchengen, {}) }) })] }));
};
BaggageEnrouteNonSchengenWidget.displayName = "BaggageEnrouteNonSchengenWidget";
