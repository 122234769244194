var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import "./Heading.scss";
import cl from "clsx";
import { forwardRef } from "react";
import { typoClassNames } from "../util";
/**
 * Part of a set of components for displaying text with consistent typography.
 *
 * @see [📝 Documentation](<apply-link>)
 * @see 🏷️ {@link HeadingProps}
 * @see [🤖 OverridableComponent](<apply-link>) support
 *
 * @example
 * ```jsx
 *     <Heading level="1" size="xlarge">
 *       Hva kan vi hjelpe deg med?
 *     </Heading>
 * ```
 */
export const Heading = 
// eslint-disable-next-line react/display-name
forwardRef((_a, ref) => {
    var { level = "1", size, className, as, spacing, align, visuallyHidden, textColor, weight } = _a, rest = __rest(_a, ["level", "size", "className", "as", "spacing", "align", "visuallyHidden", "textColor", "weight"]);
    const HeadingTag = as !== null && as !== void 0 ? as : `h${level}`;
    return (_jsx(HeadingTag, Object.assign({}, rest, { ref: ref, className: cl(className, "avinor-heading", `avinor-heading--${size}`, typoClassNames({
            spacing,
            align,
            visuallyHidden,
            textColor,
            weight,
        })) })));
});
export default Heading;
