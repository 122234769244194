import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import usePlanMetadata from "@features/Plan/hooks/usePlanMetadata";
import "./PlanOverviewDisruptionTooltip.scss";
export const PlanOverviewDisruptionTooltip = ({ disruption, id }) => {
    var _a;
    const { planMetaData } = usePlanMetadata();
    const { t } = useTranslation();
    const cause = (_a = planMetaData === null || planMetaData === void 0 ? void 0 : planMetaData.causesOfDisruptions.find((x) => x.id == disruption.disruptionTypeId)) === null || _a === void 0 ? void 0 : _a.disruptionName;
    const commentLines = disruption.comments
        .split(";")
        .map((comment) => comment.trim())
        .filter((comment) => comment.length > 0);
    return (_jsxs(Tooltip, { id: id, children: [_jsx("h3", { children: disruption.timePeriodFrom + " - " + disruption.timePeriodTo }), _jsxs("p", { className: "plan-overview-tooltip-item", children: [t("plan.create.disruptions.tooltip.status"), ":", " ", _jsx("span", { style: { fontWeight: "bold" }, children: disruption.someDisturbances
                            ? t("plan.create.disruptions.tooltip.someDisruptions")
                            : t("plan.create.disruptions.tooltip.majorDisruptions") })] }), cause && (_jsxs("p", { className: "plan-overview-tooltip-item", children: [t("plan.create.disruptions.tooltip.cause"), ":", " ", _jsx("span", { style: { fontWeight: "bold" }, children: t(`plan.create.disruptions.causes.${cause}`) })] })), commentLines.length > 0 && (_jsxs("div", { className: "plan-overview-tooltip-item", children: [_jsxs("p", { className: "plan-overview-tooltip-item", children: [t("plan.create.disruptions.tooltip.comment"), ":"] }), commentLines.map((comment, index) => (_jsxs("p", { style: { width: "100%" }, className: "plan-overview-tooltip-item", children: [_jsx("span", { children: comment }), _jsx("br", {})] }, index)))] }))] }));
};
