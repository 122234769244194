import { PaxThroughSecurityWidget } from "@features/Pax/widgets";
import { DefaultPermissions } from "@widgets/registry";
export const paxWidgets = [
    {
        name: PaxThroughSecurityWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 12],
        component: PaxThroughSecurityWidget.displayName,
        componentProps: {
            Component: PaxThroughSecurityWidget,
            minHeight: 170,
        },
        permissions: DefaultPermissions,
    },
];
