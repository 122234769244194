export var TimeScope;
(function (TimeScope) {
    TimeScope["Today"] = "Today";
    TimeScope["Forecast"] = "Forecast";
    TimeScope["History"] = "History";
})(TimeScope || (TimeScope = {}));
export var FlightMovementsDirection;
(function (FlightMovementsDirection) {
    FlightMovementsDirection["All"] = "All";
    FlightMovementsDirection["Arrival"] = "Arrival";
    FlightMovementsDirection["Departure"] = "Departure";
})(FlightMovementsDirection || (FlightMovementsDirection = {}));
