import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./Expandable.scss";
import ExpandButton from "../Buttons/ExpandButton/ExpandButton";
const Expandable = ({ children, isExpanded, onToggle, useDefaultButton = true, buttonText }) => {
    const expandedText = (buttonText === null || buttonText === void 0 ? void 0 : buttonText.expanded) || "expandedText";
    const collapsedText = (buttonText === null || buttonText === void 0 ? void 0 : buttonText.collapsed) || "collapsedText";
    const bodyClasses = !isExpanded ? "expandable__body" : "expandable__body expandable__body--expanded";
    const expandableAriaControls = "expandable-controls";
    const handleOnToggle = () => {
        if (onToggle)
            onToggle();
    };
    return (_jsxs("section", { className: "expandable", children: [_jsx("div", { className: bodyClasses, id: expandableAriaControls, children: _jsx("div", { className: "expandable__content", children: children }) }), useDefaultButton && (_jsx("div", { className: "expandable__button", children: _jsx(ExpandButton, { onExpand: handleOnToggle, isExpanded: isExpanded, buttonText: { expanded: expandedText, collapsed: collapsedText }, "aria-controls": expandableAriaControls }) }))] }));
};
export default Expandable;
