import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
const DeIceHasChangesContext = createContext(undefined);
export const DeIceHasChangesProvider = ({ children }) => {
    const [deIceHasChanges, setDeIceHasChanges] = useState(false);
    return (_jsx(DeIceHasChangesContext.Provider, { value: {
            deIceHasChanges,
            setDeIceHasChanges,
        }, children: children }));
};
export const useDeIceHasChangesContext = () => {
    const context = useContext(DeIceHasChangesContext);
    if (!context) {
        throw new Error("useDeIceHasChangesContext must be used within a DeIceHasChangesProvider");
    }
    return context;
};
