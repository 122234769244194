import authProvider from "./authProvider";
import { AuthScope } from "./constants";
export const getTokenRedirect = async (request) => {
    /**
     *  This is a workaround for a known issue with the MSAL library whe used in conjunction with Recoil.js.
     *
     * Error: Cannot update a component (MsalProvider) while rendering a different component.
     *
     * Related issue: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5796#issuecomment-1763461620
     *
     * Why this works:
     *
     * JavaScript's event loop handles asynchronous operations by using task queues. When a Promise is resolved or rejected, any of its callbacks are queued as microtasks.
     * These microtasks have higher priority than other tasks (like rendering or event handling) in the event loop and are executed immediately after the currently executing
     * script finishes and before the JavaScript engine has the chance to perform other tasks, such as rendering which is the source of this error in this case.
     **/
    await Promise.resolve();
    const isAuthenticated = authProvider.getActiveAccount() !== null;
    try {
        if (isAuthenticated) {
            const { accessToken } = await authProvider.acquireTokenSilent(request);
            return accessToken;
        }
    }
    catch (error) {
        // Error could be instanceof InteractionRequiredAuthError or ServerError with suberror == 'device_authentication_failed' or 'invalid_grant'.
        // In all cases, we will retry with force refresh. If that also fails, last resort is to redirect the user:
        console.error("Acquire token silently failed: " + error);
        try {
            const forceRefreshRequest = Object.assign(Object.assign({}, request), { forceRefresh: true });
            if (isAuthenticated) {
                const { accessToken } = await authProvider.acquireTokenSilent(forceRefreshRequest);
                return accessToken;
            }
        }
        catch (error) {
            console.error("Acquiring token silently with force refresh failed: " + error);
            console.log("Redirecting user...");
            await authProvider.acquireTokenRedirect(request);
        }
    }
};
export const createAuthRequest = (scopes) => {
    const request = {
        scopes: [...scopes],
        account: authProvider.getAllAccounts()[0],
    };
    return request;
};
export const getMSALToken = async () => {
    const request = createAuthRequest([AuthScope.AopAccessApi]);
    const token = await getTokenRedirect(request);
    return token;
};
