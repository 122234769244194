import { jsx as _jsx } from "react/jsx-runtime";
import "./ButtonGroup.scss";
import classNames from "classnames";
const styleSheet = {
    root: classNames("button-group"),
    buttons: (fullWidth) => classNames("button-group__buttons", {
        "button-group__buttons--full-width": fullWidth, // Use the prop to determine the class
    }),
    button: (active, first, last) => classNames("button-group__button", {
        "button-group__button--active": active,
        "button-group__button--left": first,
        "button-group__button--right": last,
    }),
};
const Button = ({ id, text, onClick, lastButton, active, disabled = false, icon }) => {
    return (_jsx("button", { className: styleSheet.button(active === id, id === 0, id === lastButton), onClick: onClick, disabled: disabled, children: icon ? icon : text }, id));
};
const ButtonGroup = ({ buttons, active, fullWidth = false, noWrap = false }) => {
    if (buttons === undefined) {
        return null;
    }
    return (_jsx("div", { className: styleSheet.root, children: _jsx("div", { className: styleSheet.buttons(fullWidth), style: noWrap ? { flexWrap: "nowrap" } : {}, children: buttons === null || buttons === void 0 ? void 0 : buttons.map((buttonProps) => {
                return (_jsx(Button, { disabled: buttonProps.disabled, id: buttonProps.id, text: buttonProps.text, icon: buttonProps.icon, onClick: buttonProps.onClick, active: active, lastButton: buttons.length - 1 }, buttonProps.id));
            }) }) }));
};
export default ButtonGroup;
