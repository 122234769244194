import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./Header.scss";
import AvinorLogoLink from "@components/AvinorLogo/AvinorLogoLink";
import LanguageSwitcher from "@components/LanguageSwitcher/LanguageSwitcher";
import ProfileName from "@components/ProfileName/ProfileName";
import { breakpoints } from "@constants";
import SignOutButton from "@auth/components/SignOutButton/SignOutButton";
import { useSignOut } from "@auth/hooks/useSignOut";
import { useMediaQuery } from "@hooks";
import { ToggleNavigationButton } from "../Buttons/ToggleNavigationButton/ToggleNavigationButton";
const Header = () => {
    const { isAuthenticated } = useSignOut();
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    return (_jsxs("div", { className: "site-header-wrapper", children: [_jsxs("div", { className: "site-header-wrapper__top", children: [_jsx(AvinorLogoLink, { to: "/" }), _jsx("span", { className: "site-header-wrapper__top__text", children: "Airport Operations Plan (AOP)" })] }), isMobile ? (_jsxs("div", { className: "site-header-wrapper__right-items", children: [_jsx(LanguageSwitcher, {}), isAuthenticated && _jsx(ToggleNavigationButton, {})] })) : (_jsxs("div", { className: "site-header-wrapper__right-items", children: [_jsx(ProfileName, {}), _jsx(LanguageSwitcher, {}), _jsx(SignOutButton, {})] }))] }));
};
export default Header;
