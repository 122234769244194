import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import "./Pagination.scss";
const Pagination = ({ pagination, data, style }) => {
    if (!data || !pagination)
        return null;
    const { state, fns } = pagination;
    const { start, end } = state.getPageBoundaries(data.nodes);
    return (_jsx("nav", { className: "pagination", style: style, children: _jsxs("ul", { className: "pagination__control", children: [_jsxs("li", { className: "pagination__label", children: [start === end ? start : `${start}-${end}`, " av ", data.nodes.length] }), state.getPages(data.nodes).map((_, index) => (_jsx("li", { children: _jsx("button", { className: `pagination__button ${state.page === index ? "pagination__button--active" : ""}`, type: "button", onClick: () => fns.onSetPage(index), children: index + 1 }) }, index)))] }) }));
};
export default Pagination;
