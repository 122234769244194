import { addDays, isFuture, isPast, isToday, subDays } from "date-fns";
import { t } from "i18next";
import { Page } from "@enums";
export const handleFiveDayReportText = () => {
    const today = new Date().toLocaleDateString("nb-NO", {
        month: "2-digit",
        day: "2-digit",
    });
    const fourDaysBack = new Date(new Date().setDate(new Date().getDate() - 4)).toLocaleDateString("nb-NO", {
        month: "2-digit",
        day: "2-digit",
    });
    return `(${fourDaysBack.substring(0, fourDaysBack.length - 1).replace(".", "/")} - ${today
        .substring(0, today.length - 1)
        .replace(".", "/")})`;
};
export const getWidgetsByTimeScope = (widgetGroups, from) => {
    const timescope = () => {
        if (isPast(from) && !isToday(from)) {
            return "historical";
        }
        else if (isFuture(from)) {
            return "forecast";
        }
        else {
            return "today";
        }
    };
    return widgetGroups[timescope()] || [];
};
/**
 * Returns the select options for a given page. The options are used to populate the datepicker's select dropdown.
 */
export const getDatePickerSelectOptionsForPage = (page) => {
    const selectOptions = {
        [Page.Baggage]: [
            { value: "custom", label: t("datePickerPresets.custom") },
            {
                value: JSON.stringify({ from: subDays(new Date(), 1), to: subDays(new Date(), 1) }),
                label: t("datePickerPresets.yesterday"),
            },
            {
                value: JSON.stringify({ from: new Date(), to: new Date() }),
                label: t("datePickerPresets.today"),
            },
            {
                value: JSON.stringify({ from: subDays(new Date(), 5), to: subDays(new Date(), 1) }),
                label: t("datePickerPresets.last5days"),
            },
        ],
        [Page.DeIce]: [
            { value: "custom", label: t("datePickerPresets.custom") },
            {
                value: JSON.stringify({ from: subDays(new Date(), 1), to: subDays(new Date(), 1) }),
                label: t("datePickerPresets.yesterday"),
            },
            {
                value: JSON.stringify({ from: new Date(), to: new Date() }),
                label: t("datePickerPresets.today"),
            },
            {
                value: JSON.stringify({ from: addDays(new Date(), 1), to: addDays(new Date(), 1) }),
                label: t("datePickerPresets.tomorrow"),
            },
        ],
        [Page.Delays]: [
            { value: "custom", label: t("datePickerPresets.custom") },
            {
                value: JSON.stringify({ from: subDays(new Date(), 1), to: subDays(new Date(), 1) }),
                label: t("datePickerPresets.yesterday"),
            },
            {
                value: JSON.stringify({ from: new Date(), to: new Date() }),
                label: t("datePickerPresets.today"),
            },
        ],
        [Page.Pax]: [
            { value: "custom", label: t("datePickerPresets.custom") },
            {
                value: JSON.stringify({ from: subDays(new Date(), 1), to: subDays(new Date(), 1) }),
                label: t("datePickerPresets.yesterday"),
            },
            {
                value: JSON.stringify({ from: new Date(), to: new Date() }),
                label: t("datePickerPresets.today"),
            },
        ],
        [Page.PunctualityRegularity]: [
            { value: "custom", label: t("datePickerPresets.custom") },
            {
                value: JSON.stringify({ from: subDays(new Date(), 1), to: subDays(new Date(), 1) }),
                label: t("datePickerPresets.yesterday"),
            },
            {
                value: JSON.stringify({ from: new Date(), to: new Date() }),
                label: t("datePickerPresets.today"),
            },
        ],
    };
    return selectOptions[page];
};
