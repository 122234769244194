import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import ReactDOMServer from "react-dom/server";
import { Tooltip } from "react-tooltip";
import AlertIndicator from "@features/AirportTransportation/components/AlertIndicator/AlertIndicator";
export const ThresholdValues = {
    InboundTaxiTime: {
        good: "<6",
        warning: "6-8",
        alarm: ">8",
    },
    OutboundTaxiTime: {
        good: "<12",
        warning: "12-14",
        alarm: ">14",
    },
    StartupDelay: {
        good: "<2",
        warning: "2-4",
        alarm: ">4",
    },
    Percentage: {
        good: ">90",
        warning: "80-90",
        alarm: "<80",
    },
};
export const OTPThresholdsTooltip = ({ value, valueSuffix, thresholdValues, id, evaluatingFunction, }) => {
    return (_jsxs(_Fragment, { children: [_jsxs("span", { className: "OTP-table-timeframe-" + evaluatingFunction(value), "data-tooltip-id": id, "data-tooltip-html": ReactDOMServer.renderToStaticMarkup(OTPThresholdsTooltipContent(thresholdValues)), "data-tooltip-place": "left", "data-tooltip-position-strategy": "fixed", children: [value, " ", valueSuffix] }), _jsx(Tooltip, { id: id })] }));
};
export const OTPThresholdsTooltipContent = ({ good, warning, alarm }) => {
    const styles = {
        container: {
            display: "grid",
            gridTemplateColumns: "auto min-content",
            alignItems: "left",
            columnGap: "0.5rem",
        },
        threshold: {
            display: "flex",
            gridTemplateColumns: "min-content auto",
            alignItems: "center",
            gap: "0.5rem",
        },
    };
    // Data array to iterate over
    const thresholds = [
        { variant: "good", label: "Good", value: good },
        { variant: "warning", label: "Warning", value: warning },
        { variant: "alarm", label: "Alarm", value: alarm },
    ];
    return (_jsxs(_Fragment, { children: [_jsx("h3", { children: "Thresholds" }), _jsx("div", { style: styles.container, children: thresholds.map(({ variant, label, value }) => (_jsxs(_Fragment, { children: [_jsxs("div", { style: styles.threshold, children: [_jsx(AlertIndicator, { variant: variant }), _jsx("span", { children: value })] }), _jsx("div", { style: { textAlign: "left" }, children: _jsx("span", { children: label }) })] }))) })] }));
};
export default OTPThresholdsTooltip;
