var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./Checkbox.scss";
import clsx from "clsx";
import { useContext } from "react";
import { CheckboxGroupContext } from "./CheckboxGroupContext";
const Checkbox = (_a) => {
    var _b;
    var { id, label, className, variant = "large", defaultChecked = false, onCheckChange } = _a, restProps = __rest(_a, ["id", "label", "className", "variant", "defaultChecked", "onCheckChange"]);
    const context = useContext(CheckboxGroupContext);
    if (!context) {
        throw new Error("Checkbox must be used within a CheckboxGroup");
    }
    const { checkedItems, toggleItem } = context;
    const checkmarkClass = clsx("checkmark", {
        large: variant === "large",
        small: variant === "small",
    });
    const handleChange = (e) => {
        const { checked } = e.target;
        toggleItem(id);
        if (onCheckChange)
            onCheckChange(id, checked);
    };
    const isChecked = (_b = checkedItems[id]) !== null && _b !== void 0 ? _b : defaultChecked;
    return (_jsxs("label", { htmlFor: id, className: clsx("checkbox-container", className), children: [label, _jsx("input", Object.assign({ id: id, type: "checkbox", checked: isChecked, onChange: handleChange }, restProps)), _jsx("span", { className: checkmarkClass })] }));
};
export default Checkbox;
