import { useEffect, useState } from "react";
export const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);
    useEffect(() => {
        const media = window.matchMedia(query);
        const listener = () => setMatches(media.matches);
        window.addEventListener("resize", listener);
        setMatches(media.matches);
        return () => window.removeEventListener("resize", listener);
    }, [query]);
    return matches;
};
