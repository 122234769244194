var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import "./Wrapper.scss";
import clsx from "clsx";
import { forwardRef } from "react";
// eslint-disable-next-line react/display-name
export const Wrapper = forwardRef((_a, ref) => {
    var { as: Component = "div", children, className, padding = "medium", style: _style } = _a, rest = __rest(_a, ["as", "children", "className", "padding", "style"]);
    const style = Object.assign({}, _style);
    return (_jsx(Component, Object.assign({}, rest, { ref: ref, style: style, className: clsx("wrapper", {
            "wrapper--none": padding === "none",
            "wrapper--small": padding === "small",
            "wrapper--medium": padding === "medium",
            "wrapper--large": padding === "large",
        }, className), children: children })));
});
