import { FlightMovementsTodayWidget, FlightMovementsDomesticWidget, FlightMovementsInternationalWidget, } from "@features/FlightMovements/widgets";
import { FlightMovementsBarChartWidget } from "@features/FlightMovements/widgets";
import { PunctualityProgressBarWidget, RegularityProgressBarWidget } from "@features/OnTimePerformance/widgets";
import { DefaultPermissions } from "@widgets/registry";
export const flightMovementsWidgets = [
    {
        name: PunctualityProgressBarWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 6, 6],
        component: PunctualityProgressBarWidget.displayName,
        componentProps: {
            minHeight: 160,
        },
        permissions: DefaultPermissions,
    },
    {
        name: RegularityProgressBarWidget.displayName,
        gridRow: [2, 1, 1],
        gridColumn: [1, 7, 7],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 6, 6],
        component: RegularityProgressBarWidget.displayName,
        componentProps: {
            Component: RegularityProgressBarWidget,
            minHeight: 160,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FlightMovementsTodayWidget.displayName,
        gridRow: [3, 2, 2],
        gridColumn: [1, 1, 1],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: FlightMovementsTodayWidget.displayName,
        componentProps: {
            minHeight: 306,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FlightMovementsDomesticWidget.displayName,
        gridRow: [4, 3, 2],
        gridColumn: [1, 1, 5],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: FlightMovementsDomesticWidget.displayName,
        componentProps: {
            minHeight: 306,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FlightMovementsInternationalWidget.displayName,
        gridRow: [5, 4, 2],
        gridColumn: [1, 1, 9],
        rowSpan: [1, 1, 1],
        columnSpan: [12, 12, 4],
        component: FlightMovementsInternationalWidget.displayName,
        componentProps: {
            minHeight: 306,
        },
        permissions: DefaultPermissions,
    },
    {
        name: FlightMovementsBarChartWidget.displayName,
        gridRow: [5, 5, 3],
        gridColumn: [1, 1, 1],
        rowSpan: [2, 2, 2],
        columnSpan: [12, 12, 12],
        component: FlightMovementsBarChartWidget.displayName,
        componentProps: {
            title: "Flybevegelser i dag",
            subTitle: "Avganger og ankomst",
            screenMode: "FULLSCREEN_MODE",
            minHeight: 515,
        },
        permissions: DefaultPermissions,
    },
];
