import { useQuery } from "@tanstack/react-query";
import { subHours } from "date-fns";
import { formatDate } from "@utils/DateUtils";
import { FormatPattern } from "@enums";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useIataContext } from "@contexts/IataContext";
import { evaluateAlertIndicatorLevel } from "../components/OnTimePerformanceServiceQuality/OnTimePerformanceServiceQualityTableUtils";
import { getRegularityAndPunctualityPeriod } from "../services";
const useRegularityAndPunctualityPeriodData = ({ paxOnly = true, cargoOnly = false, direction, from, to, }) => {
    const { picker: { from: fromDatePicker, to: toDatePicker }, } = useDatePickerContext();
    const fromDate = from || fromDatePicker;
    const toDate = to || toDatePicker;
    const { iata } = useIataContext();
    const { data, error, isLoading } = useQuery({
        queryFn: () => getRegularityAndPunctualityPeriod({
            iata,
            from: formatDate(false, fromDate, FormatPattern.yyyy_MM_dd_HH_mm),
            to: formatDate(false, toDate, FormatPattern.yyyy_MM_dd_HH_mm),
            paxOnly,
            cargoOnly,
            direction,
        }),
        queryKey: ["getRegularityAndPunctualityPeriod", { fromDate, toDate, paxOnly, cargoOnly, direction, iata }],
    });
    const indicatorLevel = evaluateAlertIndicatorLevel(Math.floor((data === null || data === void 0 ? void 0 : data.totalPunctuality) || 0));
    return { data, error, isLoading, indicatorLevel };
};
export default useRegularityAndPunctualityPeriodData;
export const useArrivalPunctuality = () => {
    const { data, isLoading } = useRegularityAndPunctualityPeriodData({
        from: formatDate(false, subHours(new Date(), 1), FormatPattern.yyyy_MM_dd_HH_mm),
        to: formatDate(false, new Date(), FormatPattern.yyyy_MM_dd_HH_mm),
        direction: "A",
    });
    const indicatorLevel = evaluateAlertIndicatorLevel(Math.floor((data === null || data === void 0 ? void 0 : data.totalPunctuality) || 0));
    return { data, isLoading, indicatorLevel };
};
export const useDeparturePunctuality = () => {
    const { data, isLoading } = useRegularityAndPunctualityPeriodData({
        from: formatDate(false, subHours(new Date(), 1), FormatPattern.yyyy_MM_dd_HH_mm),
        to: formatDate(false, new Date(), FormatPattern.yyyy_MM_dd_HH_mm),
        direction: "D",
    });
    const indicatorLevel = evaluateAlertIndicatorLevel(Math.floor((data === null || data === void 0 ? void 0 : data.totalPunctuality) || 0));
    return { data, isLoading, indicatorLevel };
};
