export const mapHandlerToName = (handler) => {
    const airlineInfoMap = {
        Menzies: "MEN",
        SGH: "SGH",
        WGH: "WGH",
        Menzies_SGH: "SGH + MEN",
        ALL: "ALL",
        OTHER: "OTHER",
    };
    return airlineInfoMap[handler];
};
