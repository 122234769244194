import { jsx as _jsx } from "react/jsx-runtime";
import { Cell } from "@table-library/react-table-library";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import Spinner from "@components/Spinner/Spinner";
import Table from "@components/Table/Table";
import { addIdToObj } from "@components/Table/utils";
import { getBaggageAndPaxEnroute } from "@features/Baggage/services";
import { useIataContext } from "@contexts/IataContext";
const tableColumnHeaders = {
    timePeriod: "",
    total: "baggage.widgets.enrouteTable.total",
    domestic: "baggage.widgets.enrouteTable.domestic",
    schengen: "baggage.widgets.enrouteTable.schengen",
    nonSchengen: "baggage.widgets.enrouteTable.nonSchengen",
};
const headers = Object.keys(tableColumnHeaders);
export const PaxEnrouteTable = () => {
    const { iata } = useIataContext();
    const { t } = useTranslation();
    const { data: bagsAndPaxEnroute, isLoading } = useQuery({
        queryFn: () => getBaggageAndPaxEnroute({
            iata,
        }),
        queryKey: ["getBaggageAndPaxEnroute", { iata }],
    });
    const paxEnroute = (bagsAndPaxEnroute === null || bagsAndPaxEnroute === void 0 ? void 0 : bagsAndPaxEnroute.paxPeriods) || [];
    const getTableColumnHeaders = () => {
        return [
            {
                headers: headers.map((header, index) => ({
                    key: header,
                    content: t(tableColumnHeaders[header]),
                    cellProps: { weight: index === 0 ? 1.5 : 1 },
                })),
            },
        ];
    };
    const customCellRenderers = {
        timePeriod: ({ cellIndex, value }) => {
            let translationKey = value;
            if (value.toLowerCase() == "neste time")
                translationKey = "timePeriod.nextHour";
            if (value.toLowerCase() == "neste 2 timer")
                translationKey = "timePeriod.next2Hours";
            if (value.toLowerCase() == "neste 4 timer")
                translationKey = "timePeriod.next4Hours";
            return _jsx(Cell, { children: t(translationKey) }, cellIndex);
        },
    };
    if (isLoading)
        return _jsx(Spinner, {});
    if (!paxEnroute)
        return _jsx(NoDataAvailable, {});
    return (_jsx(Table, { data: addIdToObj(paxEnroute), headerGroups: getTableColumnHeaders(), cellRenderers: customCellRenderers, useDesignsystemetTheme: true }));
};
