import { useQuery } from "@tanstack/react-query";
import { subHours } from "date-fns";
import { formatDate } from "@utils/DateUtils";
import { FormatPattern } from "@enums";
import { useIataContext } from "@contexts/IataContext";
import { evaluateAlertIndicatorLevelRecoveredDelay } from "../components/OnTimePerformanceServiceQuality/OnTimePerformanceServiceQualityTableUtils";
import { getRecoverDelay } from "../services";
export const useRecoverDelayData = (props) => {
    const { from, to } = props;
    const { iata } = useIataContext();
    const { data, isLoading } = useQuery({
        queryFn: () => getRecoverDelay({
            iata,
            from,
            to,
        }),
        queryKey: ["getRecoverDelay", { iata, from, to }],
    });
    return { data, isLoading };
};
export const useRecoveredDelay = () => {
    const { data, isLoading } = useRecoverDelayData({
        from: formatDate(false, subHours(new Date(), 3), FormatPattern.yyyy_MM_dd_HH_mm),
        to: formatDate(false, new Date(), FormatPattern.yyyy_MM_dd_HH_mm),
    });
    const indicatorLevel = evaluateAlertIndicatorLevelRecoveredDelay((data === null || data === void 0 ? void 0 : data.averageRecoverDelay) ? Math.round(data.averageRecoverDelay) : 0);
    return { data, isLoading, indicatorLevel };
};
