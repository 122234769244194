import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { ProgressCard } from "@components/ProgressCard/ProgressCard";
import Spinner from "@components/Spinner/Spinner";
import useFacilityStatusListData from "../hooks/useFacilityStatusListData";
import useFacilityStatusOverviewData from "../hooks/useFacilityStatusOverviewData";
export const FacilityOpenCases = () => {
    var _a;
    const { t } = useTranslation();
    const { data: facilityStatusOverview, isLoading: isLoadingOverview } = useFacilityStatusOverviewData();
    const { data: facilityStatusList, isLoading: isLoadingList } = useFacilityStatusListData();
    const criticalCases = (_a = facilityStatusList === null || facilityStatusList === void 0 ? void 0 : facilityStatusList.facilities) === null || _a === void 0 ? void 0 : _a.filter((facility) => {
        return facility.casePriority.includes("1");
    });
    const { OpenCases } = facilityStatusOverview || {
        OpenCases: "N/A",
    };
    if (isLoadingOverview || isLoadingList)
        return _jsx(Spinner, {});
    return (_jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", value: `${OpenCases} (${criticalCases === null || criticalCases === void 0 ? void 0 : criticalCases.length} ${t("facility.casePriorities.critical").toLowerCase()})` }));
};
