import { jsx as _jsx } from "react/jsx-runtime";
import "./ToggleNavigationButton.scss";
import { useEffect } from "react";
import { BarsOutlined, CloseOutlined } from "@avinor/react-icons";
import { useMenuContext } from "@contexts/MenuContext";
export const ToggleNavigationButton = () => {
    const { menuOpen, setMenuOpen } = useMenuContext();
    const preventTouchMove = (e) => {
        e.preventDefault();
    };
    const allowNavScroll = (e) => {
        e.stopPropagation();
    };
    useEffect(() => {
        const nav = document.querySelector(".nav");
        if (menuOpen) {
            document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
            nav === null || nav === void 0 ? void 0 : nav.addEventListener("touchmove", allowNavScroll);
        }
        else {
            document.body.removeEventListener("touchmove", preventTouchMove);
            nav === null || nav === void 0 ? void 0 : nav.removeEventListener("touchmove", allowNavScroll);
        }
        return () => {
            document.body.removeEventListener("touchmove", preventTouchMove);
            nav === null || nav === void 0 ? void 0 : nav.removeEventListener("touchmove", allowNavScroll);
        };
    }, [menuOpen]);
    const Icon = menuOpen ? CloseOutlined : BarsOutlined;
    return (_jsx("button", { onClick: () => setMenuOpen((prev) => !prev), className: "toggle-navigation-button", children: _jsx(Icon, { color: "var(--avinor-white)" }) }));
};
