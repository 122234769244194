import { jsx as _jsx } from "react/jsx-runtime";
import { getDateToReadableText } from "@utils/DateUtils";
import { WidgetCard } from "@components/WidgetCard/WidgetCard";
import useDeIceData from "../hooks/useDeIceData";
export const DeIcePlatformMedianQueTime = ({ id }) => {
    const { data: deIceData } = useDeIceData();
    const deIceMedianQueueTime = deIceData && deIceData.platforms[id].deIceMedianQueueTime;
    return (_jsx(WidgetCard, { title: "K\u00F8tid plattform (median)", description: getDateToReadableText(), error: !deIceMedianQueueTime, children: deIceMedianQueueTime }));
};
DeIcePlatformMedianQueTime.displayName = "DeIcePlatformMedianQueTime";
