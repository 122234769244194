import { useMsal } from "@azure/msal-react";
//Add the domains of the external users here, when needed.
export var UserDomains;
(function (UserDomains) {
    UserDomains["Avinor"] = "avinor.no";
    UserDomains["Unknown"] = "unknown";
})(UserDomains || (UserDomains = {}));
// Returns the current user's email domain
const useEmailDomain = () => {
    const { accounts } = useMsal();
    const account = accounts[0];
    const email = account === null || account === void 0 ? void 0 : account.username;
    // Extract domain from email
    const domain = email ? email.split("@")[1] : UserDomains.Unknown;
    // Determine if the domain matches a known domain
    const userDomain = Object.values(UserDomains).includes(domain)
        ? domain
        : UserDomains.Unknown;
    return { domain: userDomain };
};
export default useEmailDomain;
