import { useMutation, useQueryClient } from "@tanstack/react-query";
import { formatDate } from "@utils/DateUtils";
import { FormatPattern } from "@enums";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useIataContext } from "@contexts/IataContext";
import { postDeIceCapacityAndPadConfiguration } from "../services";
export const useSubmitDeIceCapacityAndPadConfig = () => {
    const queryClient = useQueryClient();
    const { iata: iataCode } = useIataContext();
    const { picker: { from }, } = useDatePickerContext();
    const deIceDate = formatDate(true, from, FormatPattern.yyyy_MM_dd);
    const mutation = useMutation({
        mutationFn: ({ capacity, pads }) => postDeIceCapacityAndPadConfiguration({
            body: {
                iata: iataCode,
                date: deIceDate,
                handlerCapacity: capacity,
                pads: pads,
            },
        }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["getPadConfiguration"] });
            queryClient.invalidateQueries({ queryKey: ["getDeIcePlan"] });
            queryClient.invalidateQueries({ queryKey: ["getDeIceCapacity"] });
        },
        onError: (error) => {
            console.error(`postDeIceCapacity() ERROR \n${error}`);
        },
    });
    const submitDeIceCapacityAndPadConfig = (capacity, pads) => {
        mutation.mutate({ capacity, pads });
    };
    const resetMutation = () => {
        mutation.reset();
    };
    return {
        submitDeIceCapacityAndPadConfig,
        operationStatus: mutation.status, // 'idle' | 'loading' | 'error' | 'success'
        error: mutation.error,
        resetMutation: resetMutation,
    };
};
