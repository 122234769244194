import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { MenuProvider } from "@contexts/MenuContext";
import { ScreenModeProvider } from "@contexts/ScreenModeContext";
import AuthenticatedApp from "./AuthenticatedApp";
import UnauthenticatedApp from "./UnauthenticatedApp";
const App = ({ pca }) => {
    return (_jsx(MsalProvider, { instance: pca, children: _jsx(MenuProvider, { children: _jsxs(ScreenModeProvider, { children: [_jsx(AuthenticatedTemplate, { children: _jsx(AuthenticatedApp, {}) }), _jsx(UnauthenticatedTemplate, { children: _jsx(UnauthenticatedApp, {}) })] }) }) }));
};
export default App;
