import { jsx as _jsx } from "react/jsx-runtime";
import { isPast, isToday } from "date-fns";
import { useTranslation } from "react-i18next";
import { getDateToReadableText } from "@utils/DateUtils";
import { Page } from "@enums";
import { widgetDelay } from "@widgets/registry";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { DelayCodeMetaDataProvider } from "@contexts/DelayCodeMetaDataContext";
import { useIataContext } from "@contexts/IataContext";
import PageLayout from "./PageLayout/PageLayout";
import { getDatePickerSelectOptionsForPage } from "./utils";
const DelaysPage = () => {
    const { iata } = useIataContext();
    const { t } = useTranslation();
    const { picker: { id }, } = useDatePickerContext();
    return (_jsx(DelayCodeMetaDataProvider, { children: _jsx(PageLayout, { customDatePickerProps: {
                id,
                selectProps: {
                    options: getDatePickerSelectOptionsForPage(Page.Delays),
                },
                datePickerProps: {
                    shouldDisableDate: (date) => {
                        return !isPast(date) && !isToday(date);
                    },
                },
            }, title: `${t("pages.delays.title")} ${getDateToReadableText().toLowerCase()}`, description: t("pages.delays.description", { iata: iata }), widgets: widgetDelay }) }));
};
export default DelaysPage;
