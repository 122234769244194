import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Cell } from "@table-library/react-table-library";
import Table from "@components/Table/Table";
import { addIdToObj } from "@components/Table/utils";
import { useRecoveredDelay } from "@features/OnTimePerformance/hooks/useRecoverDelay";
import { OnTimePerformanceTimeframeText, PerformanceMetricCell, headerGroups, } from "./OnTimePerformanceServiceQualityTableUtils";
const cellRenderers = {
    recoveryDelay: ({ cellIndex, value, gridColumnStart, gridColumnEnd }) => {
        return (_jsxs(Cell, { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, className: "OTP-table-performance-cell", children: [value, " min"] }, cellIndex));
    },
    occupationRatio: ({ cellIndex, value, gridColumnStart, gridColumnEnd }) => {
        return (_jsxs(Cell, { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, className: "OTP-table-performance-cell", children: [value ? Math.floor(value) : "-", " %"] }, cellIndex));
    },
    performanceMetrics: PerformanceMetricCell,
};
export const OnTimePerformanceServiceQualityTurnaroundTable = () => {
    const { data: recoverDelay } = useRecoveredDelay();
    const nodes = [
        {
            performanceMetrics: {
                content: (_jsx(OnTimePerformanceTimeframeText, { text: "Recovered delay (Average)", timeFrame: "last 3 h avg." })),
                tooltip: {
                    id: "recovereddelayaverage",
                    title: "Recovered delay (Average)",
                    description: "Minutes recovered from delayed arrival to new departure - average last 3 hours",
                },
            },
            recoveryDelay: (recoverDelay === null || recoverDelay === void 0 ? void 0 : recoverDelay.averageRecoverDelay) ? Math.round(recoverDelay.averageRecoverDelay) : "-",
        },
    ];
    return (_jsx(Table, { data: addIdToObj(nodes), headerGroups: headerGroups(), headerVisibility: "hidden", cellRenderers: cellRenderers, customTheme: {
            Cell: `
                    text-align: start;

                    & > div {
                        margin-left: 0.5rem;
                    }
                `,
        } }));
};
