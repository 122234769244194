import { useMsal } from "@azure/msal-react";
export var Roles;
(function (Roles) {
    Roles["Admin"] = "Aop.Admin";
    Roles["PlanWriter"] = "Aop.Write.Plan";
})(Roles || (Roles = {}));
const useRoles = () => {
    var _a;
    const { accounts } = useMsal();
    const account = accounts[0];
    const roles = ((_a = account === null || account === void 0 ? void 0 : account.idTokenClaims) === null || _a === void 0 ? void 0 : _a.roles) || [];
    const hasAdminRole = roles.some((role) => role === Roles.Admin);
    const hasPlanWriteRole = roles.some((role) => role === Roles.PlanWriter || role === Roles.Admin);
    return { roles, hasAdminRole, hasPlanWriteRole };
};
export default useRoles;
