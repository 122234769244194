import { useQuery } from "@tanstack/react-query";
import { formatDate } from "@utils/DateUtils";
import { FormatPattern } from "@enums";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useIataContext } from "@contexts/IataContext";
import { useUtcContext } from "@contexts/UtcContext";
import { getDeIceData } from "../services";
const useDeIceData = () => {
    const { iata } = useIataContext();
    const { utc } = useUtcContext();
    const { picker: { from }, } = useDatePickerContext();
    const { data, error } = useQuery({
        queryFn: () => getDeIceData({
            iata,
            date: formatDate(utc, from, FormatPattern.yyyy_MM_dd),
        }),
        queryKey: ["getDeIceData", { from, utc, iata }],
    });
    return { data, error, from, utc, iata };
};
export default useDeIceData;
