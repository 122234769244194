import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, NativeSelect, Radio, Textarea } from "@digdir/designsystemet-react";
import { Cell } from "@table-library/react-table-library";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { prettyPrintDate } from "@utils/DateUtils";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import Spinner from "@components/Spinner/Spinner";
import Table from "@components/Table/Table";
import { alignCellCenterClass } from "@components/Table/themes";
import { addIdToObj } from "@components/Table/utils";
import "./PlanCreateTable.scss";
import usePlanMetadata from "@features/Plan/hooks/usePlanMetadata";
const tableColumnHeaders = {
    timePeriodFrom: "",
    normalOperation: "plan.create.fillInProcess.normalOperation",
    someDisturbances: "plan.create.fillInProcess.someDisturbances",
    majorDisturbances: "plan.create.fillInProcess.majorDisturbances",
    cause: "plan.create.fillInProcess.cause",
    comments: "plan.create.fillInProcess.comments",
};
const getDomainName = (email) => {
    const parts = email.split("@");
    if (parts.length !== 2)
        return "";
    const domainParts = parts[1].split(".");
    if (domainParts.length < 2 || !domainParts[0])
        return "";
    return domainParts[0].charAt(0).toUpperCase() + domainParts[0].slice(1);
};
const headers = Object.keys(tableColumnHeaders);
export const PlanCreateTable = ({ currentPlan, handlePlanDisruptionsChange, isLoading, setPlanHasChanges, }) => {
    const planExists = currentPlan.updatedBy !== "";
    const { planMetaData, isLoadingMetadata } = usePlanMetadata();
    const [loadingDisruptions, setLoadingDisruptions] = useState(true);
    const [planDisruptions, setPlanDisruptions] = useState([]);
    const { t, i18n } = useTranslation();
    useEffect(() => {
        if (currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.disruptions) {
            setPlanDisruptions(currentPlan.disruptions);
        }
        setLoadingDisruptions(false);
    }, [currentPlan]);
    const handleRadioChange = (index, key) => {
        const updatedPlanDisruptions = planDisruptions.map((disruption, i) => (Object.assign(Object.assign({}, disruption), { normalOperation: i === index ? key === "normalOperation" : disruption.normalOperation, someDisturbances: i === index ? key === "someDisturbances" : disruption.someDisturbances, majorDisturbances: i === index ? key === "majorDisturbances" : disruption.majorDisturbances })));
        setPlanDisruptions(updatedPlanDisruptions);
        handlePlanDisruptionsChange(updatedPlanDisruptions);
        setPlanHasChanges(true);
    };
    const handleSelectCauseChange = (index, value) => {
        const updatedPlanDisruptions = planDisruptions.map((disruption, i) => (Object.assign(Object.assign({}, disruption), { disruptionTypeId: i === index ? value : disruption.disruptionTypeId, errorMessage: "" })));
        setPlanDisruptions(updatedPlanDisruptions);
        handlePlanDisruptionsChange(updatedPlanDisruptions);
        setPlanHasChanges(true);
    };
    const handleTextareaChange = (index, value) => {
        const updatedPlanDisruptions = planDisruptions.map((disruption, i) => (Object.assign(Object.assign({}, disruption), { comments: i === index ? value : disruption.comments })));
        setPlanDisruptions(updatedPlanDisruptions);
        handlePlanDisruptionsChange(updatedPlanDisruptions);
        setPlanHasChanges(true);
    };
    const renderTimePeriod = ({ cellIndex, row, gridColumnStart, gridColumnEnd }) => (_jsx(Cell, { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, children: _jsxs("span", { children: [row.timePeriodFrom, " - ", row.timePeriodTo] }) }, cellIndex));
    const renderRadioCell = ({ cellIndex, row, gridColumnStart, gridColumnEnd }, key) => (_jsx(Cell, { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, children: _jsx("div", { className: "plan-table-radio", children: _jsx(Radio, { value: key, checked: row[key], onChange: () => handleRadioChange(row.id - 1, key) }) }) }, cellIndex));
    const renderCauseDropdown = ({ cellIndex, row, value: selectedValue, gridColumnStart, gridColumnEnd, }) => {
        return (_jsx(Cell, { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, children: _jsx("div", { className: "plan-table-dropdown", hidden: row.normalOperation, children: _jsxs(NativeSelect, { label: t("plan.create.disruptions.causes.chooseCause"), hideLabel: true, size: "sm", value: selectedValue, error: row.errorMessage, onChange: (e) => handleSelectCauseChange(row.id - 1, Number(e.target.value)), children: [_jsxs("option", { value: "0", disabled: true, hidden: true, children: [t("plan.create.disruptions.causes.choose"), "..."] }), planMetaData === null || planMetaData === void 0 ? void 0 : planMetaData.causesOfDisruptions.map((cause) => (_jsx("option", { value: cause.id, children: t(`plan.create.disruptions.causes.${cause.disruptionName}`) }, cause.id)))] }) }) }, cellIndex));
    };
    const renderCommentsInput = ({ cellIndex, row, value, gridColumnStart, gridColumnEnd }) => (_jsx(Cell, { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, children: _jsx("div", { hidden: row.normalOperation, style: { padding: "0.4rem" }, children: _jsx(Textarea, { cols: 40, rows: 1, description: "", error: "", label: "comments", hideLabel: true, size: "sm", defaultValue: value, onChange: (e) => handleTextareaChange(row.id - 1, e.target.value) }) }) }, cellIndex));
    const customCellRenderers = {
        normalOperation: (props) => renderRadioCell(props, "normalOperation"),
        someDisturbances: (props) => renderRadioCell(props, "someDisturbances"),
        majorDisturbances: (props) => renderRadioCell(props, "majorDisturbances"),
        disruptionTypeId: (props) => renderCauseDropdown(props),
        comments: (props) => renderCommentsInput(props),
        timePeriodFrom: (props) => renderTimePeriod(props),
    };
    const getTableColumnHeaders = () => {
        return [
            {
                headers: headers.map((header, index) => ({
                    key: header,
                    content: t(tableColumnHeaders[header]),
                    cellProps: {
                        weight: index === 5 ? 3 : 1,
                        className: `${alignCellCenterClass}`,
                    },
                })),
            },
        ];
    };
    if (isLoading || isLoadingMetadata || loadingDisruptions)
        return _jsx(Spinner, {});
    if (!planDisruptions || planDisruptions.length === 0)
        return _jsx(NoDataAvailable, {});
    return (_jsxs(_Fragment, { children: [planExists && (_jsxs(Alert, { className: "plan-alert-custom", children: [_jsxs("h3", { className: "plan-alert-heading", children: ["God dag ", currentPlan.processType.company, " ", currentPlan.processType.name] }), _jsx("p", { className: "plan-alert-paragraph", style: { margin: "10px 0" }, children: t("plan.create.alertInfo.updatedInfo", {
                            updatedBy: getDomainName(currentPlan.updatedBy),
                            updatedAt: prettyPrintDate(currentPlan.updatedAt, i18n.language),
                        }) }), _jsx("p", { className: "plan-alert-paragraph", dangerouslySetInnerHTML: { __html: t("plan.create.alertInfo.alertDescription") } })] })), _jsx("div", { className: "plan-create-table", children: _jsx(Table, { data: addIdToObj(planDisruptions), cellRenderers: customCellRenderers, excludeKeys: ["errorMessage", "timePeriodTo"], headerGroups: getTableColumnHeaders(), useDesignsystemetTheme: true, style: { minWidth: "800px" } }) })] }));
};
