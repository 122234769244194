import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { AvinorColor } from "@constants";
import { mapWeatherCodeToIcon } from "@mappings";
import ToolTip from "@charts/shared/components/Tooltip/Tooltip";
import { formatXAxisTick } from "@charts/utils";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useGranularityContext } from "@contexts/GranularityContext";
const CustomTooltip = ({ flightMovement, chart, }) => {
    const { picker: { id, from: currentSelectedFromDate }, } = useDatePickerContext();
    const { granularity } = useGranularityContext({ from: currentSelectedFromDate, currentPage: id });
    const { t } = useTranslation();
    if (!chart || !flightMovement)
        return null;
    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    const { from, weatherCode, international, domestic, schengen, totalFlights, airTemperature, precipitationAmount, dewpointAlert, dewpointTemperature, } = flightMovement;
    const { icon: WeatherIcon, iconDescription } = mapWeatherCodeToIcon(weatherCode);
    const listItems = {
        totalFlights,
        international,
        schengen,
        domestic,
    };
    const mapKeyToColor = {
        totalFlights: AvinorColor.Grey300,
        international: AvinorColor.ChartMintGreen300,
        schengen: AvinorColor.ChartVioletLight600,
        domestic: AvinorColor.ChartVioletLight400,
    };
    return (_jsx(motion.div, { initial: { opacity: 0, scale: 0.95 }, animate: { opacity: 1, scale: 1 }, exit: { opacity: 0, scale: 0.95 }, transition: { duration: 0.2 }, style: {
            position: "absolute",
            left: positionX + 50 + "px",
            top: positionY + 35 + "px",
        }, children: _jsxs(ToolTip, { children: [_jsxs(ToolTip.Title, { children: [formatXAxisTick(from.toString(), granularity, t("timePrefix")), WeatherIcon && (_jsx(WeatherIcon, { width: "2rem", height: "2rem", color: "white", style: { marginLeft: "auto" } }))] }), _jsx(ToolTip.SubTitle, { children: iconDescription }), _jsxs(ToolTip.List, { children: [Object.entries(listItems).map(([key, value]) => {
                            return (_jsx(ToolTip.ListItem, { style: { type: "bullet", bgColor: mapKeyToColor[key] }, content: {
                                    value: t(`charts.flightMovements.bar.${key}`),
                                }, status: {
                                    value: `${value}`,
                                }, horizontalLine: {
                                    bottom: key === "totalFlights",
                                } }, key));
                        }), airTemperature !== undefined && (_jsx(ToolTip.ListItem, { style: {
                                type: "bullet",
                                bgColor: AvinorColor.Grey300,
                            }, content: {
                                value: t("charts.flightMovements.bar.airTemperature"),
                            }, status: {
                                value: airTemperature,
                            }, horizontalLine: {
                                top: true,
                            } })), precipitationAmount !== undefined && (_jsx(ToolTip.ListItem, { style: {
                                type: "bullet",
                                bgColor: AvinorColor.Grey300,
                            }, content: {
                                value: t("charts.flightMovements.bar.precipitationAmount"),
                            }, status: {
                                value: precipitationAmount,
                            } })), dewpointAlert && (_jsx(ToolTip.ListItem, { style: {
                                type: "bullet",
                                bgColor: AvinorColor.Grey300,
                            }, content: {
                                value: t("charts.flightMovements.bar.dewPointAlert"),
                            }, status: {
                                value: dewpointTemperature,
                            } }))] })] }) }));
};
export default CustomTooltip;
