export var FormatPattern;
(function (FormatPattern) {
    FormatPattern["yyyy_MM_dd_HH_00"] = "yyyy-MM-dd'T'HH:00";
    FormatPattern["yyyy_MM_dd_HH_00_Z"] = "yyyy-MM-dd'T'HH:00'Z";
    FormatPattern["yyyy_MM_dd_HH_mm"] = "yyyy-MM-dd'T'HH:mm";
    FormatPattern["yyyy_MM_dd_HH_mm_Z"] = "yyyy-MM-dd'T'HH:mm'Z'";
    FormatPattern["yyyy_MM_dd_HH_mm_ss_SSS"] = "yyyy-MM-dd HH:mm:ss.SSS";
    FormatPattern["yyyy_MM_dd"] = "yyyy-MM-dd";
    FormatPattern["yyyy_MM_dd_Z"] = "yyyy-MM-dd'Z";
    FormatPattern["d_LLL"] = "d LLL";
    FormatPattern["d_LLLL"] = "d LLLL";
    FormatPattern["d_MMMM"] = "d MMMM";
    FormatPattern["LL"] = "LL";
    FormatPattern["H"] = "H";
    FormatPattern["H_mm"] = "H:mm";
    FormatPattern["HH"] = "HH";
    FormatPattern["HH_00"] = "HH:00";
    FormatPattern["PPP"] = "PPP";
    FormatPattern["PPPP"] = "PPPP";
    FormatPattern["PP"] = "PP";
    FormatPattern["p"] = "p";
    FormatPattern["dd"] = "dd";
    FormatPattern["HH_mm"] = "HH:mm";
    FormatPattern["HH_mm_ss"] = "HH:mm:ss";
    FormatPattern["hh_mm_a"] = "hh:mm a";
    FormatPattern["kk_mm"] = "kk:mm";
    FormatPattern["dd_MM_yy"] = "dd.MM.yy";
    FormatPattern["dd_MM_yyyy"] = "dd.MM.yyyy";
    FormatPattern["EEEE_d_MMMM"] = "EEEE d. MMMM";
    FormatPattern["iiii_d_MMMM"] = "iiii d. MMMM";
    FormatPattern["EEEE_DD_MM"] = "EEEE dd.MM";
})(FormatPattern || (FormatPattern = {}));
