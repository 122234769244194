import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./DeIceHandlerFilter.scss";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import Select from "@components/Select/Select";
import { useDeIceHandlerContext } from "@features/DeIce/contexts/DeIceHandlerContext";
import { EDeIceHandler } from "@features/DeIce/enums";
import { useDeIceDateSelector } from "@features/DeIce/hooks/useDeIceDateSelector";
import { getPadConfiguration } from "@features/DeIce/services";
import { useIataContext } from "@contexts/IataContext";
const getDeIceHandlerOptions = (deIceConfig, deIcePad) => {
    const deiceHandlerOptions = [];
    let pads = deIceConfig.pads;
    if (deIcePad) {
        pads = pads.filter((p) => deIcePad.value === p.nameAcronym);
    }
    pads.filter((p) => p.active).forEach((pad) => {
        const { handlers } = pad;
        if (handlers.includes(EDeIceHandler.SGH) &&
            !deiceHandlerOptions.some((option) => option.value === EDeIceHandler.SGH)) {
            deiceHandlerOptions.push({ value: EDeIceHandler.SGH, label: EDeIceHandler.SGH });
        }
        if (handlers.includes(EDeIceHandler.WGH) &&
            !deiceHandlerOptions.some((option) => option.value === EDeIceHandler.WGH)) {
            deiceHandlerOptions.push({ value: EDeIceHandler.WGH, label: EDeIceHandler.WGH });
        }
        if (handlers.includes(EDeIceHandler.MENZIES) &&
            !deiceHandlerOptions.some((option) => option.value === EDeIceHandler.MENZIES)) {
            deiceHandlerOptions.push({ value: EDeIceHandler.MENZIES, label: "MEN" });
        }
    });
    return deiceHandlerOptions;
};
const DeIceHandlerFilter = () => {
    const { selectedDeIceHandlers, setSelectedDeIceHandlers } = useDeIceHandlerContext();
    const [deIcePad, setDeIcePad] = useState(null);
    const [showHandlers, setShowHandlers] = useState(true);
    const deIceDataDate = useDeIceDateSelector();
    const { iata } = useIataContext();
    const { data: deIceConfiguration, isLoading } = useQuery({
        queryFn: () => getPadConfiguration({ date: deIceDataDate, iata }),
        refetchInterval: 0,
        staleTime: 0,
        refetchOnWindowFocus: false,
        gcTime: 0,
        queryKey: ["getPadConfiguration", { deIceDataDate, iata }],
    });
    useEffect(() => {
        if (deIcePad) {
            setDeIcePad(null);
            setSelectedDeIceHandlers([]);
        }
    }, [deIceConfiguration]);
    if (isLoading || !deIceConfiguration)
        return null;
    const deiceHandlerOptions = getDeIceHandlerOptions(deIceConfiguration, deIcePad);
    const padOptions = deIceConfiguration.pads
        .filter((pad) => pad.active)
        .map((pad) => ({ value: pad.nameAcronym, label: pad.name }));
    const selectedDeiceHandlerOptions = selectedDeIceHandlers.map((handler) => ({
        value: handler,
        label: handler,
    }));
    const handleDeiceHandlersChange = (options) => {
        if (options.length > 0) {
            const handlers = options.map((option) => option.value);
            setSelectedDeIceHandlers(handlers);
            setShowHandlers(true);
            setDeIcePad(null);
        }
        else {
            setSelectedDeIceHandlers([]);
        }
    };
    const handlePadChange = (option) => {
        setDeIcePad(option);
        if (option) {
            const updatedHandlers = getDeIceHandlerOptions(deIceConfiguration, option);
            if (updatedHandlers.length > 0) {
                const updatedHandlerValues = updatedHandlers.map((handler) => handler.value);
                setSelectedDeIceHandlers(updatedHandlerValues);
                setShowHandlers(false);
            }
            else {
                setSelectedDeIceHandlers(["NONE"]);
            }
        }
        else {
            setSelectedDeIceHandlers([]);
        }
    };
    return (_jsxs("div", { className: "deice-handler-filter-grid", children: [_jsx(Select, { label: "Pad", options: padOptions, placeholder: padOptions.length === 0 ? "None available" : "All", onChange: (option) => handlePadChange(option), isClearable: true, isDisabled: showHandlers &&
                    !selectedDeiceHandlerOptions.some((option) => option.value === "NONE") &&
                    selectedDeiceHandlerOptions.length !== 0, value: deIcePad }), deiceHandlerOptions.length == 0 ? (_jsx(Select, { isMulti: true, label: "De-ice Handler", options: deiceHandlerOptions, placeholder: "None available", isDisabled: true, value: [] })) : showHandlers || !deIcePad ? (_jsx(Select, { isMulti: true, label: "De-ice Handler", options: deiceHandlerOptions, placeholder: "All", onChange: (options) => handleDeiceHandlersChange(options), value: selectedDeiceHandlerOptions.filter((x) => x.value != "NONE") })) : (_jsx(Select, { isMulti: true, label: "De-ice Handler", options: deiceHandlerOptions, isDisabled: true, placeholder: selectedDeiceHandlerOptions
                    ? "All (" +
                        selectedDeiceHandlerOptions
                            .map((x) => x.value)
                            .filter((value) => value !== "NONE")
                            .join(", ") +
                        ")"
                    : "All", onChange: (options) => handleDeiceHandlersChange(options), value: [] }))] }));
};
export default DeIceHandlerFilter;
