import { jsx as _jsx } from "react/jsx-runtime";
import { isPast, isToday } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getDateToReadableText } from "@utils/DateUtils";
import { Page } from "@enums";
import { fiveDayReportWidget, widgetPunctuality } from "@widgets/registry";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useIataContext } from "@contexts/IataContext";
import PageLayout from "./PageLayout/PageLayout";
import { getDatePickerSelectOptionsForPage } from "./utils";
const PunctualityRegularityPage = () => {
    const { iata: airport } = useIataContext();
    const [punctualityDisplayMode, setPunctualityDisplayMode] = useState("customDay");
    const { picker: { id }, } = useDatePickerContext();
    const { t } = useTranslation();
    const regularTitle = getDateToReadableText().toLowerCase();
    const weeklyOverviewTitle = t("datePickerPresets.last5days").toLowerCase();
    const description = t("pages.punctuality.description", { iata: airport });
    const isWeeklyOverview = punctualityDisplayMode === "weeklyOverview";
    const title = `${t("pages.punctuality.title")} ${isWeeklyOverview ? weeklyOverviewTitle : regularTitle}`;
    return (_jsx(PageLayout, { page: Page.PunctualityRegularity, customDatePickerProps: {
            id,
            selectProps: {
                options: getDatePickerSelectOptionsForPage(Page.PunctualityRegularity),
                onChange: (option) => {
                    if (!option)
                        return;
                    if (option.value !== "custom") {
                        setPunctualityDisplayMode("customDay");
                    }
                },
            },
            datePickerProps: {
                onOk: () => {
                    setPunctualityDisplayMode("customDay");
                },
                shouldDisableDate: (date) => {
                    return !isPast(date) && !isToday(date);
                },
            },
        }, title: title, description: description, widgets: isWeeklyOverview ? fiveDayReportWidget : widgetPunctuality, setPunctualityDisplayMode: setPunctualityDisplayMode }));
};
export default PunctualityRegularityPage;
