import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { getDateToReadableText } from "@utils/DateUtils";
import { Page } from "@enums";
import { DeIceHandlerProvider } from "@features/DeIce/contexts/DeIceHandlerContext";
import { DeIceHasChangesProvider } from "@features/DeIce/contexts/DeIceHasChangesContext";
import { DeIceWeatherProvider } from "@features/DeIce/contexts/DeIceWeatherContext";
import { deIceWidgets } from "@widgets/registry";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useIataContext } from "@contexts/IataContext";
import PageLayout from "./PageLayout/PageLayout";
import { getDatePickerSelectOptionsForPage } from "./utils";
const DeIcePage = () => {
    const { iata } = useIataContext();
    const { t } = useTranslation();
    const { picker: { id }, } = useDatePickerContext();
    return (_jsx(DeIceHasChangesProvider, { children: _jsx(DeIceHandlerProvider, { children: _jsx(DeIceWeatherProvider, { children: _jsx(PageLayout, { customDatePickerProps: {
                        id,
                        selectProps: {
                            options: getDatePickerSelectOptionsForPage(Page.DeIce),
                        },
                    }, title: `${t("pages.deice.title")} ${getDateToReadableText().toLowerCase()}`, description: t("pages.deice.description", { iata }), widgets: deIceWidgets }) }) }) }));
};
export default DeIcePage;
