import { httpDeIceService } from "@services/HttpService";
import { resolveUrl } from "@utils/UrlUtils";
import { getMSALToken } from "@auth/authUtils";
export async function getDeIceData({ iata, date }) {
    const params = new URLSearchParams("");
    params.set("date", date);
    params.set("iata", iata);
    const token = await getMSALToken();
    const res = await httpDeIceService.get(resolveUrl({
        url: `status?${params}`,
        mockEndpoint: "/deice/status",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
}
export async function getDeIceFlightsData({ iata, date, limit = 1000, }) {
    const params = new URLSearchParams("");
    params.set("date", date);
    params.set("iata", iata);
    params.set("limit", limit.toString());
    const token = await getMSALToken();
    const res = await httpDeIceService.get(resolveUrl({
        url: `flights?${params}`,
        mockEndpoint: "/deice/flights",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
}
export async function getDeIceMedianTimeData({ iata, date, }) {
    const params = new URLSearchParams("");
    params.set("date", date);
    params.set("iata", iata);
    const token = await getMSALToken();
    const res = await httpDeIceService.get(resolveUrl({
        url: `median-time?${params}`,
        mockEndpoint: "/deice/median-time",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
}
export async function getPadConfiguration({ iata, date }) {
    const params = new URLSearchParams("");
    params.set("date", date);
    params.set("iata", iata);
    const token = await getMSALToken();
    const res = await httpDeIceService.get(resolveUrl({
        url: `pad-configuration?${params}`,
        mockEndpoint: "/deice/pad-configuration",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
}
export async function getDeIcePlan({ params: { date, iata, SGH, WGH, Menzies, ALL }, }) {
    const params = new URLSearchParams("");
    params.set("date", date);
    params.set("iata", iata);
    const optionalParams = { SGH, WGH, Menzies, ALL };
    for (const [key, value] of Object.entries(optionalParams)) {
        if (value) {
            params.set(key, "true");
        }
    }
    const token = await getMSALToken();
    const res = await httpDeIceService.get(resolveUrl({
        url: `plan?${params}`,
        mockEndpoint: "/deice/plan",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
}
export async function getDeIceCapacity({ params: { date, iata }, }) {
    const params = new URLSearchParams("");
    params.set("date", date);
    params.set("iata", iata);
    const token = await getMSALToken();
    const res = await httpDeIceService.get(resolveUrl({
        url: `capacity?${params}`,
        mockEndpoint: "/deice/capacity",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
}
export async function postDeIceCapacity({ body, token, }) {
    const res = await httpDeIceService.post(resolveUrl({
        url: `save-capacity`,
        mockEndpoint: "/deice/save-capacity",
    }), body, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res;
}
export async function postDeIceCapacityAndPadConfiguration({ body, }) {
    const token = await getMSALToken();
    const res = await httpDeIceService.post(resolveUrl({
        url: `capacity-and-pad-configuration`,
        mockEndpoint: "/deice/capacity-and-pad-configuration",
    }), body, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res;
}
