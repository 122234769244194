var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./ExpandButton.scss";
import { ArrowDownOutlined } from "@avinor/react-icons";
const ExpandButton = (_a) => {
    var { isExpanded, onExpand, buttonText } = _a, restProps = __rest(_a, ["isExpanded", "onExpand", "buttonText"]);
    const expandedText = (buttonText === null || buttonText === void 0 ? void 0 : buttonText.expanded) || "expandedText";
    const collapsedText = (buttonText === null || buttonText === void 0 ? void 0 : buttonText.collapsed) || "collapsedText";
    const iconClasses = !isExpanded ? "expand-button__icon" : "expand-button__icon expand-button__icon--rotate";
    return (_jsxs("button", Object.assign({ className: "expand-button", onClick: onExpand, "aria-expanded": isExpanded }, restProps, { children: [isExpanded ? expandedText : collapsedText, _jsx(ArrowDownOutlined, { className: iconClasses, "aria-hidden": "true" })] })));
};
export default ExpandButton;
