import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Grid } from "@components/Layout";
import { ProgressCard } from "@components/ProgressCard/ProgressCard";
import { breakpoints } from "@constants";
import { useMediaQuery } from "@hooks";
import { useIataContext } from "@contexts/IataContext";
import { getBaggageEnrouteStatistics } from "../services";
export const BaggageEnrouteSchengen = () => {
    const { iata } = useIataContext();
    const { data: bagsData } = useQuery({
        queryFn: () => getBaggageEnrouteStatistics({
            iata,
            hoursTo: 1,
        }),
        queryKey: ["getBaggageEnrouteStatistics", { iata }],
    });
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    const { t } = useTranslation();
    return (_jsxs(Grid, { columns: 2, gap: isMobile ? 0.5 : 1, children: [_jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", title: t("baggage.widgets.enrouteSchengen.domestic"), value: (bagsData === null || bagsData === void 0 ? void 0 : bagsData.enrouteBagsDomestic) || 0 }), _jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", title: t("baggage.widgets.enrouteSchengen.international"), value: (bagsData === null || bagsData === void 0 ? void 0 : bagsData.enrouteBagsSchengenInternational) || 0 })] }));
};
BaggageEnrouteSchengen.displayName = "BaggageEnrouteSchengen";
