import { jsx as _jsx } from "react/jsx-runtime";
import "./Spinner.scss";
import { Audio, Oval, ThreeDots } from "react-loader-spinner";
import { AvinorColor } from "@constants";
const Spinner = ({ type = "audio", size = 40, color = AvinorColor.VioletPrimary, }) => {
    const Icon = {
        audio: Audio,
        oval: Oval,
        threeDots: ThreeDots,
    }[type];
    return (_jsx("div", { className: "spinner", children: _jsx(Icon, { color: color, height: size, width: size }) }));
};
export default Spinner;
