import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
export const useSignOut = () => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const currentAccount = instance.getActiveAccount();
    const [name, setName] = useState("");
    useEffect(() => {
        if (currentAccount && currentAccount.username) {
            setName(currentAccount.username);
        }
    }, [instance]);
    const handleSignOut = () => {
        var _a;
        const logoutHint = (_a = currentAccount === null || currentAccount === void 0 ? void 0 : currentAccount.idTokenClaims) === null || _a === void 0 ? void 0 : _a.login_hint;
        instance.logout({ logoutHint: logoutHint });
    };
    return {
        isAuthenticated,
        name,
        handleSignOut,
    };
};
