import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Modal } from "@digdir/designsystemet-react";
import { ChatExclamationmarkIcon, CheckmarkCircleFillIcon, FloppydiskIcon } from "@navikt/aksel-icons";
import { useTranslation } from "react-i18next";
export var OnSaveModalStateEnum;
(function (OnSaveModalStateEnum) {
    OnSaveModalStateEnum[OnSaveModalStateEnum["SAVING"] = 0] = "SAVING";
    OnSaveModalStateEnum[OnSaveModalStateEnum["SAVED"] = 1] = "SAVED";
    OnSaveModalStateEnum[OnSaveModalStateEnum["ERROR"] = 2] = "ERROR";
})(OnSaveModalStateEnum || (OnSaveModalStateEnum = {}));
export const PlanOnSaveModal = ({ onSaveModalRef, onSave, isSaving, modalState }) => {
    const { t } = useTranslation();
    return (_jsx(Modal.Root, { children: _jsxs(Modal.Dialog, { ref: onSaveModalRef, children: [modalState.state == OnSaveModalStateEnum.SAVING && (_jsxs(_Fragment, { children: [_jsx(Modal.Header, { children: t("plan.create.planOnSaveModal.headerSaving") }), _jsx(Modal.Content, { children: t("plan.create.planOnSaveModal.contentSaving") }), _jsx(Modal.Footer, { children: _jsxs("div", { style: { display: "flex", justifyContent: "flex-end", width: "100%", gap: "10px" }, children: [_jsx(Button, { color: "accent", size: "sm", variant: "tertiary", disabled: isSaving, onClick: () => { var _a; return (_a = onSaveModalRef.current) === null || _a === void 0 ? void 0 : _a.close(); }, children: t("plan.create.planOnSaveModal.buttonCancel") }), _jsx(Button, { color: "accent", size: "sm", variant: "primary", disabled: isSaving, onClick: () => onSave(), children: isSaving ? (_jsx(_Fragment, { children: t("plan.create.planOnSaveModal.saving") })) : (_jsxs(_Fragment, { children: [_jsx(FloppydiskIcon, { "aria-hidden": true, fontSize: "1.5rem" }), t("plan.create.planOnSaveModal.buttonSave")] })) })] }) })] })), modalState.state == OnSaveModalStateEnum.SAVED && (_jsx(_Fragment, { children: _jsx(Modal.Content, { children: _jsxs("div", { style: {
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                alignItems: "center",
                                justifyContent: "center",
                            }, children: [_jsx("div", { style: { marginTop: "20px" }, children: _jsx(CheckmarkCircleFillIcon, { title: "a11y-title", fontSize: "3.5rem", color: "#2a9d5a" }) }), _jsx("h2", { style: { color: "var(--avinor-green-400)" }, children: t("plan.create.planOnSaveModal.headerSaved") }), _jsx("div", { style: { marginBottom: "30px" }, children: t("plan.create.planOnSaveModal.contentSaved") })] }) }) })), modalState.state == OnSaveModalStateEnum.ERROR && (_jsxs(_Fragment, { children: [_jsx(Modal.Header, { children: _jsxs("div", { style: { display: "flex", alignItems: "center", gap: "10px" }, children: [_jsx(ChatExclamationmarkIcon, { title: "a11y-title", fontSize: "1.5rem", color: "#243142" }), _jsx("span", { children: t("plan.create.planOnSaveModal.headerError") })] }) }), _jsx(Modal.Content, { children: modalState.message
                                ? modalState.message
                                : t("plan.create.planOnSaveModal.defaultErrorMessage") }), _jsx(Modal.Footer, { children: _jsx("div", { style: { display: "flex", justifyContent: "flex-end", width: "100%", gap: "10px" }, children: _jsx(Button, { color: "accent", size: "sm", variant: "tertiary", onClick: () => { var _a; return (_a = onSaveModalRef.current) === null || _a === void 0 ? void 0 : _a.close(); }, children: t("plan.create.planOnSaveModal.buttonOk") }) }) })] }))] }) }));
};
