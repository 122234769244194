import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ToggleGroup, ToggleGroupRoot } from "@digdir/designsystemet-react";
import "./PlanOverviewDayPickerWidget.scss";
import { useTranslation } from "react-i18next";
import Widget from "@components/Widget/Widget";
import { PlanOverviewDay, usePlanDayPickerContext } from "@features/Plan/contexts/PlanDayPickerContext";
export const PlanOverviewDayPickerWidget = () => {
    const { t } = useTranslation();
    const { selectedDay, setSelectedDay } = usePlanDayPickerContext();
    return (_jsx(Widget.Container, { children: _jsx(Widget.Body, { children: _jsxs(ToggleGroupRoot, { defaultValue: selectedDay, name: "toggle-group-plan-day", size: "sm", onChange: (e) => setSelectedDay(e), children: [_jsx(ToggleGroup.Item, { value: PlanOverviewDay.YESTERDAY, children: t(PlanOverviewDay.YESTERDAY) }), _jsx(ToggleGroup.Item, { value: PlanOverviewDay.TODAY, children: t(PlanOverviewDay.TODAY) }), _jsx(ToggleGroup.Item, { value: PlanOverviewDay.TOMORROW, children: t(PlanOverviewDay.TOMORROW) })] }) }) }));
};
PlanOverviewDayPickerWidget.displayName = "PlanOverviewDayPickerWidget";
