import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { NativeSelect } from "@digdir/designsystemet-react";
import { useTranslation } from "react-i18next";
import Spinner from "@components/Spinner/Spinner";
import { usePlanProcessTypeContext } from "@features/Plan/contexts/PlanProcessTypeContext";
import usePlanMetadata from "@features/Plan/hooks/usePlanMetadata";
const PlanCreateStep1Widget = ({ style, showError }) => {
    const { t } = useTranslation();
    const { planMetaData, isLoadingMetadata } = usePlanMetadata();
    const { companyName: selectedCompanyName, setCompanyName: setSelectedCompanyName, planProcessType: selectedPlanProcessType, setPlanProcessTypeById: setSelectedPlanProcessTypeById, } = usePlanProcessTypeContext();
    if (isLoadingMetadata)
        return _jsx(Spinner, {});
    // Extract unique company names from planMetaData
    const uniqueCompanies = [...new Set(planMetaData === null || planMetaData === void 0 ? void 0 : planMetaData.processTypes.map((p) => p.company))];
    return (_jsxs(_Fragment, { children: [_jsx("div", { style: style && { width: "60%", margin: "0 auto" }, children: _jsxs(NativeSelect, { label: t("plan.create.selectProcess.selectCompanyLabel"), error: showError && !selectedCompanyName ? t("plan.create.selectProcess.selectCompanyError") : "", size: "sm", defaultValue: selectedCompanyName, onChange: (e) => setSelectedCompanyName(e.target.value), children: [_jsx("option", { value: "", disabled: true, hidden: true, children: t("plan.create.selectProcess.selectPlaceholder") }), uniqueCompanies.map((company) => (_jsx("option", { value: company, children: company }, company)))] }) }), _jsx("div", { style: style && { width: "60%", margin: "1rem auto" }, children: selectedCompanyName && (_jsxs(NativeSelect, { label: t("plan.create.selectProcess.selectProcessLabel"), size: "sm", error: showError && !(selectedPlanProcessType === null || selectedPlanProcessType === void 0 ? void 0 : selectedPlanProcessType.id)
                        ? t("plan.create.selectProcess.selectProcessError")
                        : "", value: (selectedPlanProcessType === null || selectedPlanProcessType === void 0 ? void 0 : selectedPlanProcessType.id) || "", onChange: (e) => setSelectedPlanProcessTypeById(Number(e.target.value)), children: [_jsx("option", { value: "", disabled: true, hidden: true, children: t("plan.create.selectProcess.selectPlaceholder") }), planMetaData === null || planMetaData === void 0 ? void 0 : planMetaData.processTypes.filter((p) => p.company === selectedCompanyName).map((process) => (_jsx("option", { value: process.id, children: process.name }, process.id)))] })) })] }));
};
export default PlanCreateStep1Widget;
