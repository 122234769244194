import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { roundIfNeeded } from "@utils/Utils";
import Progress from "@components/Progress/Progress";
import Spinner from "@components/Spinner/Spinner";
import useRegularityAndPunctualityPeriodData from "../hooks/useRegularityAndPunctualityPeriodData";
export const PunctualityProgressBar = ({ direction }) => {
    const { data: punctualityTotalData, isLoading } = useRegularityAndPunctualityPeriodData({
        paxOnly: false,
        direction,
    });
    const { t } = useTranslation();
    const progress = punctualityTotalData === null || punctualityTotalData === void 0 ? void 0 : punctualityTotalData.totalPunctuality;
    const operatedFlights = punctualityTotalData === null || punctualityTotalData === void 0 ? void 0 : punctualityTotalData.totalFlightsOperated;
    const plannedFlights = punctualityTotalData === null || punctualityTotalData === void 0 ? void 0 : punctualityTotalData.totalFlights;
    if (isLoading)
        return _jsx(Spinner, {});
    return (_jsx(Progress, { completedPercentage: progress, text: t("common.progressStatusCompletedAndPlanned", {
            percent: roundIfNeeded(progress || 0),
            completed: operatedFlights,
            planned: plannedFlights,
        }), withPadding: true }));
};
PunctualityProgressBar.displayName = "PunctualityProgressBar";
