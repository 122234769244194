import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./config";
const authProvider = new PublicClientApplication(msalConfig);
await authProvider.initialize();
const activeAccount = authProvider.getActiveAccount();
if (!activeAccount) {
    const accounts = authProvider.getAllAccounts();
    if (accounts.length > 0) {
        authProvider.setActiveAccount(accounts[0]);
    }
}
authProvider.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const { account } = event.payload;
        authProvider.setActiveAccount(account);
    }
});
export default authProvider;
