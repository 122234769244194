import { jsx as _jsx } from "react/jsx-runtime";
import { Snow, Heavysnow, Lightsleet, Fog, Heavysleet } from "@avinor/react-icons";
import ButtonGroup from "@components/Buttons/ButtonGroup/ButtonGroup";
import { useDeIceHasChangesContext } from "@features/DeIce/contexts/DeIceHasChangesContext";
import { useDeIceWeatherContext } from "@features/DeIce/contexts/DeIceWeatherContext";
const DeIceWeatherConditionFilter = () => {
    var _a;
    const { deIceWeather: weatherCondition, setDeIceWeather: setWeatherCondition } = useDeIceWeatherContext();
    const { setDeIceHasChanges } = useDeIceHasChangesContext();
    const weatherConditionLookup = {
        fog: 1,
        lightSleet: 2,
        snow: 3,
        heavySnow: 4,
        heavySleet: 5,
    };
    const iconProps = {
        width: 24,
        height: 24,
        color: "var(--avinor-violet-light-400)",
    };
    const weatherSelected = (weather) => {
        setWeatherCondition(weather);
        setDeIceHasChanges(true);
    };
    const buttons = [
        {
            id: 1,
            icon: _jsx(Fog, Object.assign({}, iconProps)),
            onClick: () => weatherSelected("fog"),
        },
        {
            id: 2,
            icon: _jsx(Lightsleet, Object.assign({}, iconProps)),
            onClick: () => weatherSelected("lightSleet"),
        },
        {
            id: 3,
            icon: _jsx(Snow, Object.assign({}, iconProps)),
            onClick: () => weatherSelected("snow"),
        },
        {
            id: 4,
            icon: _jsx(Heavysnow, Object.assign({}, iconProps)),
            onClick: () => weatherSelected("heavySnow"),
        },
        {
            id: 5,
            icon: _jsx(Heavysleet, Object.assign({}, iconProps)),
            onClick: () => weatherSelected("heavySleet"),
        },
    ];
    const active = (_a = buttons.find((button) => button.id === weatherConditionLookup[weatherCondition])) === null || _a === void 0 ? void 0 : _a.id;
    return (_jsx(ButtonGroup, { active: active, buttons: buttons, fullWidth: true }));
};
export default DeIceWeatherConditionFilter;
