import { addDays, isBefore } from "date-fns";
export const useFlightMovementDataSource = (from) => {
    const selectedDate = new Date(from);
    const today = new Date();
    const tenDaysAhead = addDays(today, 10);
    if (isBefore(selectedDate, today) || selectedDate <= tenDaysAhead) {
        return { dataSource: "operative" };
    }
    else {
        return { dataSource: "scheduled" };
    }
};
