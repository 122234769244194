export const alignCellLeftClass = "designsystemet-align-cell-left";
export const alignCellCenterClass = "designsystemet-align-cell-center";
export const designsystemetTheme = {
    Table: `
      z-index: 0;
      width: 100%;
      table-layout: fixed;
      border-collapse: separate;
      border-spacing: 0;
      display: table;
         thead {
        display: table-header-group !important;
    }
      > tbody {
        display: table-row-group;
      }
      
    `,
    BaseCell: `
      text-align: left;
      color: var(--semantic-text-neutral-default);
      display: table-cell;
    `,
    HeaderRow: `
      text-align: left;
      display: table-row;
      &:last-of-type {
        & th:not(:first-of-type):not(.${alignCellLeftClass}, .${alignCellCenterClass}) {
          & > div > div {
            justify-content: flex-end;
          }
        }
      }
      :nth-of-type(2) {
        background-color: var(--avinor-white);
        th {
          font-weight: 300;
        }
      }
    `,
    HeaderCell: `
      color: var(--semantic-text-neutral-default);
      font-weight: 500;
      border-bottom: 2px solid var(--avinor-grey-100);
      & div > div > div {
        white-space: initial;
        text-align: right;
      }
      & > div > div {
        display: flex;
        justify-content: flex-end;
      }
      > div {
        padding: 0.4rem;
        white-space: initial;
        text-align: right;
        
      }
      &.header-sortable > div {
        .active, &:hover {
          z-index: 1;
          &::after {
            content: "";
            background-color: var(--sematic-surface-neutral-subtle);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
          }
        }
        .active {
          &::after {
            background-color: var(--sematic-surface-neutral-subtle-hover);
          }
        }
      }
      &:first-of-type,  &.${alignCellLeftClass} {
        & div > div > div {
          text-align: left;
        }
        & > div > div {
          justify-content: flex-start;
        }
        > div {
          text-align: left;
          
        }
      }

      &.${alignCellCenterClass} {
        & div > div > div {
          text-align: center;
        }
        & > div > div {
          justify-content: center;
        }
        > div {
          text-align: center;
          
        }
      }
   
    `,
    Cell: `
    padding: 0.4rem;
      & > div {
        white-space: initial;
        text-align: right;
      }
      &:first-of-type, &.${alignCellLeftClass} {
        & > div {
          text-align: left;
        }
      }
      &.${alignCellCenterClass} {
        & > div {
          text-align: center;
          display: flex;
          justify-content: center;
        }
      }
    `,
    Row: `
      display: table-row;
      :nth-of-type(even) {
        background-color: var(--sematic-surface-neutral-subtle);
      }
    `,
};
export const defaultTheme = {
    Table: `
      z-index: 0;
      border-top: 1px solid var(--avinor-grey-100);
      width: 100%;
  `,
    BaseCell: `
      padding: 0.4rem;
      text-align: center;

      & > div {
          white-space: normal;
     
          overflow: hidden;
          text-overflow: ellipsis;
      }

      &:not(:last-of-type) {
          border-right: 1px solid var(--avinor-grey-100);
      }

      &:first-of-type {
          border-left: 1px solid var(--avinor-grey-100);
      }
  `,
    HeaderRow: `
      background-color: var(--avinor-grey-50);

      &:last-of-type {
          & th:not(:first-of-type) {
              & > div > div {
                  justify-content: space-between;
              }
          }
      }

      :nth-of-type(2) {
          background-color: var(--avinor-white);

          th {
              font-weight: 300;
          }
      }
  `,
    HeaderCell: `
      color: var(--avinor-grey-500);
      font-weight: 500;
      border-bottom: 1px solid var(--avinor-grey-100);
      border-right: 1px solid var(--avinor-grey-100);

      & div > div > div {
          white-space: no-wrap;
          text-align: center;
      }

      & > div > div {
          display: flex;
          justify-content: center;
      }

  `,
    Cell: `
      border-right: 1px solid var(--avinor-grey-100);
  `,
    Row: `
      & td {
          border-bottom: 1px solid var(--avinor-grey-100);
      }
  `,
};
