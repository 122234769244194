import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import "./WidgetLayout.scss";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import useEmailDomain from "@auth/hooks/useEmailDomain";
import lookup from "../../widgetRegistry";
import Spinner from "../Spinner/Spinner";
import Widget from "../Widget/Widget";
const getGridStyles = (props) => {
    return {
        "--grid-row-mobile": props.gridRow[0],
        "--grid-row-tablet": props.gridRow[1],
        "--grid-row-desktop": props.gridRow[2],
        "--grid-column-mobile": props.gridColumn[0],
        "--grid-column-tablet": props.gridColumn[1],
        "--grid-column-desktop": props.gridColumn[2],
        "--row-span-mobile": props.rowSpan[0],
        "--row-span-tablet": props.rowSpan[1],
        "--row-span-desktop": props.rowSpan[2],
        "--column-span-mobile": props.columnSpan[0],
        "--column-span-tablet": props.columnSpan[1],
        "--column-span-desktop": props.columnSpan[2],
        "--min-height": `${props.componentProps.minHeight ? props.componentProps.minHeight : 0}px`,
    };
};
export const WidgetLayout = ({ widgets, layout }) => {
    const { domain } = useEmailDomain();
    // Filter widgets based on domain permissions
    // Clone widgets to avoid mutating the original array
    const clonedWidgets = widgets.map((widget) => JSON.parse(JSON.stringify(Object.assign({}, widget))));
    const filteredWidgets = clonedWidgets.filter((w) => {
        var _a, _b;
        return ((_a = w.permissions) === null || _a === void 0 ? void 0 : _a.requiredDomain) ? ((_b = w.permissions) === null || _b === void 0 ? void 0 : _b.requiredDomain) === domain : true;
    });
    // Adjust grid positions based on removed widgets
    const adjustGridPositions = (widgetsToAdjust, removedWidgets) => {
        removedWidgets.forEach((removedWidget) => {
            widgetsToAdjust.forEach((widget) => {
                const originalWidget = widgets.find((w) => w.name === widget.name);
                if (widgets.filter((w) => w.name === widget.name).length > 1) {
                    console.error("There are multiple widgets with the same name. This will cause issues with the layout.");
                }
                if (originalWidget) {
                    if (originalWidget.gridRow[0] > removedWidget.gridRow[0]) {
                        widget.gridRow[0] -= removedWidget.rowSpan[0];
                    }
                    if (originalWidget.gridRow[1] > removedWidget.gridRow[1]) {
                        widget.gridRow[1] -= removedWidget.rowSpan[1];
                    }
                    if (originalWidget.gridRow[2] > removedWidget.gridRow[2]) {
                        widget.gridRow[2] -= removedWidget.rowSpan[2];
                    }
                }
            });
        });
    };
    const removedWidgets = clonedWidgets.filter((w) => !filteredWidgets.includes(w));
    adjustGridPositions(filteredWidgets, removedWidgets);
    const widgetComponents = filteredWidgets.map((widget, i) => {
        return (_createElement(WidgetComponent, Object.assign({}, widget, { key: `${widget.name}-${i}` })));
    });
    return (_jsx("section", { style: layout, className: "widget-layout", children: widgetComponents }));
};
const WidgetComponent = (props) => {
    const Component = lookup(props.component) || (() => _jsx("div", { children: "Component not found" }));
    const { t } = useTranslation();
    const widgetStyle = getGridStyles(props);
    return (_jsx(Widget, { style: widgetStyle, className: `widget ${props.name}`, children: _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsx(Component, Object.assign({}, props.componentProps, props, { t: t })) }) }, props.name));
};
