export const findMostActivePlatform = (objects) => {
    let highestSum = -Infinity;
    let highestSumIndex = -1;
    if (objects.length < 1)
        return null;
    for (let i = 0; i < objects.length; i++) {
        const object = objects[i];
        const sum = object.deIceCompleteLast30 +
            object.deIceCompleteLast60 +
            object.deIceCompleteLast120 +
            object.deIceExpectedNext30 +
            object.deIceExpectedNext60 +
            object.deIceExpectedNext120;
        if (sum >= highestSum) {
            highestSum = sum;
            highestSumIndex = i;
        }
    }
    return highestSumIndex;
};
export const calculateSumFlightsExpectedNext60 = (objects) => {
    if (objects.length < 1)
        return null;
    const sum = objects.reduce((acc, platform) => acc + platform.deIceExpectedNext60, 0);
    return sum;
};
export const calculateDeIceVehicles = (flightMovements, deIceTime, interval = 15, vehiclesPerFlight = 2) => {
    return ((flightMovements * deIceTime * vehiclesPerFlight) / interval).toFixed(2);
};
