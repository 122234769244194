import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./ProgressCard.scss";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { HStack } from "@components/Layout";
import Progress, { progressStatus } from "../Progress/Progress";
export const ProgressCard = ({ variant = "percentage", valueVisibility = "visible", progressVisibility = "visible", title, subtitle, description, icon, value, valueOfSuffix, valueSuffix, valueSize = "small", }) => {
    const isPercentage = variant === "percentage";
    const { t } = useTranslation();
    const status = isPercentage ? progressStatus(Math.floor(Number(value))).status : "neutral";
    return (_jsxs("section", { className: "progress-card", children: [_jsxs(HStack, { justify: "space-between", align: "center", children: [_jsxs("div", { children: [title && (_jsx("h4", { className: clsx("progress-card__title", {
                                    [`progress-card__title--${valueSize}`]: true,
                                }), children: title })), subtitle && (_jsx("h5", { className: clsx("progress-card__title", {
                                    [`progress-card__subtitle--${valueSize}`]: true,
                                }), children: subtitle }))] }), icon && icon] }), _jsxs("div", { className: clsx("progress-card__progress", {
                    [`progress-card__progress--${status}`]: value !== undefined,
                }), children: [valueVisibility === "visible" && (_jsxs("span", { className: clsx("progress-card__value", {
                            [`progress-card__value--${valueSize}`]: true,
                        }), children: [isPercentage ? `${Math.floor(Number(value))}%` : `${value} `, valueOfSuffix && (_jsx("span", { style: { color: "var(--avinor-grey-200)" }, children: "of " + valueOfSuffix })), valueSuffix !== undefined && (_jsxs("span", { className: "progress-card__value-suffix", children: [t("common.of"), " ", valueSuffix] }))] })), progressVisibility === "visible" && (_jsx(Progress, { size: "medium", variant: variant, percentageVisibility: "hidden", withPadding: false, completedPercentage: value, hasWhiteBackground: true }))] }), _jsx("p", { className: "progress-card__description", children: description })] }));
};
