var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./Navigation.scss";
import clsx from "clsx";
import { isToday } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ArrowDownOutlined, ArrowDoubleLeftOutlined } from "@avinor/react-icons";
import { defaultPicker, useDatePickerContext } from "@contexts/DatePickerContext";
import { NavContext, TopLevelNavItemContext } from "@contexts/NavContext";
const styleSheet = {
    nav: "nav",
    navOpen: "nav--open",
    navOverlay: "nav__overlay",
    navOverlayActive: "nav__overlay--active",
    navList: "nav__list",
    navItem: "nav__item",
    navItemExpanded: "nav__item--expanded",
    navLink: "nav__link",
    navButton: "nav__button",
    navButtonActive: "nav__button-active",
    navButtonSingle: "nav__button--single",
    navArrow: "nav__arrow",
    navArrowRotated: "nav__arrow--rotated",
    navChildItems: "nav__child-items",
    navChildItemsExpanded: "nav__child-items--expanded",
    navChildList: "nav__child-list",
    navChildItem: "nav__child-item",
    navChildLink: "nav__child-link",
};
const Nav = (_a) => {
    var { children, defaultExpandedTopLevelNavItems = ["/"], className, open, onClose } = _a, restProps = __rest(_a, ["children", "defaultExpandedTopLevelNavItems", "className", "open", "onClose"]);
    const [expandedTopLevelNavItems, setExpandedTopLevelNavItems] = useState(defaultExpandedTopLevelNavItems);
    const [menuCollapsed, setMenuCollapsed] = useState(false);
    const [disableMenuHover, setDisableMenuHover] = useState(false);
    const toggleHoverMenu = () => {
        if (menuCollapsed) {
            setMenuCollapsed(false);
        }
        else {
            setDisableMenuHover(true);
            setMenuCollapsed(true);
        }
    };
    const navClasses = clsx(styleSheet.nav, className, {
        [styleSheet.navOpen]: open,
        "nav-collapsed": menuCollapsed,
        "nav-disable-hover": disableMenuHover,
    });
    const navOverlayClasses = clsx(styleSheet.navOverlay, className, {
        [styleSheet.navOverlayActive]: open,
    });
    return (_jsxs(_Fragment, { children: [open && (_jsx("div", { className: navOverlayClasses, onClick: onClose })), _jsx(NavContext.Provider, { value: {
                    expandedTopLevelNavItems,
                    setExpandedTopLevelNavItems,
                }, children: _jsx("nav", Object.assign({ className: navClasses }, restProps, { children: _jsxs("ul", { className: styleSheet.navList, children: [children, _jsx("li", { children: _jsx("div", { className: "nav__hide-menu-button", tabIndex: 0, children: _jsxs("div", { className: "nav__hide-menu-button-content", onClick: () => toggleHoverMenu(), onMouseLeave: () => setDisableMenuHover(false), children: [_jsx(ArrowDoubleLeftOutlined, { className: "nav__hide-menu-button-icon" }), _jsx("span", { children: menuCollapsed ? "Expand menu" : "Hide menu" })] }) }) })] }) })) })] }));
};
const Item = ({ rootPath, path, icon, childItems, onItemClicked }) => {
    const { pathname } = useLocation();
    const { picker: { from }, setPicker, } = useDatePickerContext();
    const { expandedTopLevelNavItems, setExpandedTopLevelNavItems } = useContext(NavContext);
    const topLevelNavItem = useContext(TopLevelNavItemContext);
    useEffect(() => {
        if (!rootPath)
            return;
        if (pathname.includes(rootPath)) {
            setExpandedTopLevelNavItems((currentlyExpandedTopLevelNavItems) => {
                return [...currentlyExpandedTopLevelNavItems, topLevelNavItem];
            });
        }
    }, []);
    const Icon = icon;
    const navItemClasses = clsx(styleSheet.navItem, {
        [styleSheet.navItemExpanded]: expandedTopLevelNavItems.includes(topLevelNavItem),
    });
    const navChildItemsClasses = clsx(styleSheet.navChildItems, {
        [styleSheet.navChildItemsExpanded]: expandedTopLevelNavItems.includes(topLevelNavItem),
    });
    const navArrowClasses = clsx(styleSheet.navArrow, {
        [styleSheet.navArrowRotated]: expandedTopLevelNavItems.includes(topLevelNavItem),
    });
    const handleExpandNavTopLevelNavItem = () => {
        expandedTopLevelNavItems.includes(topLevelNavItem)
            ? setExpandedTopLevelNavItems((expandedTopLevelNavItems) => expandedTopLevelNavItems.filter((item) => item !== topLevelNavItem))
            : setExpandedTopLevelNavItems((currentlyExpandedTopLevelNavItems) => [
                ...currentlyExpandedTopLevelNavItems,
                topLevelNavItem,
            ]);
    };
    if (!childItems) {
        return (_jsx("li", { className: navItemClasses, onClick: () => {
                onItemClicked === null || onItemClicked === void 0 ? void 0 : onItemClicked();
                if (!isToday(from))
                    setPicker(defaultPicker);
            }, children: _jsxs(NavLink, { to: path || "/", className: styleSheet.navLink, tabIndex: 0, children: [_jsx(Icon, {}), _jsx("span", { className: "nav__text ", children: topLevelNavItem })] }) }));
    }
    const shouldButtonBeActive = () => {
        const currentPath = window.location.pathname;
        return childItems.some((item) => item.path && item.path === currentPath);
    };
    const navButtonClasses = clsx(styleSheet.navButton, {
        [styleSheet.navButtonActive]: shouldButtonBeActive(),
    });
    return (_jsxs("li", { className: navItemClasses, children: [_jsxs("button", { className: navButtonClasses, onClick: handleExpandNavTopLevelNavItem, tabIndex: 0, children: [_jsx(Icon, {}), _jsx("span", { className: "nav__text", children: topLevelNavItem }), _jsx(ArrowDownOutlined, { className: navArrowClasses })] }), _jsx("div", { className: navChildItemsClasses, children: _jsx("ul", { className: styleSheet.navChildList, children: childItems.map((_a, childItemIndex) => {
                        var { children } = _a, childItemProps = __rest(_a, ["children"]);
                        return (_jsx(ChildItem, Object.assign({}, childItemProps, { children: children }), childItemIndex));
                    }) }) })] }));
};
const ChildItem = ({ children, name, path, onChildItemClicked }) => {
    const { expandedTopLevelNavItems } = useContext(NavContext);
    const topLevelNavItem = useContext(TopLevelNavItemContext);
    const { picker: { from }, setPicker, } = useDatePickerContext();
    const isTopLevelNavItemExpanded = expandedTopLevelNavItems.includes(topLevelNavItem);
    return (_jsx("li", { className: styleSheet.navChildItem, onClick: () => {
            onChildItemClicked === null || onChildItemClicked === void 0 ? void 0 : onChildItemClicked();
            if (!isToday(from))
                setPicker(defaultPicker);
        }, children: !path ? (children) : (_jsx(NavLink, { to: path, className: styleSheet.navChildLink, tabIndex: isTopLevelNavItemExpanded ? 0 : -1, children: _jsx("div", { className: "nav__text", children: name }) })) }));
};
Nav.Item = Item;
export default Nav;
