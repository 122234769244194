import { jsx as _jsx } from "react/jsx-runtime";
import "./NavSignOutButton.scss";
import { useTranslation } from "react-i18next";
import { useSignOut } from "@auth/hooks/useSignOut";
const NavSignOutButton = () => {
    const { isAuthenticated, handleSignOut } = useSignOut();
    const { t } = useTranslation();
    if (!isAuthenticated)
        return null;
    return (_jsx("button", { className: "nav-sign-out-button", onClick: handleSignOut, children: t("common.signOut") }));
};
export default NavSignOutButton;
