import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { usePlanEditingContext } from "../contexts/PlanEditingContext";
export const useLeaveWithoutSavingPrompt = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isBlocked, setIsBlocked] = useState(false);
    const [nextLocation, setNextLocation] = useState(null);
    const modalRef = useRef(null);
    const { todayHasChanges, setTodayHasChanges, tomorrowHasChanges, setTomorrowHasChanges } = usePlanEditingContext();
    const handleConfirm = () => {
        setIsBlocked(true);
        if (nextLocation) {
            navigate(nextLocation);
        }
        setTodayHasChanges(false);
        setTomorrowHasChanges(false);
    };
    useEffect(() => {
        if (!todayHasChanges && !tomorrowHasChanges)
            return;
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = "";
        };
        const handleBlockNavigation = (event) => {
            var _a;
            if (isBlocked || (!todayHasChanges && tomorrowHasChanges))
                return;
            const target = event.target;
            if (target && target.tagName === "A") {
                event.preventDefault();
                setNextLocation(target.getAttribute("href"));
                (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.showModal();
            }
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
        document.querySelectorAll("a").forEach((link) => {
            link.addEventListener("click", handleBlockNavigation);
        });
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            document.querySelectorAll("a").forEach((link) => {
                link.removeEventListener("click", handleBlockNavigation);
            });
        };
    }, [todayHasChanges, tomorrowHasChanges, isBlocked, navigate]);
    useEffect(() => {
        setIsBlocked(false);
    }, [location]);
    return { modalRef, handleConfirm };
};
