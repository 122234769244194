import { OperationalMessagesTableWidget } from "@features/OperationalMessages/widgets";
import { DefaultPermissions } from "@widgets/registry";
export const operationalMessagesWidgets = [
    {
        name: OperationalMessagesTableWidget.displayName,
        gridRow: [1, 1, 1],
        gridColumn: [1, 1, 1],
        rowSpan: [2, 2, 2],
        columnSpan: [12, 12, 12],
        component: OperationalMessagesTableWidget.displayName,
        componentProps: {},
        permissions: DefaultPermissions,
    },
];
