import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { subHours } from "date-fns";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "@utils/DateUtils";
import Spinner from "@components/Spinner/Spinner";
import Heading from "@components/typography/Heading/Heading";
import Widget from "@components/Widget/Widget";
import { FormatPattern } from "@enums";
import { useStartupDelay } from "@features/AirsidePerformance/hooks/useStartupDelay";
import { useRecoveredDelay } from "../hooks/useRecoverDelay";
import { useInboundTaxitime, useOutboundTaxitime } from "../hooks/useTaxiTime";
export var CardTypes;
(function (CardTypes) {
    CardTypes[CardTypes["RECOVERED_DELAY"] = 0] = "RECOVERED_DELAY";
    CardTypes[CardTypes["INBOUND_TAXI_TIME"] = 1] = "INBOUND_TAXI_TIME";
    CardTypes[CardTypes["STARTUP_DELAY"] = 2] = "STARTUP_DELAY";
    CardTypes[CardTypes["OUTBOUND_TAXI_TIME"] = 3] = "OUTBOUND_TAXI_TIME";
})(CardTypes || (CardTypes = {}));
export const OnTimePerformanceServiceQualityCardsWidget = ({ type }) => {
    const { t } = useTranslation();
    const { title, description, component } = cardTypeLookup[type];
    return (_jsx(_Fragment, { children: _jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: title, subtitle: t(description) }), _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsx(Widget.Body, { children: component }) })] }) }));
};
OnTimePerformanceServiceQualityCardsWidget.displayName = "OnTimePerformanceServiceQualityCardsWidget";
const RecoveredDelayBody = () => {
    const { data: recoverDelay } = useRecoveredDelay();
    return (_jsxs(Heading, { level: "1", size: "medium", children: [(recoverDelay === null || recoverDelay === void 0 ? void 0 : recoverDelay.averageRecoverDelay) ? Math.round(recoverDelay === null || recoverDelay === void 0 ? void 0 : recoverDelay.averageRecoverDelay) : "-", " min"] }));
};
const InboundTaxiTimeBody = () => {
    const { data: taxiTime } = useInboundTaxitime();
    return (_jsxs(Heading, { level: "1", size: "medium", children: [(taxiTime === null || taxiTime === void 0 ? void 0 : taxiTime.taxiTimeAvg) || "-", " min"] }));
};
const StartupDelayBody = () => {
    const { startupDelay } = useStartupDelay({
        from: formatDate(false, subHours(new Date(), 1), FormatPattern.yyyy_MM_dd_HH_mm),
        to: formatDate(false, new Date(), FormatPattern.yyyy_MM_dd_HH_mm),
    });
    return (_jsxs(Heading, { level: "1", size: "medium", children: [(startupDelay === null || startupDelay === void 0 ? void 0 : startupDelay.totalStartupDelay) ? Math.round(startupDelay === null || startupDelay === void 0 ? void 0 : startupDelay.totalStartupDelay) : "-", " min"] }));
};
const OutboundTaxiTimeBody = () => {
    const { data: taxiTime } = useOutboundTaxitime();
    return (_jsxs(Heading, { level: "1", size: "medium", children: [(taxiTime === null || taxiTime === void 0 ? void 0 : taxiTime.taxiTimeAvg) || "-", " min"] }));
};
const cardTypeLookup = {
    [CardTypes.RECOVERED_DELAY]: {
        title: "Recovered Delay",
        description: "onTimePerformance.processes.cards.recoveredDelay",
        component: _jsx(RecoveredDelayBody, {}),
    },
    [CardTypes.INBOUND_TAXI_TIME]: {
        title: "Inbound Taxi Time",
        description: "onTimePerformance.processes.cards.inboundTaxiTime",
        component: _jsx(InboundTaxiTimeBody, {}),
    },
    [CardTypes.STARTUP_DELAY]: {
        title: "Startup Delay",
        description: "onTimePerformance.processes.cards.startupDelay",
        component: _jsx(StartupDelayBody, {}),
    },
    [CardTypes.OUTBOUND_TAXI_TIME]: {
        title: "Outbound Taxi Time",
        description: "onTimePerformance.processes.cards.outboundTaxiTime",
        component: _jsx(OutboundTaxiTimeBody, {}),
    },
};
