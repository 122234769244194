import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./DeIceCapacityScheduler.scss";
import { NativeSelect } from "@digdir/designsystemet-react";
import { mapHandlerToName } from "@mappings/mapHandlerToName";
import { useTranslation } from "react-i18next";
import { VStack, HStack, Grid } from "@components/Layout";
import { useDeIceHasChangesContext } from "@features/DeIce/contexts/DeIceHasChangesContext";
const styleSheet = {
    root: "deicing-capacity-scheduler",
    title: "deicing-capacity-scheduler__title",
    columnHeader: "deicing-capacity-scheduler__column-header",
    handler: "deicing-capacity-scheduler__handler",
};
const selectOptions = (handler) => {
    return Array.from({ length: handler === "ALL" ? 16 : 11 }, (_, index) => ({
        value: `${index}`,
        label: `${index}`,
    }));
};
const columnHeaders = ["Handler", "00:00-14:00", "14:00-23:59"];
const DeIceCapacityScheduler = ({ deIceHandlerCapacity, setDeIceHandlerCapacity }) => {
    const { t } = useTranslation();
    const { setDeIceHasChanges } = useDeIceHasChangesContext();
    const onDeiceHandlerSelected = (selectedValue, handler, value, key) => {
        setDeIceHasChanges(true);
        setDeIceHandlerCapacity((prevHandlerCapacities) => {
            const updatedCapacities = prevHandlerCapacities.map((prevHandlerCapacity) => {
                var _a;
                if (prevHandlerCapacity.handler === handler) {
                    return Object.assign(Object.assign({}, prevHandlerCapacity), { [key]: (_a = +selectedValue) !== null && _a !== void 0 ? _a : value });
                }
                return prevHandlerCapacity;
            });
            // Calculate the morning and afternoon sums for the "ALL" handler
            const morningTotal = updatedCapacities
                .filter((capacity) => capacity.handler !== "ALL")
                .reduce((total, capacity) => total + capacity.morningFlightCapacity, 0);
            const afternoonTotal = updatedCapacities
                .filter((capacity) => capacity.handler !== "ALL")
                .reduce((total, capacity) => total + capacity.afternoonFlightCapacity, 0);
            // Update the "ALL" handler with the new sums
            return updatedCapacities.map((capacity) => capacity.handler === "ALL"
                ? Object.assign(Object.assign({}, capacity), { morningFlightCapacity: morningTotal, afternoonFlightCapacity: afternoonTotal }) : capacity);
        });
    };
    const sortOrder = ["MEN", "SGH", "WGH", "ALL"];
    const deIceHandlerCapacitySorted = deIceHandlerCapacity.sort((a, b) => sortOrder.indexOf(a.handler) - sortOrder.indexOf(b.handler));
    return (_jsxs(VStack, { className: styleSheet.root, gap: 1, children: [_jsx(HStack, { justify: "space-between", children: _jsx("h4", { className: styleSheet.title, children: t("deice.capacity.controlPanel.scheduler.title") }) }), _jsx(Grid, { columns: columnHeaders.length, gap: 1, children: columnHeaders.map((title) => (_jsx("span", { className: styleSheet.columnHeader, children: title }, title))) }), deIceHandlerCapacitySorted.map(({ handler, morningFlightCapacity, afternoonFlightCapacity }) => (_jsxs(Grid, { columns: columnHeaders.length, gap: 1, align: "center", style: handler == "ALL" ? { borderTop: "1px solid var(--avinor-grey-100)", paddingTop: "8px" } : {}, children: [_jsx("div", { className: styleSheet.handler, children: mapHandlerToName(handler) }), Object.entries({ morningFlightCapacity, afternoonFlightCapacity }).map(([key, value]) => {
                        if (handler == "ALL") {
                            return _jsx("span", { children: value }, key);
                        }
                        return (_jsx(NativeSelect, { name: key, size: "sm", onChange: (event) => onDeiceHandlerSelected(event.target.value, handler, value, key), value: `${value}`, children: selectOptions(handler).map((option) => (_jsx("option", { value: option.value, children: option.label }, option.value))) }, key));
                    })] }, handler)))] }));
};
export default DeIceCapacityScheduler;
