import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { orderBy } from "lodash";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import { AvinorColor } from "@constants";
import { defaultApexChartOptions } from "@charts/apexcharts/defaultApexChartOptions";
import ToolTip from "@charts/shared/components/Tooltip/Tooltip";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useDelayCodeMetaDataContext } from "@contexts/DelayCodeMetaDataContext";
const BarChart = () => {
    const { t } = useTranslation();
    const { picker: { from }, } = useDatePickerContext();
    const { delayCodeMetaData: selectedDelayCodeMetaData } = useDelayCodeMetaDataContext(from);
    const [chartAnnotationPosition, setChartAnnotationPosition] = useState([true, true]);
    if (!selectedDelayCodeMetaData || (selectedDelayCodeMetaData === null || selectedDelayCodeMetaData === void 0 ? void 0 : selectedDelayCodeMetaData.airlineBreakdown.length) === 0)
        return _jsx(NoDataAvailable, {});
    const { airlineBreakdown, delayCode } = selectedDelayCodeMetaData;
    const sortedAirlineBreakdown = orderBy(airlineBreakdown, ["greaterthan15min", "greaterthan3min"], ["desc", "desc"]);
    const series = [
        {
            name: t("charts.onTimePerformance.bar.delayCodes.series.greaterthan3min"),
            data: sortedAirlineBreakdown.map(({ greaterthan3min }) => greaterthan3min),
        },
        {
            name: t("charts.onTimePerformance.bar.delayCodes.series.greaterthan15min"),
            data: sortedAirlineBreakdown.map(({ greaterthan15min }) => greaterthan15min),
        },
    ];
    const xaxisCategories = sortedAirlineBreakdown.map(({ airlineCode }) => airlineCode);
    const sharedStyles = {
        colors: AvinorColor.Grey900,
        fontSize: "0.875rem",
        fontWeight: "normal",
    };
    const options = {
        colors: [AvinorColor.MintGreenPrimaryLight, AvinorColor.VioletPrimaryLight],
        chart: {
            toolbar: {
                tools: {
                    download: false,
                },
            },
            fontFamily: "Roboto, sans-serif",
            type: "bar",
            stacked: true,
            zoom: {
                type: "x",
            },
            events: {
                legendClick: function (_, seriesIndex) {
                    setChartAnnotationPosition((prev) => prev.map((visible, index) => (index === seriesIndex ? !visible : visible)));
                },
            },
        },
        annotations: {
            points: sortedAirlineBreakdown.map(({ greaterthan15min, greaterthan3min, airlineCode }) => {
                const calculateTotalDelaysBasedOnLegendSelection = () => {
                    const totalDelays = (chartAnnotationPosition[0] ? greaterthan3min : 0) +
                        (chartAnnotationPosition[1] ? greaterthan15min : 0);
                    return totalDelays;
                };
                const totalDelays = calculateTotalDelaysBasedOnLegendSelection();
                if (totalDelays === 0)
                    return {};
                return {
                    x: airlineCode,
                    y: totalDelays,
                    label: {
                        style: {
                            fontSize: "0.875rem",
                        },
                        orientation: "horizontal",
                        text: `${totalDelays}`,
                        offsetY: -20,
                    },
                };
            }),
        },
        dataLabels: {
            style: {
                colors: [AvinorColor.Grey800, AvinorColor.White],
            },
        },
        grid: defaultApexChartOptions.grid,
        xaxis: {
            categories: xaxisCategories,
            title: {
                text: t("charts.onTimePerformance.bar.delayCodes.xaxisTitle.airline"),
                style: sharedStyles,
            },
            labels: {
                style: sharedStyles,
            },
        },
        yaxis: {
            title: {
                text: t("charts.onTimePerformance.bar.delayCodes.yaxisTitle"),
                style: sharedStyles,
            },
            labels: {
                formatter: function (value) {
                    if (typeof value === "string")
                        return value;
                    return Math.round(value).toString();
                },
                style: sharedStyles,
            },
        },
        legend: defaultApexChartOptions.legend,
        tooltip: {
            custom: function ({ dataPointIndex }) {
                const { airlineCode, ge15Flights, ge3Flights, greaterthan15min, greaterthan3min } = sortedAirlineBreakdown[dataPointIndex];
                const listItems = {
                    greaterthan15min,
                    greaterthan3min,
                };
                const mapKeyToColor = {
                    greaterthan15min: AvinorColor.ChartVioletLight400,
                    greaterthan3min: AvinorColor.ChartMintGreen300,
                };
                const tooltipElement = (_jsxs(ToolTip, { children: [_jsx(ToolTip.Title, { children: t("charts.onTimePerformance.bar.delayCodes.perFlight.tooltip.title", { airlineCode }) }), _jsx(ToolTip.SubTitle, { children: t("charts.onTimePerformance.bar.delayCodes.perFlight.tooltip.subTitle", { delayCode }) }), _jsxs(ToolTip.List, { wide: true, children: [_jsxs("div", { children: [ge15Flights.map((flight, i) => (_jsx(ToolTip.ListItem, { style: {
                                                type: "bullet",
                                                bgColor: AvinorColor.ChartVioletLight400,
                                            }, content: {
                                                value: flight,
                                            }, wide: true }, `${i}${flight}`))), ge3Flights.map((flight) => (_jsx(ToolTip.ListItem, { style: {
                                                type: "bullet",
                                                bgColor: AvinorColor.ChartMintGreen300,
                                            }, content: {
                                                value: flight,
                                            }, wide: true }, flight)))] }), Object.entries(listItems)
                                    .filter(([, value]) => value > 0)
                                    .map(([key, value], index) => {
                                    return (_jsx(ToolTip.ListItem, { total: true, wide: false, style: {
                                            type: "bullet",
                                            bgColor: mapKeyToColor[key],
                                        }, content: {
                                            value: t(`charts.onTimePerformance.bar.delayCodes.series.${key}`),
                                        }, status: {
                                            value,
                                        }, horizontalLine: { top: index === 0 } }, key));
                                })] })] }));
                return ReactDOMServer.renderToStaticMarkup(tooltipElement);
            },
        },
        responsive: [
            {
                breakpoint: 768,
                options: {
                    annotations: undefined,
                    xaxis: {
                        title: {
                            text: undefined,
                        },
                    },
                    yaxis: {
                        title: {
                            text: undefined,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                        },
                    },
                },
            },
        ],
    };
    return (_jsx(ReactApexChart, { options: options, series: series, type: "bar", height: 600 }));
};
export default BarChart;
