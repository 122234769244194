import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import ButtonGroup from "@components/Buttons/ButtonGroup/ButtonGroup";
import { FacilityCaseStatus, useFacilityStatusPickerContext, } from "@features/Facility/contexts/FacilityStatusPickerContext";
const FacilityStatusFilter = () => {
    var _a;
    const { selectedStatus, setSelectedStatus } = useFacilityStatusPickerContext();
    const { t } = useTranslation();
    const facilityStatusLookup = {
        [FacilityCaseStatus.ALL]: 1,
        [FacilityCaseStatus.NOW]: 2,
        [FacilityCaseStatus.PLANNED]: 3,
    };
    const buttons = [
        {
            id: 1,
            text: t("common.all"),
            onClick: () => setSelectedStatus(FacilityCaseStatus.ALL),
        },
        {
            id: 2,
            text: t("filter.facilityStatus.now"),
            onClick: () => setSelectedStatus(FacilityCaseStatus.NOW),
        },
        {
            id: 3,
            text: t("filter.facilityStatus.planned"),
            onClick: () => setSelectedStatus(FacilityCaseStatus.PLANNED),
        },
    ];
    const active = (_a = buttons.find(({ id }) => id === facilityStatusLookup[selectedStatus])) === null || _a === void 0 ? void 0 : _a.id;
    return (_jsx(ButtonGroup, { active: active, buttons: buttons, noWrap: true }));
};
export default FacilityStatusFilter;
