import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { Runway } from "@avinor/react-icons";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import { OnTimePerformanceServiceQualityRunway } from "@features/OnTimePerformance/components";
export const OnTimePerformanceServiceQualityRunwayTableWidget = () => {
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: "Runway", subtitleVisibility: "none", icon: {
                    component: (_jsx(Runway, { width: "2em", height: "2em" })),
                } }), _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsx(Widget.Body, { children: _jsx(OnTimePerformanceServiceQualityRunway, {}) }) })] }));
};
OnTimePerformanceServiceQualityRunwayTableWidget.displayName = "OnTimePerformanceServiceQualityRunwayTableWidget";
