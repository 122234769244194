import { httpFacilityStatusService } from "@services/HttpService";
import { resolveUrl } from "@utils/UrlUtils";
import { getMSALToken } from "@auth/authUtils";
export async function getFacilityStatusListData({ iata, active = true, critical = true, high = true, normal = true, low = true, inOperation = true, outOfOperation = true, scrapped = true, filterAOPareas = true, }) {
    const params = new URLSearchParams("");
    const token = await getMSALToken();
    params.set("active", active.toString());
    params.set("critical", critical.toString());
    params.set("high", high.toString());
    params.set("normal", normal.toString());
    params.set("low", low.toString());
    params.set("inOperation", inOperation.toString());
    params.set("outOfOperation", outOfOperation.toString());
    params.set("scrapped", scrapped.toString());
    params.set("filterAOPareas", filterAOPareas.toString());
    params.set("iata", iata);
    const res = await httpFacilityStatusService.get(resolveUrl({
        url: `facility-status-list?${params}`,
        mockEndpoint: "/facility-status/facility-status-list",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
}
export async function getFacilityStatusOverviewData({ iata, active = true, }) {
    const params = new URLSearchParams("");
    params.set("active", active.toString());
    params.set("iata", iata);
    const token = await getMSALToken();
    const res = await httpFacilityStatusService.get(resolveUrl({
        url: `facility-status-overview?${params}`,
        mockEndpoint: "/facility-status/facility-status-overview",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
}
export async function getFacilityStatusOverviewDetailedData({ iata, active = true, }) {
    const params = new URLSearchParams("");
    params.set("active", active.toString());
    params.set("iata", iata);
    const token = await getMSALToken();
    const res = await httpFacilityStatusService.get(resolveUrl({
        url: `facility-status-overview-detailed?${params}`,
        mockEndpoint: "/facility-status/facility-status-overview-detailed",
    }), {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
}
