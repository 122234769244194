import { jsx as _jsx } from "react/jsx-runtime";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import posthog from "posthog-js";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Delay } from "@enums";
// Auth
import authProvider from "@auth/authProvider";
import "./assets/i18n";
import { DatePickerProvider } from "@contexts/DatePickerContext";
import { GranularityProvider } from "@contexts/GranularityContext";
import { IataProvider } from "@contexts/IataContext";
import { UtcProvider } from "@contexts/UtcContext";
import App from "./App";
if (!window.location.host.includes("localhost") && !window.location.host.includes("test")) {
    posthog.init("phc_AeUfoCQg6jVWuXIi8jqCVyUY3weX6bE7TnVV3v7gFeq", {
        api_host: "https://eu.posthog.com",
        cross_subdomain_cookie: false,
        persistence: "memory",
    });
}
const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: (error) => {
            console.error("React Query Error:", error); //Output query errors to the console
        },
    }),
    mutationCache: new MutationCache({
        onError: (error) => {
            console.error("React Query Mutation Error:", error); //Output mutation errors to the console
        },
    }),
    defaultOptions: {
        queries: {
            refetchInterval: Delay.FiveMinutes, //Default polling interval
            refetchOnWindowFocus: true, // Default behavior
        },
    },
});
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById("root")).render(_jsx(BrowserRouter, { children: _jsx(GranularityProvider, { children: _jsx(DatePickerProvider, { children: _jsx(UtcProvider, { children: _jsx(IataProvider, { children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(App, { pca: authProvider }) }) }) }) }) }) }));
