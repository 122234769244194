import { jsx as _jsx } from "react/jsx-runtime";
import { endOfDay, startOfDay } from "date-fns";
import { createContext, useContext, useState } from "react";
const DatePickerContext = createContext(undefined);
export const DatePickerProvider = ({ children }) => {
    const [datePickers, setDatePickers] = useState(new Map());
    return _jsx(DatePickerContext.Provider, { value: { datePickers, setDatePickers }, children: children });
};
export const useDatePickerContext = (id) => {
    const context = useContext(DatePickerContext);
    const datePickerId = id ? id : getDatePickerIdOfPage();
    if (!context) {
        throw new Error("useDatePickerContext must be used within a DatePickerProvider");
    }
    const { datePickers, setDatePickers } = context;
    const setPicker = ({ from, to }) => {
        setDatePickers((prev) => new Map(prev).set(datePickerId, { id: datePickerId, from, to }));
    };
    const picker = {
        id: datePickerId,
        from: startOfDay(new Date()),
        to: endOfDay(new Date()),
    };
    const existingPicker = datePickers.get(datePickerId);
    if (existingPicker) {
        picker.from = startOfDay(new Date(existingPicker.from));
        picker.to = endOfDay(new Date(existingPicker.to));
    }
    return { picker, setPicker };
};
/**
 * Creates a unique ID for a datepicker based on the current window's pathname.
 * If the pathname is "/", the function returns "main-date-picker". For other pathnames, it removes the leading slash,
 * replaces any subsequent slashes with dashes, and appends "-date-picker" to the end.
 * This ID is useful for uniquely identifying datepickers in the context of different application routes.
 *
 * @returns {string} The unique atom ID for the datepicker, based on the pathname.
 */
export const getDatePickerIdOfPage = () => {
    const pathname = window.location.pathname;
    if (pathname === "/") {
        return "main-date-picker";
    }
    return pathname.replace(/^\//, "").replace(/\//g, "-") + "-date-picker";
};
export const defaultPicker = {
    id: getDatePickerIdOfPage(),
    from: startOfDay(new Date()),
    to: endOfDay(new Date()),
};
