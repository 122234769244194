import { useQuery } from "@tanstack/react-query";
import { formatDate } from "@utils/DateUtils";
import { FormatPattern } from "@enums";
import { useIataContext } from "@contexts/IataContext";
import { getRunwayConfig } from "../services";
export const useRunwayConfig = () => {
    const { iata } = useIataContext();
    const { data: runwayConfig, isLoading } = useQuery({
        queryFn: () => getRunwayConfig({
            iata,
            datetime: formatDate(false, new Date(), FormatPattern.yyyy_MM_dd_HH_mm),
        }),
        queryKey: ["getRunwayConfig", { iata }],
    });
    if (runwayConfig) {
        runwayConfig.runwayConfig.operationModeParsed = parseOperationDetails(runwayConfig.runwayConfig.operationModeName);
    }
    return { runwayConfig, isLoading };
};
const parseOperationDetails = (data) => {
    const parts = data.split(";");
    if (parts.length !== 3) {
        //The provided string does not contain the expected number of parts.
        return {};
    }
    return {
        code: parts[0].trim(),
        name: parts[1].trim(),
        description: parts[2].trim(),
    };
};
