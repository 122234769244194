import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import { formatDate, getDateToReadableText } from "@utils/DateUtils";
import Spinner from "@components/Spinner/Spinner";
import { WidgetCard } from "@components/WidgetCard/WidgetCard";
import { FormatPattern } from "@enums";
import useFlightMovementsData from "@features/FlightMovements/hooks/useFlightMovementsData";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useIataContext } from "@contexts/IataContext";
import { useUtcContext } from "@contexts/UtcContext";
import { getDeIceFlightsData } from "../services";
export const NumberOfPlanesForDeIce = ({ description }) => {
    const { picker: { from, to }, } = useDatePickerContext();
    const { iata } = useIataContext();
    const { utc } = useUtcContext();
    const { data: deIceFlightsData, isLoading: isLoadingDeice } = useQuery({
        queryFn: () => getDeIceFlightsData({
            iata,
            date: formatDate(utc, from, FormatPattern.yyyy_MM_dd),
        }),
        queryKey: ["getDeIceFlightsData", { from, iata, utc }],
    });
    const { departureFlights, isLoading: isLoadingFlightMovements } = useFlightMovementsData({ date: { from, to } });
    const readableDateRange = getDateToReadableText().toLowerCase();
    const numberOfPlanesInDeIceMode = deIceFlightsData && deIceFlightsData.deIceFlight ? deIceFlightsData.deIceFlight.length : "-";
    if (isLoadingDeice || isLoadingFlightMovements)
        return _jsx(Spinner, {});
    return (_jsx(WidgetCard, { title: `Antall via De-ice ${readableDateRange}`, description: description, underline: "header", children: `${numberOfPlanesInDeIceMode}/${departureFlights}` }));
};
NumberOfPlanesForDeIce.displayName = "NumberOfPlanesForDeIce";
