import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DropdownMenu, DropdownMenuRoot } from "@digdir/designsystemet-react";
import { useEffect, useState } from "react";
import "./LanguageSwitcher.scss";
import { useTranslation } from "react-i18next";
import { GlobeOutlined, ArrowDownOutlined, ArrowUpOutlined } from "@avinor/react-icons";
import { useMenuContext } from "@contexts/MenuContext";
const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState("nb");
    const [open, setOpen] = useState(false);
    const { setMenuOpen } = useMenuContext();
    const options = [
        { value: "nb", label: "Norsk" },
        { value: "en", label: "English" },
    ];
    useEffect(() => {
        // Check if there's a saved language in localStorage
        const savedLanguage = localStorage.getItem("language") || "nb";
        setCurrentLanguage(savedLanguage);
        i18n.changeLanguage(savedLanguage);
    }, [i18n]);
    const handleChange = (selectedValue) => {
        // Change the language
        i18n.changeLanguage(selectedValue);
        // Save the selected language to localStorage
        localStorage.setItem("language", selectedValue);
        setCurrentLanguage(selectedValue);
        setOpen(false);
    };
    const selectedLabel = currentLanguage == "en" ? "EN" : "NO";
    const toggleSwitcher = (value) => {
        if (value) {
            setMenuOpen(false);
        }
        setOpen(value);
    };
    return (_jsx("div", { className: "language-switcher", children: _jsxs(DropdownMenuRoot, { open: open, size: "sm", onClose: () => toggleSwitcher(false), children: [_jsx(DropdownMenu.Trigger, { asChild: true, onClick: () => toggleSwitcher(!open), children: _jsxs("button", { className: "language-switcher__button", children: [_jsx(GlobeOutlined, { color: "white", width: "24px", height: "24px" }), _jsx("span", { children: selectedLabel }), open ? (_jsx(ArrowUpOutlined, { color: "white", width: "10px" })) : (_jsx(ArrowDownOutlined, { color: "white", width: "10px" }))] }) }), _jsx(DropdownMenu.Content, { children: options.map((option) => (_jsx(DropdownMenu.Item, { value: option.value, onClick: () => handleChange(option.value), children: option.label }, option.value))) })] }) }));
};
export default LanguageSwitcher;
