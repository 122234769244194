import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./OnTimePerformanceServiceQualityStepper.scss";
import clsx from "clsx";
import { steps } from "./OnTimePerformanceServiceQualityStepperSteps";
export const OnTimePerformanceServiceQualityStepper = () => {
    return (_jsx("div", { className: "ontimeperformance-stepper", children: steps.map((step, index) => {
            const indicatorLevel = step.hook ? step.hook().indicatorLevel : "";
            return (_jsx("div", { className: "step-wrapper", children: _jsx("div", { className: "step", children: _jsx("div", { className: clsx("step-icon-label-wrapper", index < steps.length - 1 && "step-line-after"), children: step.icon ? (_jsxs(_Fragment, { children: [_jsx("span", { className: "step-icon", children: step.icon }), _jsx("span", { className: "step-label step-label-icon", children: step.key })] })) : (_jsxs(_Fragment, { children: [_jsx("span", { className: clsx("step-circle", {
                                        "step-circle--valid": indicatorLevel === "valid",
                                        "step-circle--alarm": indicatorLevel === "alarm",
                                        "step-circle--warning": indicatorLevel === "warning",
                                    }) }), _jsx("span", { className: "step-label", children: step.key })] })) }) }) }, index));
        }) }));
};
