import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { SuitcaseOutlined } from "@avinor/react-icons";
import Widget from "@components/Widget/Widget";
import { useIataContext } from "@contexts/IataContext";
import { BagsEnrouteTable } from "../components/BaggageEnrouteTable";
export const BaggageEnrouteTableWidget = ({ title, subTitle, footerText, }) => {
    const { iata } = useIataContext();
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t(title, { iata }), subtitle: t(subTitle, { iata }), icon: {
                    component: (_jsx(SuitcaseOutlined, { width: "1.5rem", height: "1.5rem" })),
                } }), _jsx(Widget.Body, { children: _jsx(BagsEnrouteTable, {}) }), _jsx(Widget.Footer, { children: footerText })] }));
};
BaggageEnrouteTableWidget.displayName = "BaggageEnrouteTableWidget";
