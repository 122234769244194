var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./DeIceVehicleAllocation.scss";
import { mapHandlerToName } from "@mappings/mapHandlerToName";
import { Cell } from "@table-library/react-table-library";
import { useTranslation } from "react-i18next";
import { VStack } from "@components/Layout";
import Table from "@components/Table/Table";
import { calculateDeIceVehicles } from "@features/DeIce/components/utils";
import { useDeIceWeatherContext } from "@features/DeIce/contexts/DeIceWeatherContext";
import DeIceVehiclesCalculationFormula from "../DeIceVehiclesCalculationFormula/DeIceVehiclesCalculationFormula";
const tableColumnHeaders = {
    handler: "Handler",
    morning: "00-14",
    afternoon: "14-00",
};
const headers = Object.keys(tableColumnHeaders);
const getTableColumnHeaders = () => {
    return [
        {
            headers: [
                {
                    content: tableColumnHeaders.handler,
                    key: headers[0],
                },
                {
                    content: tableColumnHeaders.morning,
                    key: headers[1],
                },
                {
                    content: tableColumnHeaders.afternoon,
                    key: headers[2],
                },
            ],
        },
    ];
};
const VehiclesCell = ({ cellIndex, value }) => {
    return (_jsxs(Cell, { children: [_jsx("span", { className: "deice-vehicle-allocation__vehicles-rounded", children: Math.ceil(value) }), _jsxs("span", { className: "deice-vehicle-allocation__vehicles", children: ["(", value, ")"] })] }, cellIndex));
};
const customCellRenderers = () => {
    return {
        morningVehicleAllocation: (cellProps) => {
            const { key } = cellProps, propsWithoutKey = __rest(cellProps, ["key"]);
            return (_jsx(VehiclesCell, Object.assign({}, propsWithoutKey), key));
        },
        afternoonVehicleAllocation: (cellProps) => {
            const { key } = cellProps, propsWithoutKey = __rest(cellProps, ["key"]);
            return (_jsx(VehiclesCell, Object.assign({}, propsWithoutKey), key));
        },
    };
};
const DeIceVehicleAllocation = ({ deIceHandlerCapacity }) => {
    const { t } = useTranslation();
    const { deIceTime } = useDeIceWeatherContext();
    const vehicleAllocations = deIceHandlerCapacity.map(({ handler, morningFlightCapacity, afternoonFlightCapacity }, index) => {
        return {
            id: index,
            handler: mapHandlerToName(handler),
            morningVehicleAllocation: calculateDeIceVehicles(morningFlightCapacity, deIceTime),
            afternoonVehicleAllocation: calculateDeIceVehicles(afternoonFlightCapacity, deIceTime),
        };
    });
    return (_jsxs(VStack, { className: "deice-vehicle-allocation", justify: "space-between", gap: 1, children: [_jsx("h4", { className: "deice-vehicle-allocation__title", children: t("deice.capacity.controlPanel.vehicleAllocation.title") }), _jsx(DeIceVehiclesCalculationFormula, {}), _jsx(Table, { data: { nodes: vehicleAllocations || [] }, headerGroups: getTableColumnHeaders(), cellRenderers: customCellRenderers(), customTheme: {
                    Table: `
                        border: 1px solid var(--avinor-grey-100);
                        border-bottom: none;
                    `,
                    HeaderCell: `
                        text-align: start;
                        border-left: none !important;
                        border-right: none !important;
                    `,
                    Cell: `
                        td {
                            border-left: none !important;
                            border-right: none !important;
                        }
                    `,
                    Row: `
                        td {
                            text-align: start;
                            border-left: none !important;
                            border-right: none !important;
                            border-bottom: 1px solid var(--avinor-grey-100);
                        }
                    `,
                } })] }));
};
export default DeIceVehicleAllocation;
