import { format } from "date-fns";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormatPattern } from "@enums";
import { getLocale } from "@assets/i18n/utils";
const useCurrentDateTime = () => {
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const { i18n } = useTranslation();
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);
    const currentTime = format(currentDateTime, FormatPattern.HH_mm);
    const currentDate = format(currentDateTime, FormatPattern.EEEE_d_MMMM, {
        locale: getLocale(i18n.language),
    });
    return { currentTime, currentDate };
};
export default useCurrentDateTime;
