import { useQuery } from "@tanstack/react-query";
import { useIataContext } from "@contexts/IataContext";
import { getStartupDelay } from "../services";
export const useStartupDelay = (props) => {
    const { from, to } = props;
    const { iata } = useIataContext();
    const { data: startupDelay, isLoading } = useQuery({
        queryFn: () => getStartupDelay({
            iata,
            from,
            to,
        }),
        queryKey: ["getStartupDelay", { iata, from, to }],
    });
    return { startupDelay, isLoading };
};
