import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
const SelectOptionsContext = createContext(undefined);
export const SelectOptionsProvider = ({ children }) => {
    const [selectOptions, setSelectOptions] = useState(new Map());
    return (_jsx(SelectOptionsContext.Provider, { value: { selectOptions, setSelectOptions }, children: children }));
};
export const useSelectOptionContext = (key) => {
    const context = useContext(SelectOptionsContext);
    if (!context) {
        throw new Error("useSelectOptionContext must be used within a SelectOptionProvider");
    }
    const { selectOptions, setSelectOptions } = context;
    const setOptions = (options) => {
        setSelectOptions((prev) => new Map(prev).set(key, options));
    };
    const options = [];
    const existingOptions = selectOptions.get(key);
    if (existingOptions) {
        options.push(...existingOptions);
    }
    return { options, setOptions };
};
