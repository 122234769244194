export const mapAirlineIataToName = (iata, i18nFallback, options) => {
    const airlineInfoMap = {
        SK: "SAS",
        DY: "Norwegian",
        WF: "Widerøe",
        N0: "Norse Atlantic Airways",
    };
    let airlineName = airlineInfoMap[iata];
    if (airlineName) {
        if ((options === null || options === void 0 ? void 0 : options.targetAirline) && airlineName === options.targetAirline && options.modifyOutput) {
            airlineName = options.modifyOutput(airlineName);
        }
        return airlineName;
    }
    else if (i18nFallback) {
        return i18nFallback();
    }
    else {
        return "Unknown IATA code";
    }
};
