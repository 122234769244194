import { jsx as _jsx } from "react/jsx-runtime";
import { isToday } from "date-fns";
import { useTranslation } from "react-i18next";
import { FlightMovementsDirectionFilterProvider } from "@features/FlightMovements/contexts/FlightMovementsDirectionFilterContext";
import { PlanDayPickerProvider } from "@features/Plan/contexts/PlanDayPickerContext";
import { PlanOverviewMeteogramWidget, PlanOverviewWeatherWidget } from "@features/Plan/widgets";
import { PlanOverviewWidgets } from "@widgets/registry";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import { useIataContext } from "@contexts/IataContext";
import PageLayout from "./PageLayout/PageLayout";
const PlanOverviewPage = () => {
    const { t } = useTranslation();
    const { iata } = useIataContext();
    const { picker: { from }, } = useDatePickerContext();
    const datePickerIsToday = isToday(from);
    let widgetsToShow = [];
    if (datePickerIsToday) {
        widgetsToShow = PlanOverviewWidgets;
    }
    else {
        widgetsToShow = PlanOverviewWidgets.filter((widget) => widget.name !== PlanOverviewWeatherWidget.displayName &&
            widget.name !== PlanOverviewMeteogramWidget.displayName);
    }
    return (_jsx(FlightMovementsDirectionFilterProvider, { children: _jsx(PlanDayPickerProvider, { children: _jsx(PageLayout, { title: t("plan.overview.title", { iata }), description: t("plan.overview.description"), widgets: widgetsToShow }) }) }));
};
export default PlanOverviewPage;
