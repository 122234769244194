import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import { getWeatherData, getTextForecast } from "@features/Plan/services";
import "./PlanOverviewWeatherWidget.scss";
import PlanOverviewWeatherWidgetTable from "./PlanOverviewWeatherWidgetTable";
export const PlanOverviewWeatherWidget = () => {
    var _a, _b;
    const { data: weatherData, isLoading: weatherLoading, error: weatherError, } = useQuery({
        queryFn: () => getWeatherData(),
        queryKey: ["getWeatherData"],
    });
    const currentTime = new Date();
    const currentHour = currentTime.getUTCHours();
    // Find the timeseries object that matches the current hour
    const currentWeather = weatherData === null || weatherData === void 0 ? void 0 : weatherData.properties.timeseries.find((timeserie) => {
        const timeserieHour = new Date(timeserie.time).getUTCHours();
        return timeserieHour === currentHour;
    });
    const { data: textForecast, isLoading: textLoading, error: textError, } = useQuery({
        queryFn: () => getTextForecast(),
        queryKey: ["getTextForecast"],
    });
    if (weatherLoading || textLoading) {
        return _jsx(Spinner, {});
    }
    if (weatherError || textError || !weatherData || !textForecast || !currentWeather) {
        return _jsx(NoDataAvailable, {});
    }
    const windDirection = currentWeather === null || currentWeather === void 0 ? void 0 : currentWeather.data.instant.details.wind_from_direction;
    const precipitation = (_a = currentWeather === null || currentWeather === void 0 ? void 0 : currentWeather.data.next_1_hours) === null || _a === void 0 ? void 0 : _a.details.precipitation_amount;
    const airTemperature = currentWeather === null || currentWeather === void 0 ? void 0 : currentWeather.data.instant.details.air_temperature;
    const windSpeed = currentWeather === null || currentWeather === void 0 ? void 0 : currentWeather.data.instant.details.wind_speed;
    const windSpeedGusts = currentWeather === null || currentWeather === void 0 ? void 0 : currentWeather.data.instant.details.wind_speed_of_gust;
    return (_jsx(Widget.Container, { children: _jsx(Widget.Body, { children: _jsxs("div", { className: "WeatherWidget-content", children: [_jsx("h2", { children: "V\u00E6rvarsel: Oslo Lufthavn" }), _jsxs("div", { className: "WeatherWidget-wrapper", children: [_jsxs("div", { className: "WeatherWidget-weather-info", children: [_jsxs("div", { className: "WeatherWidget-temperature-icon", children: [_jsx("img", { className: "WeatherWidget-weather-icon", src: `/weather-icons/${(_b = currentWeather === null || currentWeather === void 0 ? void 0 : currentWeather.data.next_1_hours) === null || _b === void 0 ? void 0 : _b.summary.symbol_code}.svg`, alt: "weather icon" }), _jsxs("span", { className: "WeatherWidget-temperature" +
                                                    (airTemperature <= 0 ? " WeatherWidget-temperature-cold" : ""), children: [Math.round(airTemperature), "\u00B0"] })] }), _jsxs("div", { className: "WeatherWidget-side-info-wrapper", children: [_jsxs("div", { className: "WeatherWidget-side-info", children: [_jsx("img", { className: "WeatherWidget-side-info-icon", src: `/weather-icons/umbrella.svg`, alt: "weather icon" }), _jsxs("span", { className: "WeatherWidget-precipitation-info", children: [precipitation, " mm"] })] }), _jsxs("div", { className: "WeatherWidget-side-info", children: [_jsx("img", { className: "WeatherWidget-side-info-icon", src: `/weather-icons/wind.svg`, alt: "weather icon" }), _jsxs("span", { className: "WeatherWidget-wind-info", children: [Math.round(windSpeed), " (", Math.round(windSpeedGusts), ") m/s"] }), _jsx("div", { style: { transform: `rotate(${windDirection}deg)` }, className: "WeatherWidget-side-info-wind-direction", children: _jsx("img", { className: "WeatherWidget-side-info-wind-direction-icon", src: `/weather-icons/wind_direction_arrow.svg`, alt: "weather icon" }) })] })] })] }), _jsx(PlanOverviewWeatherWidgetTable, {}), _jsxs("div", { style: { marginTop: "20px" }, children: [_jsx("h3", { children: "V\u00E6rforhold \u00D8stlandet" }), _jsx("p", { children: textForecast.text })] })] })] }) }) }));
};
PlanOverviewWeatherWidget.displayName = "PlanOverviewWeatherWidget";
