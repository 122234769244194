import { jsx as _jsx } from "react/jsx-runtime";
import "./AlertIndicator.scss";
import clsx from "clsx";
const AlertIndicator = ({ value, variant, styles }) => {
    const styleSheet = {
        alertIndicator: clsx("alert-indicator", {
            "alert-indicator--good": variant === "good",
            "alert-indicator--warning": variant === "warning",
            "alert-indicator--alarm": variant === "alarm",
        }),
    };
    return (_jsx("span", { style: styles, className: styleSheet.alertIndicator, children: value }));
};
export default AlertIndicator;
