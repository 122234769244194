import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { ExclamationSquareOutlined } from "@avinor/react-icons";
import Widget from "@components/Widget/Widget";
import { FacilityErrorCount } from "../components";
export const FacilityErrorCountWidget = () => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t("facility.errorCount.title"), subtitle: t("facility.errorCount.subtitle"), icon: {
                    component: (_jsx(ExclamationSquareOutlined, { width: "2em", height: "2em" })),
                } }), _jsx(Widget.Body, { children: _jsx(FacilityErrorCount, {}) })] }));
};
FacilityErrorCountWidget.displayName = "FacilityErrorCountWidget";
