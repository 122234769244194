import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { format } from "date-fns";
import { nb } from "date-fns/locale";
import { t } from "i18next";
import { removeZuluSuffix } from "@utils/DateUtils";
import { AvinorColor } from "@constants";
import { FormatPattern } from "@enums";
import { mapPlaneBodySizeToColor } from "@mappings";
import { EDeIceHandler } from "@features/DeIce/enums";
import ToolTip from "@charts/shared/components/Tooltip/Tooltip";
const removeDuplicateFlights = (flights) => {
    return flights.filter((flight, i, self) => {
        return self.findIndex((f) => f.flightID === flight.flightID) === i;
    });
};
const groupFlightsByHandler = (flights) => {
    const filterByHandler = (handler) => flights.filter((flight) => flight.handler === handler);
    return {
        sghHandlerFlights: filterByHandler(EDeIceHandler.SGH),
        wghHandlerFlights: filterByHandler(EDeIceHandler.WGH),
        mnzHandlerFlights: filterByHandler(EDeIceHandler.MENZIES),
        otherHandlerFlights: filterByHandler(EDeIceHandler.OTHER),
    };
};
const formatTimeSegment = (date, utc, formatPattern = FormatPattern.HH_mm) => {
    const formattedDate = format(new Date(utc ? removeZuluSuffix(date) : date), formatPattern, { locale: nb });
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};
const formatDeIceHandler = (handler) => (handler === EDeIceHandler.OTHER ? t("common.unknown") : handler);
const handlerFlightsList = ({ handler, flights, utc, }) => {
    if (flights.length === 0)
        return null;
    const uniqueFlights = removeDuplicateFlights(flights);
    return (_jsxs("div", { style: { padding: "0.5em 0" }, children: [_jsxs("h3", { children: [formatDeIceHandler(handler), " (", uniqueFlights.length, ")"] }), uniqueFlights.map((flight) => {
                return (_jsx(ToolTip.ListItem, { content: {
                        value: `${formatTimeSegment(flight.offBlockTime, utc)} - ${flight.flightID}`,
                        color: AvinorColor.Grey200,
                    }, status: {
                        value: flight.planeBodySize ? flight.planeBodySize : "N/A",
                        color: mapPlaneBodySizeToColor(flight.planeBodySize),
                    } }, flight.flightID));
            })] }));
};
const CustomTooltip = ({ dataPointIndex, timeSegments, utc, }) => {
    const { sghHandlerFlights, wghHandlerFlights, mnzHandlerFlights, otherHandlerFlights } = groupFlightsByHandler(timeSegments[dataPointIndex].flights);
    const from = formatTimeSegment(timeSegments[dataPointIndex].from, utc);
    const to = formatTimeSegment(timeSegments[dataPointIndex].to, utc);
    return (_jsxs(ToolTip, { children: [_jsx(ToolTip.Title, { children: formatTimeSegment(timeSegments[dataPointIndex].from, utc, FormatPattern.iiii_d_MMMM) }), _jsxs(ToolTip.SubTitle, { children: [from, " - ", to] }), _jsxs(ToolTip.List, { children: [handlerFlightsList({ handler: EDeIceHandler.WGH, flights: wghHandlerFlights, utc }), handlerFlightsList({ handler: EDeIceHandler.SGH, flights: sghHandlerFlights, utc }), handlerFlightsList({ handler: EDeIceHandler.MENZIES, flights: mnzHandlerFlights, utc }), handlerFlightsList({ handler: EDeIceHandler.OTHER, flights: otherHandlerFlights, utc })] })] }));
};
export default CustomTooltip;
