import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./FacilityStatusTableControlPanel.scss";
import FacilityStatusFilter from "@components/Filters/FacilityStatusFilter/FacilityStatusFilter";
import Search from "@components/Search/Search";
import Select from "@components/Select/Select";
const FilterByObjectType = () => {
    return null;
    return (_jsx(Select, { className: "facility-status-table-control-panel__select", label: "Filtrer p\u00E5 objekttype" }));
};
const FilterByArea = () => {
    return null;
    const options = [
        {
            value: "airside",
            label: "Flyside",
        },
        {
            value: "landside",
            label: "Landside",
        },
    ];
    return (_jsx(Select, { className: "facility-status-table-control-panel__select", label: "Filtrer p\u00E5 omr\u00E5de", options: options }));
};
const FacilityStatusTableControlPanel = ({ setFacilitySearchQuery }) => {
    return (_jsxs("div", { className: "facility-status-table-control-panel", children: [_jsx(Search, { placeholder: "S\u00F8k", className: "facility-status-table-control-panel__search", onChange: setFacilitySearchQuery }), _jsx(FilterByArea, {}), _jsx(FilterByObjectType, {}), _jsx(FacilityStatusFilter, {})] }));
};
export default FacilityStatusTableControlPanel;
