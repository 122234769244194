import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import ButtonGroup from "@components/Buttons/ButtonGroup/ButtonGroup";
import { FlightMovementsDirection } from "@enums";
import { useFlightMovementsDirectionFilter } from "@features/FlightMovements/contexts/FlightMovementsDirectionFilterContext";
const FlightMovementsDirectionFilter = () => {
    var _a;
    const { filter, setFilter } = useFlightMovementsDirectionFilter();
    const { t } = useTranslation();
    const directionLookup = {
        [FlightMovementsDirection.All]: 1,
        [FlightMovementsDirection.Arrival]: 2,
        [FlightMovementsDirection.Departure]: 3,
    };
    const buttons = filter.directions.map((direction, index) => ({
        id: index + 1,
        text: t(`common.${direction.toLowerCase()}`),
        onClick: () => setFilter({
            directions: filter.directions,
            direction,
        }),
    }));
    const active = (_a = buttons.find(({ id }) => id === directionLookup[filter.direction])) === null || _a === void 0 ? void 0 : _a.id;
    return (_jsx(ButtonGroup, { active: active, buttons: buttons }));
};
export default FlightMovementsDirectionFilter;
