export const useSelectOptions = (selectedOptions, data, createOption, excludeKeys) => {
    var _a;
    const currentSelectedOptionKeys = new Set(selectedOptions === null || selectedOptions === void 0 ? void 0 : selectedOptions.map((item) => item.value));
    excludeKeys === null || excludeKeys === void 0 ? void 0 : excludeKeys.forEach((key) => currentSelectedOptionKeys.add(key));
    const mapKeysToOptions = (item) => {
        return Object.keys(item)
            .filter((key) => !currentSelectedOptionKeys.has(key))
            .map((key) => createOption(key));
    };
    const options = (_a = data === null || data === void 0 ? void 0 : data.flatMap(mapKeysToOptions)) !== null && _a !== void 0 ? _a : [];
    const uniqueOptions = Array.from(options.reduce((acc, option) => acc.set(option.value, option), new Map())).map(([, option]) => option);
    return {
        options: uniqueOptions,
    };
};
