import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import { UserDomains } from "@auth/hooks/useEmailDomain";
import { DelayTopFiveDelayCodesPieChart } from "../components";
export const DelayTopFiveDelayCodesPieChartWidget = () => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t("onTimePerformance.delay.widgets.top5delayCodes.title") }), _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsx(Widget.Body, { children: _jsx(DelayTopFiveDelayCodesPieChart, {}) }) })] }));
};
DelayTopFiveDelayCodesPieChartWidget.displayName = "DelayTopFiveDelayCodesPieChartWidget";
DelayTopFiveDelayCodesPieChartWidget.permissions = { requiredDomain: UserDomains.Avinor };
