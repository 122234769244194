import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { OSL_RWY_0 } from "./OnTimePerformanceServiceQualityRunwayImages/OSL_RWY_0";
import { OSL_RWY_1 } from "./OnTimePerformanceServiceQualityRunwayImages/OSL_RWY_1";
import { OSL_RWY_A01LD01L } from "./OnTimePerformanceServiceQualityRunwayImages/OSL_RWY_A01LD01L";
import { OSL_RWY_A01R } from "./OnTimePerformanceServiceQualityRunwayImages/OSL_RWY_A01R";
import { OSL_RWY_A01RD01R } from "./OnTimePerformanceServiceQualityRunwayImages/OSL_RWY_A01RD01R";
import { OSL_RWY_A19LD19L } from "./OnTimePerformanceServiceQualityRunwayImages/OSL_RWY_A19LD19L";
import { OSL_RWY_A19R } from "./OnTimePerformanceServiceQualityRunwayImages/OSL_RWY_A19R";
import { OSL_RWY_A19RD19R } from "./OnTimePerformanceServiceQualityRunwayImages/OSL_RWY_A19RD19R";
import { OSL_RWY_D01L } from "./OnTimePerformanceServiceQualityRunwayImages/OSL_RWY_D01L";
import { OSL_RWY_D19L } from "./OnTimePerformanceServiceQualityRunwayImages/OSL_RWY_D19L";
export var RunwayPosition;
(function (RunwayPosition) {
    RunwayPosition[RunwayPosition["Left"] = 0] = "Left";
    RunwayPosition[RunwayPosition["Right"] = 1] = "Right";
})(RunwayPosition || (RunwayPosition = {}));
export const OnTimePerformanceServiceQualityRunwayVisualizationImg = ({ runwayState, runwayPosition }) => {
    // Mapping of runway states to their respective SVG components
    // The svg components follow the following naming convention: [AIRPORT]_RWY_A[Arrival]D[Departure]
    const runwayImageMappings = [
        { arrival: "01L", departure: "01L", position: 0, Component: OSL_RWY_A01LD01L },
        { arrival: "01R", departure: "01R", position: 1, Component: OSL_RWY_A01RD01R },
        { arrival: "19R", departure: "19R", position: 0, Component: OSL_RWY_A19RD19R },
        { arrival: "19L", departure: "19L", position: 1, Component: OSL_RWY_A19LD19L },
        { arrival: "", departure: "01L", position: 0, Component: OSL_RWY_D01L },
        { arrival: "01R", departure: "", position: 1, Component: OSL_RWY_A01R },
        { arrival: "19R", departure: "", position: 0, Component: OSL_RWY_A19R },
        { arrival: "", departure: "19L", position: 1, Component: OSL_RWY_D19L },
        /*These situations are not handled right now.
        { arrival: "01L", departure: "", position: 0, Component: OSL_RWY_0 },
        { arrival: "", departure: "01R", position: 1, Component: OSL_RWY_1 },
        { arrival: "", departure: "19R", position: 0, Component: OSL_RWY_0 },
        { arrival: "19L", departure: "", position: 1, Component: OSL_RWY_1 },
        */
        { arrival: "", departure: "", position: 0, Component: OSL_RWY_0 },
        { arrival: "", departure: "", position: 1, Component: OSL_RWY_1 },
    ];
    // Find the matching runway image based on the current state and position
    const runwayImage = runwayImageMappings.find(({ arrival, departure, position }) => {
        if (position !== runwayPosition)
            return false;
        const matchesArrival = !arrival || (runwayState === null || runwayState === void 0 ? void 0 : runwayState.arrival.includes(arrival));
        const matchesDeparture = !departure || (runwayState === null || runwayState === void 0 ? void 0 : runwayState.departure.includes(departure));
        return matchesArrival && matchesDeparture;
    });
    // Render the found image component or a placeholder if not found
    return runwayImage ? _jsx(runwayImage.Component, {}) : _jsx(_Fragment, {});
};
