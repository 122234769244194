import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GoogleMap, LoadScript, TrafficLayer, Marker } from "@react-google-maps/api";
import { createCircleIcon } from "./AirportRoadDelaysGoogleMapsMarker";
import { MapsStyling } from "./AirportRoadDelaysGoogleMapsStyling";
const containerStyle = {
    width: "100%",
    height: "550px",
    border: "1px solid #d7dee4",
};
const osloAirport = {
    lat: 60.19755,
    lng: 11.100415,
};
const MapComponent = ({ apiKey, coordinates }) => {
    return (_jsx(LoadScript, { googleMapsApiKey: apiKey, children: _jsxs(GoogleMap, { mapContainerStyle: containerStyle, options: {
                styles: MapsStyling,
            }, center: osloAirport, zoom: 10, children: [_jsx(TrafficLayer, {}), _jsx(Marker, { position: osloAirport, label: {
                        text: "Oslo Lufthavn", // The label text
                        fontSize: "12px", // Text size
                    } }), coordinates.map((coordinate) => (_jsx(Marker, { position: coordinate, icon: createCircleIcon(coordinate.id) }, coordinate.id)))] }) }));
};
export default MapComponent;
