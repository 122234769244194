import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState, useEffect } from "react";
import usePlanMetadata from "../hooks/usePlanMetadata";
const PlanProcessTypeContext = createContext(undefined);
export const PlanProcessTypeProvider = ({ children }) => {
    const [companyName, setCompanyName] = useState("");
    const [planProcessType, setPlanProcessType] = useState({});
    const { planMetaData } = usePlanMetadata();
    const setPlanProcessTypeById = (planProcessTypeId) => {
        var _a;
        const planType = ((_a = planMetaData === null || planMetaData === void 0 ? void 0 : planMetaData.processTypes) === null || _a === void 0 ? void 0 : _a.find((pt) => pt.id === planProcessTypeId)) || {};
        setPlanProcessType(planType);
    };
    useEffect(() => {
        setPlanProcessType({});
    }, [companyName]);
    return (_jsx(PlanProcessTypeContext.Provider, { value: {
            planProcessType,
            setPlanProcessType,
            setPlanProcessTypeById,
            companyName,
            setCompanyName,
        }, children: children }));
};
export const usePlanProcessTypeContext = () => {
    const context = useContext(PlanProcessTypeContext);
    if (!context) {
        throw new Error("usePlanProcessTypeContext must be used within a PlanProcessTypeProvider");
    }
    return context;
};
