import { useQuery } from "@tanstack/react-query";
import { subHours } from "date-fns";
import { formatDate } from "@utils/DateUtils";
import { FormatPattern } from "@enums";
import { useIataContext } from "@contexts/IataContext";
import { evaluateAlertIndicatorLevelInboundTaxiTime, evaluateAlertIndicatorLevelOutboundTaxiTime, } from "../components/OnTimePerformanceServiceQuality/OnTimePerformanceServiceQualityTableUtils";
import { getTaxiTime } from "../services";
export const useTaxiTime = (props) => {
    const { from, to, direction } = props;
    const { iata } = useIataContext();
    const { data, isLoading } = useQuery({
        queryFn: () => getTaxiTime({
            iata,
            from,
            to,
            direction,
        }),
        queryKey: ["getTaxiTime", { iata, from, to, direction }],
    });
    return { data, isLoading };
};
export const useInboundTaxitime = () => {
    const { data, isLoading } = useTaxiTime({
        from: formatDate(false, subHours(new Date(), 1), FormatPattern.yyyy_MM_dd_HH_mm),
        to: formatDate(false, new Date(), FormatPattern.yyyy_MM_dd_HH_mm),
        direction: "A",
    });
    const indicatorLevel = evaluateAlertIndicatorLevelInboundTaxiTime((data === null || data === void 0 ? void 0 : data.taxiTimeAvg) || 0);
    return { data, isLoading, indicatorLevel };
};
export const useOutboundTaxitime = () => {
    const { data, isLoading } = useTaxiTime({
        from: formatDate(false, subHours(new Date(), 1), FormatPattern.yyyy_MM_dd_HH_mm),
        to: formatDate(false, new Date(), FormatPattern.yyyy_MM_dd_HH_mm),
        direction: "D",
    });
    const indicatorLevel = evaluateAlertIndicatorLevelOutboundTaxiTime((data === null || data === void 0 ? void 0 : data.taxiTimeAvg) || 0);
    return { data, isLoading, indicatorLevel };
};
