import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
const FlightStagesContext = createContext(undefined);
export const FlightStagesProvider = ({ children }) => {
    const [flightStages, setFlightStages] = useState(new Map());
    return (_jsx(FlightStagesContext.Provider, { value: { flightStages, setFlightStages }, children: children }));
};
export const useFlightStageContext = (flightStageKey) => {
    var _a;
    const context = useContext(FlightStagesContext);
    if (!context) {
        throw new Error("useFlightStageContext must be used within a FlightStageProvider");
    }
    const { flightStages, setFlightStages } = context;
    const setStages = (stages) => {
        setFlightStages((prev) => new Map(prev).set(flightStageKey, stages));
    };
    const stages = (_a = flightStages.get(flightStageKey)) !== null && _a !== void 0 ? _a : ["domestic", "international", "schengen"];
    return { stages, setStages };
};
