import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "@digdir/designsystemet-theme";
import "@digdir/designsystemet-css";
import "./App.scss";
import { SignInPage } from "@pages";
import { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Header from "@components/Header/Header";
import Spinner from "@components/Spinner/Spinner";
const UnauthenticatedApp = () => {
    return (_jsxs("div", { className: "app unauthorized-app", children: [_jsx(Header, {}), _jsx("div", { className: "app__body", children: _jsx("main", { className: "app__body__main", children: _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsxs(Routes, { children: [_jsx(Route, { path: "/login", element: _jsx(SignInPage, {}) }), _jsx(Route, { path: "/signin", element: _jsx(SignInPage, {}) }), _jsx(Route, { path: "/", element: _jsx(SignInPage, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/signin", replace: true }) })] }) }) }) })] }));
};
export default UnauthenticatedApp;
