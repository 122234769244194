import useDelayNumbersData from "./useDelayNumbersData";
export const useDelayNumbers = () => {
    const { data, isLoading } = useDelayNumbersData();
    const delayGreaterThanOrEqualTo3min = data && data.NoDelaysForDateGeEq3 !== 0 ? data.NoDelaysForDateGeEq3 : "-";
    const delayGreaterThanOrEqualTo15min = data && data.NoDelaysForDateGeEq15 !== 0 ? data.NoDelaysForDateGeEq15 : "-";
    const totalDelays = data && data.NoDelaysForDate !== 0 ? data.NoDelaysForDate : "-";
    return {
        delayGreaterThanOrEqualTo3min,
        delayGreaterThanOrEqualTo15min,
        totalDelays,
        isLoading,
    };
};
