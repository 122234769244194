import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Widget from "@components/Widget/Widget";
import { FacilityStatusTable } from "../components";
import FacilityStatusTableControlPanel from "../components/FacilityStatusTable/FacilityStatusTableControlPanel/FacilityStatusTableControlPanel";
import { FacilityStatusPickerProvider } from "../contexts/FacilityStatusPickerContext";
export const FacilityStatusTableWidget = () => {
    const [facilitySearchQuery, setFacilitySearchQuery] = useState("");
    const { t } = useTranslation();
    return (_jsx(FacilityStatusPickerProvider, { children: _jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t("facility.statusTable.title"), subtitle: t("facility.statusTable.subtitle") }), _jsx(FacilityStatusTableControlPanel, { setFacilitySearchQuery: setFacilitySearchQuery }), _jsx(Widget.Body, { style: {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        gap: "1em",
                        overflowX: "auto",
                    }, children: _jsx(FacilityStatusTable, { facilitySearchQuery: facilitySearchQuery }) })] }) }));
};
FacilityStatusTableWidget.displayName = "FacilityStatusTableWidget";
