export const isMock = () => {
    try {
        return process.env.MOCK == "true";
    }
    catch (e) {
        return false;
    }
};
export const resolveUrl = (options) => {
    return isMock() ? `http://localhost:3000${options.mockEndpoint}` : `${options.url}`;
};
