import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
const ScreenModeContext = createContext(undefined);
export const ScreenModeProvider = ({ children }) => {
    const { pathname } = useLocation();
    const [screenMode, setScreenMode] = useState("STANDARD_MODE");
    useEffect(() => {
        setScreenMode(pathname === "/tv" ? "FULLSCREEN_MODE" : "STANDARD_MODE");
    }, [pathname]);
    return _jsx(ScreenModeContext.Provider, { value: { screenMode }, children: children });
};
export const useScreenMode = () => {
    const context = useContext(ScreenModeContext);
    if (!context) {
        throw new Error("useScreenMode must be used within a ScreenModeProvider");
    }
    return context;
};
