import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./OnTimePerformanceServiceQualityRunway.scss";
import { Cell } from "@table-library/react-table-library";
import Spinner from "@components/Spinner/Spinner";
import Table from "@components/Table/Table";
import { addIdToObj } from "@components/Table/utils";
import Heading from "@components/typography/Heading/Heading";
import { useRunwayConfig } from "@features/AirsidePerformance/hooks/useRunwayConfig";
import { OnTimePerformanceTimeframeText, headerGroups } from "../OnTimePerformanceServiceQualityTableUtils";
import { OnTimePerformanceServiceQualityRunwayVisualization } from "./OnTimePerformanceServiceQualityRunwayVisualization";
import "../OnTimePerformanceServiceQualityTableStyles.scss";
const cellRenderers = {
    performanceMetrics: ({ cellIndex, value, gridColumnStart, gridColumnEnd }) => {
        return (_jsx(Cell, { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, children: value }, cellIndex));
    },
};
export const OnTimePerformanceServiceQualityRunway = () => {
    const { runwayConfig, isLoading } = useRunwayConfig();
    const nodes = [
        {
            performanceMetrics: (_jsx(OnTimePerformanceTimeframeText, { text: "Arrival Runway", timeFrame: "next five flights" })),
            value: (runwayConfig === null || runwayConfig === void 0 ? void 0 : runwayConfig.runwayConfig.arrival.join(" / ")) || "-",
        },
        {
            performanceMetrics: (_jsx(OnTimePerformanceTimeframeText, { text: "Departure Runway", timeFrame: "next five flights" })),
            value: (runwayConfig === null || runwayConfig === void 0 ? void 0 : runwayConfig.runwayConfig.departure.join(" / ")) || "-",
        },
    ];
    if (isLoading)
        return _jsx(Spinner, {});
    return (_jsxs("div", { className: "otp-service-quality-runway", children: [_jsx(OnTimePerformanceServiceQualityRunwayVisualization, { runwayConfig: runwayConfig ? runwayConfig : null }), _jsxs("div", { className: "otp-service-quality-table-wrapper", children: [_jsx(Heading, { level: "1", size: "medium", children: runwayConfig === null || runwayConfig === void 0 ? void 0 : runwayConfig.runwayConfig.operationModeParsed.code }), _jsx("h3", { className: "otp-service-quality-table-wrapper__subtitle", children: runwayConfig === null || runwayConfig === void 0 ? void 0 : runwayConfig.runwayConfig.operationModeParsed.name }), _jsx("p", { className: "otp-service-quality-table-wrapper__description", children: runwayConfig === null || runwayConfig === void 0 ? void 0 : runwayConfig.runwayConfig.operationModeParsed.description }), _jsx(Table, { data: addIdToObj(nodes), headerGroups: headerGroups(), headerVisibility: "hidden", cellRenderers: cellRenderers, customTheme: {
                            Cell: `
                    text-align: start;

                    & > div {
                        margin-left: 0.5rem;
                    }
                `,
                        } })] })] }));
};
