import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { PlaneUpRightOutlined } from "@avinor/react-icons";
import Widget from "@components/Widget/Widget";
import { PlanOverviewAccordion } from "@features/Plan/components/PlanOverview/PlanOverviewAccordion";
export const PlanOverviewProcessStatusesWidget = () => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t("plan.create.statusWidget.title"), subtitle: t("plan.create.statusWidget.subtitle"), icon: {
                    component: (_jsx(PlaneUpRightOutlined, { width: "1.5rem", height: "1.5rem" })),
                } }), _jsx(Widget.Body, { children: _jsx("div", { children: _jsx(PlanOverviewAccordion, {}) }) })] }));
};
PlanOverviewProcessStatusesWidget.displayName = "PlanOverviewProcessStatusesWidget";
