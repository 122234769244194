import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
export var FacilityCaseStatus;
(function (FacilityCaseStatus) {
    FacilityCaseStatus["ALL"] = "All";
    FacilityCaseStatus["PLANNED"] = "Queued";
    FacilityCaseStatus["NOW"] = "Open";
})(FacilityCaseStatus || (FacilityCaseStatus = {}));
const FacilityStatusPickerContext = createContext(undefined);
export const FacilityStatusPickerProvider = ({ children }) => {
    const [selectedStatusState, setSelectedStatusState] = useState(FacilityCaseStatus.ALL);
    return (_jsx(FacilityStatusPickerContext.Provider, { value: { selectedStatus: selectedStatusState, setSelectedStatus: setSelectedStatusState }, children: children }));
};
export const useFacilityStatusPickerContext = () => {
    const context = useContext(FacilityStatusPickerContext);
    if (!context) {
        throw new Error("useFacilityStatusPickerContext must be used within a FacilityStatusPickerProvider");
    }
    return context;
};
