import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Widget from "@components/Widget/Widget";
import { Delay } from "@enums";
import "./PlanOverviewMeteogramWidget.scss"; // Ensure to include your CSS file
export const PlanOverviewMeteogramWidget = () => {
    const [key, setKey] = useState(0);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setKey((prevKey) => prevKey + 1); // Increment key to force re-render
        }, Delay.FiveMinutes);
        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);
    // Example data for tooltips
    return (_jsx(Widget.Container, { children: _jsx(Widget.Body, { children: _jsx("div", { className: "meteogram-widget-wrapper", children: _jsx("div", { className: "meteogram-widget", children: _jsx("img", { src: "https://www.yr.no/nb/innhold/1-86450/meteogram.svg", alt: "Meteogram widget showing weather forecast for the next three days in a graph", className: "meteogram-widget-image" }, key) }) }) }) }));
};
PlanOverviewMeteogramWidget.displayName = "PlanOverviewMeteogramWidget";
