import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import { OnTimePerformanceServiceQualityStepper } from "@features/OnTimePerformance/components";
export const OnTimePerformanceServiceQualityStepperWidget = () => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { children: [_jsx(Widget.Header, { title: t("onTimePerformance.processes.stepper.title"), subtitle: t("onTimePerformance.processes.stepper.subtitle") }), _jsx(Suspense, { fallback: _jsx(Spinner, {}), children: _jsx(Widget.Body, { style: { overflowX: "auto" }, children: _jsx(OnTimePerformanceServiceQualityStepper, {}) }) })] }));
};
OnTimePerformanceServiceQualityStepperWidget.displayName = "OnTimePerformanceServiceQualityStepperWidget";
