import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { PlanEditingProvider } from "@features/Plan/contexts/PlanEditingContext";
import { PlanProcessTypeProvider } from "@features/Plan/contexts/PlanProcessTypeContext";
import { PlanCreateWidgets } from "@widgets/registry";
import { useIataContext } from "@contexts/IataContext";
import PageLayout from "./PageLayout/PageLayout";
const PlanCreatePage = () => {
    const { t } = useTranslation();
    const { iata } = useIataContext();
    return (_jsx(PlanEditingProvider, { children: _jsx(PlanProcessTypeProvider, { children: _jsx(PageLayout, { title: t("plan.create.title", { iata }), description: t("plan.create.description"), widgets: PlanCreateWidgets }) }) }));
};
export default PlanCreatePage;
