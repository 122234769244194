import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { httpVegvesenDatexService } from "@services/HttpService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Expandable from "@components/Expandable/Expandable";
import { Grid } from "@components/Layout";
import Spinner from "@components/Spinner/Spinner";
import Heading from "@components/typography/Heading/Heading";
import { breakpoints } from "@constants";
import { useMediaQuery } from "@hooks";
import config from "../../../../../config";
import MapComponent from "./AirportRoadDelaysGoogleMaps";
import { createCircleIcon } from "./AirportRoadDelaysGoogleMapsMarker";
import { fetchTrafficCameraUrls } from "./AirportRoadDelaysService";
import VideoPlayer from "./AirportRoadDelaysVideoPlayer";
const CameraHeader = ({ camera }) => {
    return (_jsx(_Fragment, { children: _jsxs("div", { style: { display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }, children: [_jsxs("div", { children: [_jsx(Heading, { level: "3", size: "xsmall", children: `${camera.road ? camera.road : ""} ${camera.title}` }), _jsxs("p", { children: ["Retning: ", camera.direction] })] }), _jsx("img", { src: createCircleIcon(camera.id).url })] }) }));
};
const CamerasExpanded = ({ putCamerasOnMap }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [cameraUrls, setCameraUrls] = useState([]);
    const [currentTimeStamp, setCurrentTimeStamp] = useState(Date.now());
    //These are the camera ids for the cameras we want to show.
    //These IDs are fetched from the Vegvesen API.
    //Click on the camera you want to show on the map, and copy the ID from the URL. Check the following page:
    //https://www.vegvesen.no/trafikk/kart#/weatherCctv/3000908_1?lat=60.1978&lng=11.04409&zoom=9&layer=fer,tra,ctv,tfl
    const cameraIds = ["0529016_1", "0229004_1", "0229019_1", "3000908_1"];
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
    const { t } = useTranslation();
    const setCameras = () => {
        if (isExpanded) {
            httpVegvesenDatexService
                .get("")
                .then((response) => response.data)
                .then((data) => fetchTrafficCameraUrls(data, cameraIds))
                .then((cameraUrls) => {
                setCameraUrls(cameraUrls);
                putCamerasOnMap(cameraUrls);
            })
                .catch((error) => {
                console.error("Error:", error);
            })
                .finally(() => {
                setIsLoading(false);
            });
        }
    };
    useEffect(() => {
        setCameras();
        //Refresh images each minute
        const interval = setInterval(() => {
            setCurrentTimeStamp(Date.now());
        }, 60000);
        //Clearing the interval
        return () => clearInterval(interval);
    }, [isExpanded]);
    return (_jsxs(Expandable, { onToggle: () => setIsExpanded((prevIsExpanded) => !prevIsExpanded), isExpanded: isExpanded, buttonText: {
            expanded: t("airportTransportation.roadDelays.cameraToggleButton.hide"),
            collapsed: t("airportTransportation.roadDelays.cameraToggleButton.show"),
        }, children: [isExpanded && !isLoading && (_jsx(Grid, { columns: isMobile ? 1 : isTablet ? 2 : 4, style: { marginBottom: "1rem" }, children: cameraUrls.map((camera) => {
                    if (camera.videoUrl) {
                        return (_jsxs("div", { children: [_jsx(CameraHeader, { camera: camera }), _jsx(VideoPlayer, { src: camera.videoUrl })] }, camera.videoUrl));
                    }
                    else if (camera.stillImageUrl) {
                        return (_jsxs("div", { children: [_jsx(CameraHeader, { camera: camera }), _jsx("a", { href: camera.link, target: "_blank", rel: "noreferrer", children: _jsx("img", { src: camera.stillImageUrl + "?t=" + currentTimeStamp, alt: "Traffic image", width: "100%", height: "auto" }) })] }, camera.stillImageUrl));
                    }
                }) })), isExpanded && isLoading && _jsx(Spinner, {})] }));
};
const AirportRoadDelays = () => {
    const [camerasCoordinates, setCamerasCoordinates] = useState([]);
    const putCamerasOnMap = (cameras) => {
        setCamerasCoordinates(cameras.map((x) => {
            return {
                id: x.id,
                lat: x.coordinates.lat,
                lng: x.coordinates.lng,
            };
        }));
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { style: { border: 0, marginBottom: "2rem" }, children: _jsx(MapComponent, { apiKey: config.GOOGLE_MAPS_API_KEY, coordinates: camerasCoordinates }) }), _jsx(CamerasExpanded, { putCamerasOnMap: putCamerasOnMap })] }));
};
AirportRoadDelays.displayName = "AirportRoadDelays";
export default AirportRoadDelays;
