import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, Paragraph } from "@digdir/designsystemet-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "@utils/DateUtils";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import Spinner from "@components/Spinner/Spinner";
import { FormatPattern } from "@enums";
import useProcessStatus from "@features/Plan/hooks/useProcessStatus";
import { useUtcContext } from "@contexts/UtcContext";
import PlanCreateCalendar from "./PlanCreateCalendar";
import "./PlanCreateModal.scss";
export const PlanCreateModal = ({ modalRef, processTypeId, setProcessTypeId, planDate }) => {
    const { t } = useTranslation();
    const { utc } = useUtcContext();
    const [selectedDay, setSelectedDay] = useState(planDate);
    const selectedDayFormatted = formatDate(utc, selectedDay, FormatPattern.yyyy_MM_dd);
    const { processStatus, isLoading } = useProcessStatus({
        date: selectedDayFormatted,
        processTypeId: processTypeId,
    });
    const closeModal = () => {
        var _a;
        (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.close();
    };
    const onClose = () => {
        setProcessTypeId(null);
    };
    //Fix for designsystemet, when having multiple modals open at the same time.
    //This will prevent scrolling on the body when the modal is open
    useEffect(() => {
        // Add the class when the component mounts
        document.body.classList.add("plan-create-modal-open");
        // Clean up: Remove the class when the component unmounts
        return () => {
            document.body.classList.remove("plan-create-modal-open");
        };
    }, []);
    return (_jsx("div", { className: "plan-create-modal", children: _jsx(Modal.Root, { children: _jsx(Modal.Dialog, { ref: modalRef, onInteractOutside: () => closeModal(), onClose: () => onClose(), children: isLoading ? (_jsxs(_Fragment, { children: [_jsx(Modal.Header, { children: _jsx(Paragraph, { size: "sm", children: t("plan.createModal.subtitle") }) }), _jsx("div", { className: "plan-create-modal-spinner-wrapper", children: _jsx(Spinner, {}) })] })) : processStatus ? (_jsxs(_Fragment, { children: [_jsxs(Modal.Header, { children: [_jsx(Paragraph, { size: "sm", children: t("plan.createModal.subtitle") }), _jsx("div", { className: "ds-heading ds-heading--xs", children: t("plan.createModal.title", { actor: processStatus === null || processStatus === void 0 ? void 0 : processStatus.processType.name }) })] }), _jsx(Modal.Content, { children: _jsx("div", { className: "plan-create-calendar-wrapper", children: _jsx(PlanCreateCalendar, { processTypeId: processTypeId, selectedDay: selectedDay, setSelectedDay: setSelectedDay, selectedDayFormatted: selectedDayFormatted }) }) })] })) : (_jsxs(_Fragment, { children: [_jsx(Modal.Header, { children: _jsx(Paragraph, { size: "sm", children: t("plan.createModal.subtitle") }) }), _jsx("div", { className: "plan-create-modal-spinner-wrapper", children: _jsx(NoDataAvailable, {}) })] })) }) }) }));
};
