import { useQuery } from "@tanstack/react-query";
import { useIataContext } from "@contexts/IataContext";
import { getPlanMetadata } from "../services";
const usePlanMetadata = () => {
    const { iata } = useIataContext();
    const { data: planMetaData, isLoading: isLoadingMetadata } = useQuery({
        queryFn: () => getPlanMetadata({
            iata,
        }),
        queryKey: ["getPlanMetadata", { iata }],
        refetchInterval: 0,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        gcTime: Infinity,
    });
    return { planMetaData, isLoadingMetadata };
};
export default usePlanMetadata;
