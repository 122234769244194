export const createCircleIcon = (number) => {
    const svg = encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="40" fill="#B5208F" />
        <text x="49" y="53" font-family="Arial, sans-serif" font-size="40" fill="white" text-anchor="middle" dominant-baseline="middle">${number}</text>
      </svg>
      `);
    return {
        url: `data:image/svg+xml;utf8,${svg}`,
        scaledSize: new google.maps.Size(35, 35),
    };
};
