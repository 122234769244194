import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import "./StatusMessageBanner.scss";
import { Alert, Paragraph } from "@digdir/designsystemet-react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowDownOutlined, ArrowUpOutlined } from "@avinor/react-icons";
import { getStatusMessages } from "@features/OperationalMessages/services";
import { useIataContext } from "@contexts/IataContext";
const StatusMessageBanner = () => {
    const { iata } = useIataContext();
    const { i18n } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);
    const currentLanguage = i18n.language.toLowerCase() == "en" ? "en" : "no";
    const { data: statusMessagesAllAirports, isLoading } = useQuery({
        queryFn: () => getStatusMessages(),
        queryKey: ["getStatusMessages", { iata }],
    });
    if (isLoading || !statusMessagesAllAirports) {
        return null;
    }
    let messagesForCurrentIata = statusMessagesAllAirports.filter((x) => x.airport.toLowerCase() == iata.toLowerCase() || x.global);
    /* REMOVE THIS ONCE MERGING TO MAIN */
    if (!messagesForCurrentIata.some((x) => x.item)) {
        const messagesForOtherIataTEST = statusMessagesAllAirports.filter((x) => {
            const now = new Date();
            const expireDate = x.item && x.item.expiredate ? new Date(x.item.expiredate) : null;
            return x.item && (!expireDate || expireDate > now);
        });
        if (messagesForOtherIataTEST) {
            messagesForCurrentIata = messagesForOtherIataTEST;
        }
    }
    const messageItemsToShow = messagesForCurrentIata
        .filter((x) => {
        var _a;
        const now = new Date();
        const expireDate = ((_a = x.item) === null || _a === void 0 ? void 0 : _a.expiredate) ? new Date(x.item.expiredate) : null;
        return x.item && (!expireDate || expireDate > now);
    })
        .map((x) => (Object.assign(Object.assign({}, x.item), { airport: x.airport, language: x.lang })));
    if (!messageItemsToShow || messageItemsToShow.length == 0) {
        return null;
    }
    return (_jsx(_Fragment, { children: messageItemsToShow
            .filter((x) => {
            // Check if the message is in the current language and has a title
            if (x.language === currentLanguage && x.title) {
                return true;
            }
            else {
                // If the current message's language does not match, check if we should still keep it, because the message in the other language is null
                if (x.title) {
                    const messageInOtherLanguage = messageItemsToShow.find((y) => y.airport == x.airport && y.language == currentLanguage);
                    if (messageInOtherLanguage) {
                        return messageInOtherLanguage.title == null;
                    }
                    else {
                        return true;
                    }
                }
                return false;
            }
        })
            .map((messageItem) => {
            if (messageItem) {
                return (_jsx(Alert, { severity: "warning", size: "sm", className: "status-message-alert", children: isExpanded ? (_jsxs(_Fragment, { children: [_jsxs("div", { className: "status-message-header", children: [_jsxs("span", { className: "status-message-title", onClick: () => {
                                            setIsExpanded(false);
                                        }, children: [messageItem.airport, ": ", messageItem.title] }), _jsx("button", { onClick: () => {
                                            setIsExpanded(false);
                                        }, children: _jsx(ArrowUpOutlined, { width: "14px" }) })] }), _jsx(Paragraph, { size: "xs", className: "status-message-description", children: messageItem.description })] })) : (_jsxs("div", { className: "status-message-collapsed", onClick: () => {
                            setIsExpanded(true);
                        }, children: [_jsxs("span", { children: [messageItem.airport, ": ", messageItem.title] }), _jsx(ArrowDownOutlined, { width: "14px" })] })) }, messageItem === null || messageItem === void 0 ? void 0 : messageItem.title));
            }
        }) }));
};
export default StatusMessageBanner;
