import i18next, { use } from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import { enDeice, nbDeice, enOnTimePerformance, nbOnTimePerformance, enBaggage, nbBaggage, enPax, nbPax, enFlightMovements, nbFlightMovements, enDatePickerPresets, nbDatePickerPresets, enPlan, nbPlan, enFacility, nbFacility, enSignin, nbSignin, } from "./locales";
import nb from "./nb.json";
// Retrieve the language from localStorage, or default to Norwegian
const savedLanguage = localStorage.getItem("language") || "nb";
const options = {
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
    debug: true,
    lng: savedLanguage, // Set the language based on localStorage
    resources: {
        en: {
            common: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, en.en), enDeice), enOnTimePerformance), enBaggage), enPax), enFlightMovements), enDatePickerPresets), enPlan), enFacility), enSignin),
        },
        nb: {
            common: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, nb.nb), nbDeice), nbOnTimePerformance), nbBaggage), nbPax), nbFlightMovements), nbDatePickerPresets), nbPlan), nbFacility), nbSignin),
        },
    },
    fallbackLng: "nb", // Still fallback to Norwegian if the selected language is unavailable
    ns: ["common"],
    defaultNS: "common",
    react: {
        wait: false,
        bindI18n: "languageChanged loaded",
        bindStore: "added removed",
        nsMode: "default",
    },
};
use(initReactI18next).init(options);
export default i18next;
