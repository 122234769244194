import { jsx as _jsx } from "react/jsx-runtime";
import Hls from "hls.js";
import { useEffect, useRef } from "react";
export const VideoPlayer = ({ src }) => {
    const videoRef = useRef(null);
    useEffect(() => {
        let hls = null;
        const attemptPlay = () => {
            if (!videoRef.current)
                return;
            videoRef.current.play().catch((e) => console.error("Error attempting to play video:", e));
        };
        // This ensures we're at the latest segment for live broadcasts, even when switching tabs.
        const onVisibilityChange = () => {
            if (document.visibilityState === "visible" && videoRef.current && hls) {
                videoRef.current.currentTime = videoRef.current.duration;
                attemptPlay();
            }
        };
        if (videoRef.current) {
            if (Hls.isSupported()) {
                hls = new Hls({ liveSyncDurationCount: 1 });
                hls.loadSource(src);
                hls.attachMedia(videoRef.current);
                const onManifestParsed = () => {
                    if (!videoRef.current)
                        return;
                    // Seek to the end of the video, which for live streams means the most current point
                    videoRef.current.currentTime = videoRef.current.duration;
                    // Attempt to play the video after seeking
                    videoRef.current.play().catch((e) => console.error("Error attempting to play video:", e));
                };
                hls.on(Hls.Events.MANIFEST_PARSED, onManifestParsed);
            }
            else if (videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
                videoRef.current.src = src;
            }
        }
        document.addEventListener("visibilitychange", onVisibilityChange);
        return () => {
            if (hls) {
                hls.destroy();
                hls = null;
            }
        };
    }, [src]);
    return (_jsx("video", { ref: videoRef, preload: "auto", autoPlay: true, controls: true, playsInline: true, style: { width: "100%", height: "auto" } }));
};
export default VideoPlayer;
