import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AvinorColor } from "@constants";
import ToolTip from "@charts/shared/components/Tooltip/Tooltip";
import { formatDateXAxisLabelDate } from "./BarChart";
import "./CustomTooltip.scss";
const CustomTooltip = ({ baggageData, }) => {
    const utc = true;
    const { from, to, flightIds, totalFlights, paxInformation, baggageInformation, totalPax, totalBags } = baggageData;
    return (_jsxs(ToolTip, { style: { marginLeft: "2em" }, children: [_jsx(ToolTip.Title, { children: `${formatDateXAxisLabelDate(from, utc)} - ${formatDateXAxisLabelDate(to, utc)}` }), _jsxs(ToolTip.List, { children: [_jsxs("h3", { style: { paddingTop: "1em" }, children: ["Pax - (Total: ", totalPax, ")"] }), paxInformation.map(({ value, color, name, active }) => {
                        return (_jsx(ToolTip.ListItem, { style: {
                                type: "bullet",
                                bgColor: color,
                            }, content: {
                                value: name,
                                color: active ? "white" : AvinorColor.Grey200,
                            }, status: {
                                value,
                            } }, color));
                    }), _jsxs("h3", { style: { paddingTop: "1em" }, children: ["Bags - (Total: ", totalBags, ")"] }), baggageInformation.map(({ value, color, name, active }) => {
                        return (_jsx(ToolTip.ListItem, { style: {
                                type: "bullet",
                                bgColor: color,
                            }, content: {
                                value: name,
                                color: active ? "white" : AvinorColor.Grey200,
                            }, status: {
                                value,
                            } }, color));
                    }), _jsxs("h3", { style: { paddingTop: "1em" }, children: ["Flights (", totalFlights, ")"] }), _jsx("div", { style: { padding: "0.5em 0", display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }, children: flightIds.map((flightId) => {
                            return (_jsx(ToolTip.ListItem, { style: {
                                    type: "none",
                                }, content: {
                                    value: flightId,
                                } }, flightId));
                        }) })] })] }));
};
export default CustomTooltip;
