import { jsx as _jsx } from "react/jsx-runtime";
import { ProgressCard } from "@components/ProgressCard/ProgressCard";
import Spinner from "@components/Spinner/Spinner";
import useFacilityStatusListData from "../hooks/useFacilityStatusListData";
export const FacilityErrorCount = () => {
    const { data: facilityStatusList, isLoading } = useFacilityStatusListData();
    // filter through the list of facilities and get the unique objectIds in case there are multiple issues on the same facility
    const uniqueObjectIds = (facilityStatusList === null || facilityStatusList === void 0 ? void 0 : facilityStatusList.facilities)
        ? [...new Set(facilityStatusList.facilities.map((facility) => facility.objectId))]
        : [];
    if (isLoading)
        return _jsx(Spinner, {});
    return (_jsx(ProgressCard, { variant: "plain", progressVisibility: "hidden", value: uniqueObjectIds.length }));
};
