import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Accordion, AccordionRoot } from "@digdir/designsystemet-react";
import "./PlanOverviewAccordion.scss";
import { CheckmarkIcon } from "@navikt/aksel-icons";
import { Cell } from "@table-library/react-table-library";
import { useQuery } from "@tanstack/react-query";
import { formatDistanceToNow, parse } from "date-fns";
import { nb } from "date-fns/locale";
import { useState } from "react";
import NoDataAvailable from "@components/NoDataAvailable/NoDataAvailable";
import Spinner from "@components/Spinner/Spinner";
import Table from "@components/Table/Table";
import { addIdToObj } from "@components/Table/utils";
import { FormatPattern } from "@enums";
import { usePlanDayPickerContext } from "@features/Plan/contexts/PlanDayPickerContext";
import { getPlanOverview } from "@features/Plan/services";
import { useIataContext } from "@contexts/IataContext";
import { PlanOverviewDisruptions, PlanOverviewDisruptionsLabels } from "./PlanOverviewDisruptions";
const tableColumnHeaders = {
    actor: "Prosesser",
    disruptions: "",
};
const headers = Object.keys(tableColumnHeaders);
const getTableColumnHeaders = () => {
    return [
        {
            headers: [
                {
                    key: headers[0],
                    content: tableColumnHeaders[headers[0]],
                    cellProps: {
                        weight: 0.25,
                        className: "",
                    },
                },
                {
                    key: headers[2],
                    content: _jsx(PlanOverviewDisruptionsLabels, {}),
                    cellProps: {
                        weight: 0.75,
                        className: "",
                    },
                },
            ],
        },
    ];
};
export const PlanOverviewAccordion = () => {
    const { iata } = useIataContext();
    const { planDateFormatted } = usePlanDayPickerContext();
    const [openItems, setOpenItems] = useState([]);
    const { data: planOverview, isLoading, isFetching, } = useQuery({
        queryFn: () => getPlanOverview({
            iata,
            date: planDateFormatted,
        }),
        queryKey: ["getPlanOverview", { iata, planDateFormatted }],
    });
    const handleAccordionToggle = (e, index) => {
        if (e.target.classList.contains("plan-overview-timeline-segment")) {
            return; // Do nothing if the target is the excluded element
        }
        setOpenItems((prevOpenItems) => {
            if (prevOpenItems.includes(index)) {
                return prevOpenItems.filter((item) => item !== index);
            }
            else {
                return [...prevOpenItems, index];
            }
        });
    };
    const capitalizeFirstLetter = (stringToCapitalize) => {
        return stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1);
    };
    const getUpdatedAtFormatted = (value) => {
        let parsedDate = new Date();
        let formattedDate = "";
        if (value) {
            parsedDate = parse(value, FormatPattern.yyyy_MM_dd_HH_mm_ss_SSS, new Date());
            if (parsedDate) {
                try {
                    formattedDate = formatDistanceToNow(parsedDate, { addSuffix: true, locale: nb });
                }
                catch (error) {
                    console.error("Error parsing date", error);
                }
            }
        }
        return capitalizeFirstLetter(formattedDate);
    };
    const customCellRenderers = {
        actor: ({ cellIndex, value, row, gridColumnStart, gridColumnEnd }) => {
            return (_jsx(Cell, { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, children: _jsxs("div", { className: "plan-overview-accordion-cell", children: [_jsx("span", { className: "plan-overview-accordion-cell-value", children: value }), _jsxs("span", { title: value, className: "plan-overview-accordion-cell-updated-at", children: [row.updatedAt && (_jsx(CheckmarkIcon, { title: "a11y-title", fontSize: "12px" })), getUpdatedAtFormatted(row.updatedAt)] })] }) }, cellIndex));
        },
        disruptions: ({ cellIndex, row, gridColumnStart, gridColumnEnd }) => {
            return (_jsx(Cell, { gridColumnStart: gridColumnStart, gridColumnEnd: gridColumnEnd, children: _jsx(PlanOverviewDisruptions, { disruptions: row.disruptions, inTableLayout: true, hideTimeLabels: true }) }, cellIndex));
        },
    };
    const getTableNodes = (planPlans) => {
        const processesSorted = planPlans.map((plan) => {
            return {
                actor: plan.processType.name,
                updatedAt: plan.updatedAt,
                disruptions: plan.disruptions,
                updatedBy: plan.updatedBy,
            };
        });
        const nodes = addIdToObj(processesSorted);
        return nodes;
    };
    const keysToExclude = ["updatedBy", "updatedAt"];
    if (isLoading || isFetching)
        return (_jsx("div", { className: "plan-overview-time-table", children: _jsx(Spinner, {}) }));
    if (!planOverview || !(planOverview === null || planOverview === void 0 ? void 0 : planOverview.processTables) || (planOverview === null || planOverview === void 0 ? void 0 : planOverview.processTables.length) == 0)
        return (_jsx("div", { className: "plan-overview-time-table", children: _jsx(NoDataAvailable, {}) }));
    return (_jsx("div", { className: "plan-overview-time-table", children: _jsx(AccordionRoot, { color: "neutral", children: planOverview === null || planOverview === void 0 ? void 0 : planOverview.processTables.map((overview, index) => (_jsxs(Accordion.Item, { open: openItems.includes(index), children: [_jsx(Accordion.Heading, { level: 3, className: "plan-overview-accordion-heading", onClick: (e) => handleAccordionToggle(e, index), children: _jsxs("div", { className: "plan-overview-accordion-heading-content", children: [_jsx("p", { children: overview.areaProcess.areaName }), _jsx(PlanOverviewDisruptions, { disruptions: overview.areaProcess.disruptions, inTableLayout: false })] }) }), _jsx(Accordion.Content, { children: _jsx(Table, { data: getTableNodes(overview.processes), excludeKeys: keysToExclude, cellRenderers: customCellRenderers, headerGroups: getTableColumnHeaders(), useDesignsystemetTheme: true }) })] }, index))) }) }));
};
