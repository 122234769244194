import { jsx as _jsx } from "react/jsx-runtime";
import { WidgetCard } from "@components/WidgetCard/WidgetCard";
import useDeIceData from "../hooks/useDeIceData";
import { calculateSumFlightsExpectedNext60 } from "./utils";
export const DeIceExpectedNext60Platforms = () => {
    const { data: deIceData } = useDeIceData();
    const platforms = deIceData && deIceData.platforms;
    const totalExpected60 = platforms && calculateSumFlightsExpectedNext60(platforms);
    return (_jsx(WidgetCard, { title: "Antall fly forventet p\u00E5 plattform", description: "Alle plattformer neste 60 min", underline: "header", error: !totalExpected60, children: totalExpected60 === 0 ? "0" : totalExpected60 }));
};
DeIceExpectedNext60Platforms.displayName = "DeIceExpectedNext60Platforms";
