import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "@components/Spinner/Spinner";
import Widget from "@components/Widget/Widget";
import { FlightMovementsDomestic, FlightMovementsDomesticTotal } from "../components";
export const FlightMovementsDomesticWidget = () => {
    const { t } = useTranslation();
    return (_jsxs(Widget.Container, { style: { justifyContent: "space-between" }, children: [_jsx(Widget.Header, { title: t("flightMovements.widgets.domestic.title"), subtitle: t("flightMovements.widgets.domestic.subTitle") }), _jsxs(Suspense, { fallback: _jsx(Spinner, {}), children: [_jsx(Widget.NumericIndicator, { children: _jsx(FlightMovementsDomesticTotal, {}) }), _jsx(Widget.Body, { children: _jsx(FlightMovementsDomestic, {}) })] })] }));
};
FlightMovementsDomesticWidget.displayName = "FlightMovementsDomesticWidget";
