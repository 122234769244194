import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
const PlanEditingContext = createContext(undefined);
export const PlanEditingProvider = ({ children }) => {
    const [todayHasChanges, setTodayHasChanges] = useState(false);
    const [modifiedStatusOfToday, setModifiedStatusOfToday] = useState(null);
    const [tomorrowHasChanges, setTomorrowHasChanges] = useState(false);
    const [modifiedStatusOfTomorrow, setModifiedStatusOfTomorrow] = useState(null);
    const [showTomorrow, setShowTomorrow] = useState(false);
    const resetStatus = () => {
        setTodayHasChanges(false);
        setTomorrowHasChanges(false);
        setModifiedStatusOfToday(null);
        setModifiedStatusOfTomorrow(null);
    };
    const areProcessStatusesValid = (processStatusesToValidate) => {
        let todayIsValid = true;
        let tomorrowIsValid = true;
        if (processStatusesToValidate === "today" || processStatusesToValidate === "both") {
            todayIsValid = validateProcessStatus(modifiedStatusOfToday, setModifiedStatusOfToday);
        }
        if (processStatusesToValidate === "tomorrow" || processStatusesToValidate === "both") {
            tomorrowIsValid = validateProcessStatus(modifiedStatusOfTomorrow, setModifiedStatusOfTomorrow);
        }
        return todayIsValid && tomorrowIsValid;
    };
    const validateProcessStatus = (processStatus, setProcessStatus) => {
        var _a;
        let isValid = true;
        if (!processStatus) {
            isValid = false;
            return isValid;
        }
        if (((_a = processStatus.disruptions) === null || _a === void 0 ? void 0 : _a.length) != 24)
            isValid = false;
        const updatedDisruptions = processStatus.disruptions.map((disruption) => {
            if ((disruption.someDisturbances || disruption.majorDisturbances) && !disruption.disruptionTypeId) {
                isValid = false;
                return Object.assign(Object.assign({}, disruption), { errorMessage: "Required" });
            }
            return disruption;
        });
        if (!isValid) {
            setProcessStatus((prevProcessStatus) => prevProcessStatus ? Object.assign(Object.assign({}, prevProcessStatus), { disruptions: updatedDisruptions }) : prevProcessStatus);
        }
        return isValid;
    };
    return (_jsx(PlanEditingContext.Provider, { value: {
            todayHasChanges,
            setTodayHasChanges,
            tomorrowHasChanges,
            setTomorrowHasChanges,
            modifiedStatusOfToday,
            setModifiedStatusOfToday,
            modifiedStatusOfTomorrow,
            setModifiedStatusOfTomorrow,
            showTomorrow,
            setShowTomorrow,
            resetStatus,
            areProcessStatusesValid,
        }, children: children }));
};
export const usePlanEditingContext = () => {
    const context = useContext(PlanEditingContext);
    if (!context) {
        throw new Error("usePlanEditingContext must be used within a PlanEditingProvider");
    }
    return context;
};
