import { useQuery } from "@tanstack/react-query";
import { useIataContext } from "@contexts/IataContext";
import { getProcessStatus } from "../services";
//Gets a specific processStatus based on the date and plantype id
const useProcessStatus = ({ date, processTypeId }) => {
    const { iata } = useIataContext();
    const { data: processStatus, error, isLoading, isFetching, refetch, } = useQuery({
        queryFn: () => getProcessStatus({
            iata,
            date,
            processTypeId,
        }),
        queryKey: ["getPlanOverview", { iata, date }],
        refetchInterval: 0,
        staleTime: 0,
        refetchOnWindowFocus: false,
        gcTime: 0,
    });
    return { processStatus, error, isLoading, isFetching, refetch };
};
export default useProcessStatus;
