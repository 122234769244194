var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { addDays, format, subDays } from "date-fns";
import { t } from "i18next";
import { useEffect, useRef } from "react";
import { DatePicker as ReactDatePicker } from "rsuite";
import { HStack } from "@components/Layout";
import { FormatPattern } from "@enums";
import { useDatePickerContext } from "@contexts/DatePickerContext";
import Select from "../Select/Select";
const defaultRanges = [
    {
        label: "today",
        value: new Date(),
        closeOverlay: true,
    },
    {
        label: "yesterday",
        value: addDays(new Date(), -1),
        closeOverlay: true,
    },
];
const defaultSelectOptions = [
    { value: "custom", label: t("datePickerPresets.custom") },
    {
        value: JSON.stringify({ from: subDays(new Date(), 5), to: subDays(new Date(), 1) }),
        label: t("datePickerPresets.last5days"),
    },
    {
        value: JSON.stringify({ from: subDays(new Date(), 1), to: subDays(new Date(), 1) }),
        label: t("datePickerPresets.yesterday"),
    },
    {
        value: JSON.stringify({ from: new Date(), to: new Date() }),
        label: t("datePickerPresets.today"),
    },
    {
        value: JSON.stringify({ from: addDays(new Date(), 1), to: addDays(new Date(), 1) }),
        label: t("datePickerPresets.tomorrow"),
    },
    {
        value: JSON.stringify({ from: addDays(new Date(), 1), to: addDays(new Date(), 3) }),
        label: t("datePickerPresets.next3days"),
    },
    {
        value: JSON.stringify({ from: addDays(new Date(), 1), to: addDays(new Date(), 7) }),
        label: t("datePickerPresets.next7days"),
    },
    {
        value: JSON.stringify({ from: addDays(new Date(), 1), to: addDays(new Date(), 30) }),
        label: t("datePickerPresets.next30days"),
    },
];
const getFormattedSelectValue = (date) => {
    return {
        value: JSON.stringify({ from: date.from, to: date.to }),
        label: format(new Date(date.from), FormatPattern.dd_MM_yyyy),
    };
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DatePicker = ({ id, selectProps, datePickerProps, }) => {
    const _a = datePickerProps !== null && datePickerProps !== void 0 ? datePickerProps : {}, { ranges = [], onOk } = _a, restDatePickerProps = __rest(_a, ["ranges", "onOk"]);
    const { options = defaultSelectOptions, onChange: onChangeSelect } = selectProps !== null && selectProps !== void 0 ? selectProps : {};
    const { picker: selectedOption, setPicker: setSelectedOption } = useDatePickerContext(id);
    const selectRef = useRef(null);
    const datePickerRef = useRef(null);
    const handleOnChangeSelect = (newValue, actionMeta) => {
        var _a, _b, _c, _d;
        if (!newValue)
            return;
        if (newValue.value === "custom") {
            (_a = datePickerRef.current) === null || _a === void 0 ? void 0 : _a.open();
            (_c = (_b = selectRef === null || selectRef === void 0 ? void 0 : selectRef.current) === null || _b === void 0 ? void 0 : _b.inputRef) === null || _c === void 0 ? void 0 : _c.blur();
        }
        else {
            setSelectedOption(JSON.parse(newValue.value));
            (_d = datePickerRef.current) === null || _d === void 0 ? void 0 : _d.close();
        }
        onChangeSelect === null || onChangeSelect === void 0 ? void 0 : onChangeSelect(newValue, actionMeta);
    };
    const handleOnOk = (date, event) => {
        var _a;
        setSelectedOption({ from: date, to: date });
        (_a = datePickerRef.current) === null || _a === void 0 ? void 0 : _a.close();
        onOk === null || onOk === void 0 ? void 0 : onOk(date, event);
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!datePickerRef.current || !selectRef.current)
                return;
            const element = event.target;
            const isOutside = element.classList.contains("rs-");
            if (isOutside) {
                datePickerRef.current.close();
            }
        };
        const handleKeyDown = (event) => {
            if (event.key === "Escape" && datePickerRef.current) {
                datePickerRef.current.close();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);
    return (_jsxs(HStack, { style: { position: "relative", minWidth: "12rem" }, children: [_jsx(Select, { ref: selectRef, value: getFormattedSelectValue(selectedOption), onChange: handleOnChangeSelect, options: options, fullWidth: true, styles: {
                    control: (base) => (Object.assign(Object.assign({}, base), { backgroundColor: "var(--avinor-white)" })),
                } }), _jsx(ReactDatePicker, Object.assign({ isoWeek: true, ref: datePickerRef, style: { visibility: "hidden", position: "absolute", top: 3, left: -73 }, ranges: [...defaultRanges, ...ranges], onOk: handleOnOk }, restDatePickerProps))] }));
};
export default DatePicker;
