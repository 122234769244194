import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { formatStringsWithConjunction, getProgressStatusText } from "@utils/Utils";
import { Grid, VStack } from "@components/Layout";
import { ProgressCard } from "@components/ProgressCard/ProgressCard";
import Spinner from "@components/Spinner/Spinner";
import { breakpoints } from "@constants";
import { useMediaQuery } from "@hooks";
import useRegularityAndPunctualityPeriodData from "../hooks/useRegularityAndPunctualityPeriodData";
import { PunctualityProgressBar } from "./PunctualityProgressBar";
import { calculateAveragePunctuality, sumAirlineData } from "./utils";
const ProgressCargo = () => {
    const { data: punctualityCargoData, isLoading } = useRegularityAndPunctualityPeriodData({
        paxOnly: false,
        direction: "D",
        cargoOnly: true,
    });
    if (isLoading)
        return _jsx(Spinner, {});
    return (_jsx(ProgressCard, { title: "Cargo", description: getProgressStatusText(punctualityCargoData === null || punctualityCargoData === void 0 ? void 0 : punctualityCargoData.totalPunctuality, punctualityCargoData === null || punctualityCargoData === void 0 ? void 0 : punctualityCargoData.totalFlightsOperated, punctualityCargoData === null || punctualityCargoData === void 0 ? void 0 : punctualityCargoData.totalFlights), value: (punctualityCargoData === null || punctualityCargoData === void 0 ? void 0 : punctualityCargoData.totalPunctuality) || 0 }));
};
const ProgressAirlines = () => {
    const { data: punctualityAirlinesData, isLoading } = useRegularityAndPunctualityPeriodData({ direction: "D" });
    const { t } = useTranslation();
    const airlines = punctualityAirlinesData === null || punctualityAirlinesData === void 0 ? void 0 : punctualityAirlinesData.airlines;
    const averagePunctuality = calculateAveragePunctuality(airlines);
    const { operatedFlights, plannedFlights } = sumAirlineData(airlines);
    const title = formatStringsWithConjunction((airlines === null || airlines === void 0 ? void 0 : airlines.filter(({ airlineIata }) => airlineIata !== "rest").map(({ airlineIata }) => airlineIata)) || ["??"], t("conjunction.and"));
    if (isLoading)
        return _jsx(Spinner, {});
    return (_jsx(ProgressCard, { title: title, description: getProgressStatusText(averagePunctuality, operatedFlights, plannedFlights), value: averagePunctuality }));
};
const ProgressOtherAirlines = () => {
    var _a;
    const { data: punctualityAirlinesData, isLoading } = useRegularityAndPunctualityPeriodData({ direction: "D" });
    const { t } = useTranslation();
    const airlines = punctualityAirlinesData === null || punctualityAirlinesData === void 0 ? void 0 : punctualityAirlinesData.airlines;
    const { operatedFlights, plannedFlights } = sumAirlineData(airlines, true);
    const othersPunctuality = (_a = punctualityAirlinesData === null || punctualityAirlinesData === void 0 ? void 0 : punctualityAirlinesData.airlines) === null || _a === void 0 ? void 0 : _a.find(({ airlineIata }) => airlineIata === "rest");
    if (isLoading)
        return _jsx(Spinner, {});
    return (_jsx(ProgressCard, { title: t("others"), description: getProgressStatusText(othersPunctuality === null || othersPunctuality === void 0 ? void 0 : othersPunctuality.airlinePunctuality, operatedFlights, plannedFlights), value: (othersPunctuality === null || othersPunctuality === void 0 ? void 0 : othersPunctuality.airlinePunctuality) || 0 }));
};
export const PunctualityTotalOverview = () => {
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
    return (_jsxs(VStack, { gap: 0.5, style: { height: "100%" }, children: [_jsx(PunctualityProgressBar, { direction: "D" }), _jsxs(Grid, { columns: 3, gap: isMobile ? 0.5 : 1, children: [_jsx(ProgressAirlines, {}), _jsx(ProgressOtherAirlines, {}), _jsx(ProgressCargo, {})] })] }));
};
